import {
  BdiVsCdi,
  BudgetAllocator,
  BudgetSplit,
  CampaignBrief,
  CaseStudy,
  MediaMap,
  MultiVerticalBrief,
  RankingDataInput,
  TargetGroup,
  TSvg
} from './MenuIcons';

type IIcons = {
  [key: string]: {
    icon: ({ color }: TSvg) => React.JSX.Element;
    color: string;
  };
};

const color = '#637085';

export const icons: IIcons = {
  'campaign-brief': {
    icon: CampaignBrief,
    color
  },
  'multi-vertical-brief': {
    icon: MultiVerticalBrief,
    color
  },
  'target-group': {
    icon: TargetGroup,
    color
  },
  'ranking-data-input': {
    icon: RankingDataInput,
    color
  },
  'bdi-vs-cdi': {
    icon: BdiVsCdi,
    color
  },
  'media-map': {
    icon: MediaMap,
    color
  },
  'budget-split': {
    icon: BudgetSplit,
    color
  },
  'budget-allocator': {
    icon: BudgetAllocator,
    color
  },
  'kpi-case-study': {
    icon: CaseStudy,
    color
  }
};

const Icon = ({ iconName, color }: { iconName: string; color?: string }) => {
  const VariableIcon = icons[iconName]
    ? icons[iconName]?.icon
    : icons['campaign-brief'].icon;

  return <VariableIcon color={color ? color : icons['campaign-brief'].color} />;
};

export default Icon;
