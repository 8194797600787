interface Props {
	value: number;
	currency: boolean;
	fullForm?: boolean;
}

const format = (currencyString: string, fullForm: boolean)=>{
	if (!fullForm) return currencyString
	switch (currencyString) {
		case 'B':
			return " Billion";
		case 'M':
			return " Million";
		case 'L':
			return " Lakh";
		case "K":
			return " Thousand";
		default:
			return ''
	}
}

const ConvertToCurrencySystem = ({ value, currency, fullForm = false }: Props) => {
	const currencySymbol = currency ? '₹' : '',
		absValue = Number(value);

	if (Math.abs(absValue) >= 1.0e9)
		return <span>{currencySymbol + parseFloat((absValue / 1.0e9).toFixed(2)) + format('B', fullForm)}</span>
	if (Math.abs(absValue) >= 1.0e6)
		return <span>{currencySymbol + parseFloat((absValue / 1.0e6).toFixed(2)) + format("M", fullForm)}</span>
	if (Math.abs(absValue) >= 1.0e5)
		return <span>{currencySymbol + parseFloat((absValue / 1.0e5).toFixed(2)) + format("L", fullForm)}</span>
	if (Math.abs(absValue) >= 1.0e3)
		return <span>{currencySymbol + parseFloat((absValue / 1.0e3).toFixed(2)) + format("K", fullForm)}</span>
	return <span>{parseFloat(absValue.toFixed(2))}</span>;
}

export default ConvertToCurrencySystem;