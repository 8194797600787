import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { IPlan, InitialState } from '../../../redux/slice';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';
import { createAppAsyncThunk } from '../../../../hooks';

import { ICampaignBrief } from '../../CampaignBrief/interface';
import { ITargetGroup } from '../../TargetGroup/interface';
import { TAudienceContext } from '../../AudienceContextFile/interface';
import { TUserPersona } from '../../UserPersonaFile/interface';
import { IAudienceSelection } from '../../MediaSpace/interface';
import { IDigitalHash } from '../../BudgetAllocator/interface';

const getEncodedJsonString = (data: unknown): string => {
  const jsonString = JSON.stringify(data),
    encodedJsonString = encodeURIComponent(jsonString);
  return encodedJsonString;
};

export const updateDigitalHashCampaignBrief = createAppAsyncThunk<
  any,
  { campaignBrief: ICampaignBrief; hashName: string }
>(
  'plan-requests/updateDigitalHashCampaignBrief',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.campaignBrief && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/brief`,
          {
            campaignBrief: data.campaignBrief
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateDigitalHashTargetGroup = createAppAsyncThunk<
  any,
  { targetGroup: ITargetGroup; hashName: string }
>(
  'plan-requests/updateDigitalHashTargetGroup',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.targetGroup && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/target-group`,
          {
            targetGroup: data.targetGroup
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateDigitalHashAudienceContext = createAppAsyncThunk<
  any,
  { audienceContext: TAudienceContext; hashName: string }
>(
  'plan-requests/updateDigitalHashAudienceContext',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.audienceContext && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/audience-context`,
          {
            ...data.audienceContext
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateDigitalHashUserPersona = createAppAsyncThunk<
  any,
  { userPersona: TUserPersona; hashName: string }
>(
  'plan-requests/updateDigitalHashUserPersona',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.userPersona && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/user-persona`,
          {
            ...data.userPersona
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const digitalHashFileUpload = createAppAsyncThunk<
  any,
  { file: File; type: 'audienceContext' | 'userPersona'; hashName: string }
>(
  'plan-requests/digitalHashFileUpload',
  async (
    { file, type = '', hashName = '' },
    { getState, extra: { API }, dispatch }
  ) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    try {
      if (file && type && hashName) {
        const response = await API.post(
          `plan-requests/${planId}/hash/digital/upload-file`,
          formData
        );
        dispatch(
          SnackBarActions.openSnack({
            message: `${
              type === 'audienceContext' ? 'Audience Context' : 'User Persona'
            } File data Updated Successfully`,
            open: true,
            type: 'info'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

interface IAddPriority {
  mediaId: string;
  mediaOptionId: string;
  priorities: string[];
}

export const digitalHashAddPriority = createAppAsyncThunk<
  any,
  { data: IAddPriority; hashName: string }
>(
  'plan-requests/digitalHashAddPriority',
  async ({ data, hashName = '' }, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (
        data?.mediaId &&
        data?.mediaOptionId &&
        data?.priorities?.length !== 0 &&
        hashName
      ) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/audience-selection`,
          {
            ...data
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName
        };
      } else {
        throw new Error(
          'Please select at least one priority before proceeding.'
        );
      }
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || err?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const digitalHashGetMediaspaceData = createAppAsyncThunk<any, {}>(
  'plan-requests/digitalHashGetMediaspaceData',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      const response = await API.get(
        `plan-requests/${planId}/hash/digital/media-space`
      );
      return {
        mediaSpace: response.data?.mediaSpace
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const digitalHashEditMedias = createAppAsyncThunk<
  any,
  { audienceSelection: IAudienceSelection; hashName: string }
>(
  'plan-requests/digitalHashEditMedias',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.audienceSelection?._id && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/audience-selection/${data?.audienceSelection?._id}`,
          {
            ...data?.audienceSelection
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

interface IRequestMediaOption {
  mediaId: string;
  mediaOptionId: string;
}

export const digitalHashRequestMediaOption = createAppAsyncThunk<
  any,
  { selectedMediaOptions: IRequestMediaOption[]; hashName: string }
>(
  'plan-requests/digitalHashRequestMediaOption',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (
        data?.selectedMediaOptions &&
        data.selectedMediaOptions?.length !== 0 &&
        data?.hashName
      ) {
        const encodedJsonString = getEncodedJsonString(
          data.selectedMediaOptions
        );

        const response = await API.get(
          `plan-requests/${planId}/hash/digital/on-request-media?params=${encodedJsonString}`
        );
        return {
          mediaSpace: response.data?.mediaSpace
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const digitalHashDeleteAudienceSelection = createAppAsyncThunk<
  any,
  { audienceIds: string[]; sourcePage: string; hashName: string }
>(
  'plan-requests/digitalHashDeleteAudienceSelection',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (
        data?.audienceIds &&
        data.audienceIds?.length !== 0 &&
        data?.sourcePage &&
        data?.hashName
      ) {
        const encodedJsonString = getEncodedJsonString(data.audienceIds);

        const response = await API.delete(
          `plan-requests/${planId}/hash/digital/remove-audience-selection?audienceIds=${encodedJsonString}&sourcePage=${data.sourcePage}`
        );

        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const digitalHashDoBudgetAllocatorCalculation = createAppAsyncThunk<
  any,
  { hashName: string }
>(
  'plan-requests/digitalHashDoBudgetAllocatorCalculation',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/budget-allocator`
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const digitalHashCloneAudienceSelection = createAppAsyncThunk<
  any,
  { audienceSelectionId: string; hashName: string }
>(
  'plan-requests/digitalHashCloneAudienceSelection',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.audienceSelectionId && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/audience-selection/${data.audienceSelectionId}/clone`
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const digitalHashRetargetingAudienceSelection = createAppAsyncThunk<
  any,
  { audienceSelectionId: string; hashName: string }
>(
  'plan-requests/digitalHashRetargetingAudienceSelection',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data?.audienceSelectionId && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/audience-selection/${data.audienceSelectionId}/retargeting`
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateDigitalHashBudgetAllocator = createAppAsyncThunk<
  any,
  { digitalHash: IDigitalHash; hashName: string }
>(
  'plan-requests/updateDigitalHashBudgetAllocator',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (
        data?.digitalHash &&
        data?.digitalHash?.audienceSelection &&
        data?.hashName
      ) {
        const response = await API.put(
          `plan-requests/${planId}/hash/digital/update-budget-allocator`,
          {
            digitalHash: data.digitalHash
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const getDigitalHashAudienceSize = createAppAsyncThunk<
  any,
  { params: { [key: string]: string[] }; hashName: string }
>(
  'plan-requests/getDigitalHashAudienceSize',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (data && data?.params && data?.hashName) {
        const encodedJsonString = getEncodedJsonString(data.params);

        const response = await API.get(
          `plan-requests/${planId}/hash/digital/audience-size?params=${encodedJsonString}`
        );
        return response?.data;
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

const updatePlanState = <T>(
  state: InitialState,
  action: PayloadAction<
    any,
    string,
    {
      arg: T;
      requestId: string;
      requestStatus: 'fulfilled';
    },
    never
  >
) => {
  if (action?.payload?.updatedPlan && action?.payload?.hashName) {
    state.plan.currentState = action.payload.updatedPlan.currentState;
    state.plan[action.payload.hashName as keyof IPlan] =
      action.payload.updatedPlan[action.payload.hashName];
  }
};

export const digitalHashExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder
    .addCase(updateDigitalHashCampaignBrief.fulfilled, (state, action) => {
      updatePlanState<{
        campaignBrief: ICampaignBrief;
        hashName: string;
      }>(state, action);
    })
    .addCase(updateDigitalHashTargetGroup.fulfilled, (state, action) => {
      updatePlanState<{
        targetGroup: ITargetGroup;
        hashName: string;
      }>(state, action);
    })
    .addCase(updateDigitalHashAudienceContext.fulfilled, (state, action) => {
      updatePlanState<{
        audienceContext: TAudienceContext;
        hashName: string;
      }>(state, action);
    })
    .addCase(updateDigitalHashUserPersona.fulfilled, (state, action) => {
      updatePlanState<{
        userPersona: TUserPersona;
        hashName: string;
      }>(state, action);
    })
    .addCase(digitalHashFileUpload.fulfilled, (state, action) => {
      updatePlanState<{
        file: File;
        type: 'audienceContext' | 'userPersona';
        hashName: string;
      }>(state, action);
    })
    .addCase(digitalHashAddPriority.fulfilled, (state, action) => {
      updatePlanState<{
        data: IAddPriority;
        hashName: string;
      }>(state, action);
    })
    .addCase(digitalHashEditMedias.fulfilled, (state, action) => {
      updatePlanState<{
        audienceSelection: IAudienceSelection;
        hashName: string;
      }>(state, action);
    })
    .addCase(digitalHashDeleteAudienceSelection.fulfilled, (state, action) => {
      updatePlanState<{
        audienceIds: string[];
        hashName: string;
      }>(state, action);
    })
    .addCase(
      digitalHashDoBudgetAllocatorCalculation.fulfilled,
      (state, action) => {
        updatePlanState<{
          hashName: string;
        }>(state, action);
      }
    )
    .addCase(digitalHashCloneAudienceSelection.fulfilled, (state, action) => {
      updatePlanState<{
        audienceSelectionId: string;
        hashName: string;
      }>(state, action);
    })
    .addCase(
      digitalHashRetargetingAudienceSelection.fulfilled,
      (state, action) => {
        updatePlanState<{
          audienceSelectionId: string;
          hashName: string;
        }>(state, action);
      }
    )
    .addCase(updateDigitalHashBudgetAllocator.fulfilled, (state, action) => {
      updatePlanState<{
        digitalHash: IDigitalHash;
        hashName: string;
      }>(state, action);
    });
};
