import { Calculator } from 'mdi-material-ui';
import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../store';

const AddCalculator = lazy(() => import('./AddCalculator/index'));
const CalculatorList = lazy(() => import('./CalculatorList/index'));

const Wrapper = () => (
  <Suspense fallback={<div>Loading....</div>}>
    <Outlet />
  </Suspense>
);

const MarketingCalculatorConfig = {
  name: 'Marketing Calculators',
  path: 'marketing-calculators',
  elements: <Wrapper />,
  Icon: Calculator,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('calculators', slice.default);
      return true;
    });
  },
  children: [
    {
      path: '',
      element: <CalculatorList />
    },
    {
      path: 'new',
      element: <AddCalculator />
    },
    {
      path: ':calculatorId/edit',
      element: <AddCalculator />
    }
  ]
};

export default MarketingCalculatorConfig;
