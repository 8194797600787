import { ITargetGroup } from '../TargetGroup/interface';
import {
  getTargetGroupStaticData,
  IBrief
} from '../Utils/getTargetGroupStaticData';
import { campaignObjective } from './constants';

export interface ICampaignObjectiveOption {
  key: string;
  isSelected: boolean;
}

export interface ICampaignObjective {
  key: string;
  isSelected: boolean;
  options: ICampaignObjectiveOption[];
}

export interface ICampaignBrief {
  campaignObjective: ICampaignObjective[];
  productName: string[];
  plannersInput: string;
  audienceType: string;
}

interface IDigitalHash {
  campaignBrief: ICampaignBrief;
  targetGroup: ITargetGroup;
}

const getCampaignObjective = (digitalHash: IDigitalHash) => ({
  campaignObjective:
    digitalHash?.campaignBrief?.campaignObjective ?? campaignObjective,
  productName: digitalHash?.campaignBrief?.productName ?? [],
  plannersInput: digitalHash?.campaignBrief?.plannersInput ?? '',
  audienceType: digitalHash?.campaignBrief?.audienceType ?? 'B2C'
});

export const getDigitalHashData = ({
  digitalHash,
  brief
}: {
  digitalHash: IDigitalHash;
  brief: IBrief;
}) => ({
  ...digitalHash,
  campaignBrief: {
    ...getCampaignObjective(digitalHash),
    ...(digitalHash?.campaignBrief ?? {})
  },
  targetGroup: {
    ...getTargetGroupStaticData({ brief, toolName: 'digital' }),
    ...(digitalHash?.targetGroup ?? {}),
    customAudience: digitalHash?.targetGroup?.customAudience ?? [],
    audienceSize: digitalHash?.targetGroup?.audienceSize ?? 0
  }
});
