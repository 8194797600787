import { ICaseStudy } from '../CaseStudy/interface';
import { validateAge } from './targetGroupErrorHandler';

export const handleKpiCaseStudyError = (caseStudy: ICaseStudy) => {
  const { gwi } = caseStudy;

  if (!gwi?.interest?.length || gwi.interest.length === 0)
    return `Interest is a required field.`;

  if (!gwi?.age?.length || gwi.age.length === 0)
    return `Age Group is a required field.`;

  if (!gwi?.gender?.length || gwi.gender.length === 0)
    return `Gender is a required field.`;

  if (!gwi?.geos || gwi.geos.length === 0)
    return `Geo Location is a required field.`;

  if (!gwi?.income?.length || gwi.income.length === 0)
    return `Income is a required field.`;

  return validateAge(gwi?.age);
};
