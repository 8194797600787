import { lazy } from 'react';

const BdiVsCdiMain = lazy(() => import('./BdiVsCdiMain'));

const BdiVsCdiMainConfig = {
  name: 'BDI vs CDI',
  path: 'bdi-vs-cdi',
  element: <BdiVsCdiMain />,
  icon: 'bdi-vs-cdi',
  state: 30
};

export default BdiVsCdiMainConfig;
