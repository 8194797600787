import { Autocomplete, Chip, TextField } from '@mui/material';

interface IMultiTextFreeSolo {
  id?: string;
  value: string[] | null;
  handleValueChange: (value: string[] | null) => void;
  placeHolder?: string;
  options?: string[];
}

const MultiTextFreeSolo = ({
  id = 'tags-filled',
  value,
  handleValueChange,
  placeHolder = 'Enter Input',
  options = []
}: IMultiTextFreeSolo) => (
  <Autocomplete
    multiple
    id={`${id}`}
    fullWidth
    options={options}
    freeSolo
    value={value ?? []}
    renderTags={(value: readonly string[], getTagProps) =>
      value.map((option: string, index: number) => {
        const { key, ...tagProps } = getTagProps({ index });
        return (
          <Chip
            variant="filled"
            size="medium"
            sx={{
              backgroundColor: '#4e3bc9',
              fontSize: '14px',
              fontWeight: 500,
              color: '#fff',
              '& .MuiChip-deleteIcon': { color: 'currentcolor' }
            }}
            label={option}
            key={key}
            {...tagProps}
          />
        );
      })
    }
    onChange={(event: any, newValue: string[] | null) => {
      handleValueChange(newValue);
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        size="small"
        variant="outlined"
        placeholder={placeHolder}
      />
    )}
  />
);

export default MultiTextFreeSolo;
