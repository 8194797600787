import { lazy } from 'react';

const PlanSchedule = lazy(() => import('./PlanSchedule'));

const PlanScheduleConfig = {
  name: 'Plan Schedule',
  path: 'plan-schedule',
  element: <PlanSchedule />,
  icon: 'planSchedule'
};

export default PlanScheduleConfig;