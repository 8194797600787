import React, { useState } from 'react';
import { MenuItem } from '@mui/material';

interface ISelectFilter {
  handleSelect: (value: any) => void;
  selectedValue: string;
  options: string[];
}

function SelectFilter({ handleSelect, selectedValue, options }: ISelectFilter) {
  const [selected, setSelected] = useState(selectedValue);

  const handleSelectedValue = (item: string) => {
    setSelected(item);
    handleSelect(item);
  };
  return (
    <>
      {options.map((item: string) => (
        <MenuItem
          key={item}
          selected={item === selected}
          sx={{
            textTransform: 'capitalize',
            '&.Mui-selected': { background: '#F4F5F7' }
          }}
          value={item}
          onClick={() => handleSelectedValue(item)}
        >
          {item}
        </MenuItem>
      ))}
    </>
  );
}

export default SelectFilter;
