import { FrequentlyAskedQuestions } from 'mdi-material-ui';
import { lazy } from 'react';

const Faq = lazy(() => import('./Faq'));

const FaqConfig = {
  name: 'FAQ',
  path: 'faqs',
  element: <Faq />,
  icon: FrequentlyAskedQuestions
};

export default FaqConfig;
