import { useWatch } from 'react-hook-form';
import { List, ListItem, ListItemText } from '@mui/material';

import { TRow } from '../ContextDetails/GwiDataTable';
import CustomAccordion from '../../Shared/CustomAccordion';

interface IFinalizedAudienceContextProps {
  targetKey: string;
  compType: string;
}
interface IContextListItemProps {
  item: TRow;
  index: number;
}

type TFinalizedContextListProps = {
  contextData: TRow[];
};

const ContextListItem = ({ item, index }: IContextListItemProps) => (
  <ListItem
    sx={{
      border: '1px solid #4E3BC9',
      borderRadius: '0.5rem',
      padding: '1rem',
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
    }}
  >
    <ListItemText primary={`${index + 1}. ${item.attributes}`} />
  </ListItem>
);

const FinalizedContextList = ({
  contextData = []
}: TFinalizedContextListProps) => (
  <List sx={{ gap: '0.75rem', display: 'flex', flexDirection: 'column' }}>
    {contextData
      .filter((data) => data?.isSelected)
      .map((item, index) => (
        <ContextListItem
          key={`${item.attributes}-${index}`}
          item={item}
          index={index}
        />
      ))}
  </List>
);

const FinalizedAudienceContext = ({
  targetKey,
  compType
}: IFinalizedAudienceContextProps) => {
  const contextData: TRow[] =
    useWatch({
      name: targetKey
    }) ?? [];
  return contextData.length !== 0 ? (
    <CustomAccordion
      title={`Finalized Audience ${
        compType === 'audienceContext' ? 'Context' : 'Persona'
      }`}
    >
      <FinalizedContextList contextData={contextData} />
    </CustomAccordion>
  ) : null;
};

export default FinalizedAudienceContext;
