import { Button, ButtonProps, styled } from "@mui/material";


const CustomButton = styled(Button)<ButtonProps>(() => ({
    minWidth: '142px',
    height: '40px',
    borderRadius: '8px',
    borderWidth: '2px',
    borderColor: '#4E3BC9',
    '&:hover': {
      borderWidth: '2px'
    }
  }));

export default CustomButton;