import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { InitialState } from '../../../redux/slice';

type TPayloadAction = {
  locationIdx: number;
  monthIdx: number;
  updatedValue: number;
};

export const campaignDurationReducers = {
  setCampaignDurationBudget(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, monthIdx, updatedValue } = action.payload;

    if (state.plan?.hash){
      state.plan.hash.locations[locationIdx].duration[monthIdx].budget =
      updatedValue;
    state.plan.hash.locations[locationIdx].durationTotalBudget =
      state.plan.hash.locations[locationIdx].duration.reduce(
        (acc, dur) => dur.budget + acc,
        0
      );
    }
  },
  setCampaignDurationFrequency(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, monthIdx, updatedValue } = action.payload;
    if (state.plan?.hash){
      state.plan.hash.locations[locationIdx].duration[monthIdx].frequency =
      updatedValue;
    }
  },
  setCampaignDurationTotalFrequency(
    state: Draft<InitialState>,
    action: PayloadAction<{
      locationIdx: number;
      updatedValue: string;
    }>
  ) {
    const { locationIdx, updatedValue } = action.payload;

    if (state.plan?.hash){
      state.plan.hash.locations[locationIdx].durationTotalFrequency = updatedValue
      ? parseFloat(updatedValue)
      : 0;
    }
  }
};
