import {
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  CircularProgress
} from '@mui/material';
import { AlertCircle } from 'mdi-material-ui';

type TWarninigPopUp = {
  open: boolean;
  renderDescription?: string;
  handleClose: () => void;
  handleContinue: () => void;
  isLoading: boolean;
  isDraft?: boolean;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: 'background.paper',
  boxShadow: 24,
  boxSizing: 'boreder-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '35px',
  borderRadius: '8px'
};

const GetDescription = ({
  renderDescription
}: {
  renderDescription: string;
}) => {
  switch (renderDescription) {
    case 'draft':
      return (
        <Typography variant="subtitle1">
          When you click <strong>'Continue,'</strong> all your current progress
          will be lost. Please utilize the side menu to navigate between
          sections without losing any work. If you've made updates or changes,
          and you wish to proceed with these modifications, click on{' '}
          <strong>'Continue.'</strong>
        </Typography>
      );
    case 'media_space_waringing':
      return (
        <Typography variant="subtitle1">
          Having already transferred the media from the plan to the designated
          media space, would you like to proceed with moving these media items?
        </Typography>
      );
    default:
      return (
        <Typography variant="subtitle1">
          Clicking <strong>'Continue'</strong> will result in the loss of all
          your progress. Utilize the side menu to navigate between different
          sections without losing your current work.
        </Typography>
      );
  }
};

const WarninigPopUp = ({
  open,
  handleClose,
  handleContinue,
  renderDescription = '',
  isLoading,
  isDraft
}: TWarninigPopUp) => (
  <Modal
    keepMounted
    open={open}
    aria-labelledby="keep-mounted-modal-title"
    aria-describedby="keep-mounted-modal-description"
  >
    <Box sx={style}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: '#4E3BC9',
          height: '180px',
          width: '100%',
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px'
        }}
      >
        <AlertCircle sx={{ color: '#fff', fontSize: '80px' }} />
      </Stack>
      <Stack sx={{ gap: '8px', padding: '0 24px', textAlign: 'center' }}>
        <Typography variant="h1" sx={{ fontWeight: 600 }}>
          Caution
        </Typography>
        <GetDescription
          renderDescription={
            renderDescription
              ? renderDescription
              : isDraft
              ? 'draft'
              : 'default'
          }
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ gap: '24px', marginBottom: '24px' }}
      >
        <Button
          variant="outlined"
          sx={{ flexGrow: 1, width: '128px', height: '40px' }}
          onClick={() => handleClose()}
        >
          Close
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          sx={{
            flexGrow: 1,
            width: '128px',
            height: '40px'
          }}
          onClick={() => handleContinue()}
          startIcon={isLoading ? <CircularProgress size={24} /> : null}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  </Modal>
);

export default WarninigPopUp;
