import { lazy } from 'react';

const Mapping = lazy(() => import('./Mapping'));

const MappingConfig = {
  name: 'Mapping',
  path: 'mapping',
  element: <Mapping />,
  icon: 'radioBrief'
};

export default MappingConfig;
