import { Box, Typography, TextField, Button, InputAdornment, CircularProgress, Divider } from "@mui/material";
import { PencilOutline, Eye, EyeOff, CheckCircleOutline } from "mdi-material-ui";
import { useState } from "react";
import styles from './index.module.css';
import { GoogleLogin, CredentialResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { googleSignIn, signin } from "../../sessionSlice";

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

interface FormInput {
	email: string;
	password: string;
}

function LoginForm() {
	const [error, setError] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: '',
			password: '',
		}
	});

	const queryParams = new URLSearchParams(location.search);
	const redirectUrl = queryParams.get('redirect') || "/";

	const handleClick = () => {
		setShowPassword((prev) => !prev);
	};

	const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
		setIsLoading(true);
		const { credential } = credentialResponse;
		dispatch(googleSignIn(credential as string)).then(() => {
			setIsLoading(false);
			navigate(redirectUrl)
		})
	};

	const onSubmit: SubmitHandler<FormInput> = (data) => {
		setIsLoading(true);
		dispatch(signin(data)).then(() => {
			setIsLoading(false);
			navigate(redirectUrl)
		}).catch(err => {
			setIsLoading(false);
			setError(err.response.data.message);
		})
	};

	return (
		<Box>
			<Typography variant="h1" color="black" mb={4}>
				The Media Ant
			</Typography>
			<Typography variant="h3" color="black" mb={4}>
				Admin Dashboard
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<Controller
					name="email"
					control={control}
					render={({ field }) => <TextField
						{...field}
						label="Your Email ID"
						margin="dense"
						fullWidth={true}
						required
						size="medium"
						variant="outlined"
						sx={{
							marginBottom: "16px",
							"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
								borderColor: "text.dark",
							},
							"& .MuiInputLabel-outlined.Mui-focused": {
								color: "text.dark",
							},
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end" sx={{ cursor: "pointer" }}>
									<PencilOutline sx={{ color: "icon.light", width: 20, height: 20 }} />
								</InputAdornment>
							),
						}}
					/>}
				/>
				<Controller
					name="password"
					control={control}
					render={({ field }) => <TextField
						{...field}
						type={showPassword ? "text" : "password"}
						name="password"
						label="Enter Password"
						margin="dense"
						fullWidth={true}
						required
						size="medium"
						variant="outlined"
						sx={{
							marginBottom: "16px",
							"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
								borderColor: "text.dark",
							},
							"& .MuiInputLabel-outlined.Mui-focused": {
								color: "text.dark",
							},
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end" onClick={handleClick} sx={{ cursor: "pointer" }}>
									{showPassword ? (
										<Eye sx={{ color: "icon.light", width: 20, height: 20 }} />
									) : (
										<EyeOff sx={{ color: "icon.light", width: 20, height: 20 }} />
									)}
								</InputAdornment>
							),
						}}
					/>}
				/>
				<Button variant="contained" color="primary" type="submit" disabled={isLoading} className={styles.loginBtn}>
					{isLoading ? (
						<CircularProgress size={20} />
					) : (
						<Box display="flex" alignItems="center" justifyContent="center">
							<span>Submit</span> <CheckCircleOutline sx={{ marginLeft: "10px", width: 16, height: 16 }} />
						</Box>
					)}
				</Button>
				{error.length > 1 && (
					<Typography variant="body1" color="error.main" sx={{ mt: "10px" }}>
						{error}
					</Typography>
				)}
			</form>
			<Divider sx={{ margin: "16px 0px" }}><Typography variant="caption">OR</Typography></Divider>
			<Box sx={{ display: 'inline-block', margin: '0 auto' }}>
				<GoogleOAuthProvider clientId={googleClientId}>
					<GoogleLogin
						onSuccess={googleLoginSuccess}
						onError={() => {
							console.log('Login Failed');
						}}
						useOneTap
					/>
				</GoogleOAuthProvider>
			</Box>
		</Box>
	)
}

export default LoginForm