import CampaignBrief from './CampaignBrief/index';
import RadioBrief from './RadioBrief/index';
import MediaSpace from './MediaSpace/index';
import RankingFileUpload from './RankingFileUpload/index';
import MixMediaModeler from './MixMediaModeler/index';
import CampaignDuration from './CampaignDuration/index';
import BudgetAllocator from './BudgetAllocator/index';
import CampaignCalendar from './CampaignCalendar/index';
import Summary from './Summary/index';

export const radioHashChildRoutes = [
  {
    ...CampaignBrief
  },
  {
    ...RadioBrief
  },
  {
    ...MediaSpace
  },
  {
    ...RankingFileUpload
  },
  {
    ...MixMediaModeler
  },
  {
    ...CampaignDuration
  },
  {
    ...BudgetAllocator
  },
  {
    ...CampaignCalendar
  },
  {
    ...Summary
  }
];
