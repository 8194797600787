import { Suspense, useEffect } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchPlan, getMediaOptionTemplateId } from '../redux/slice';
import RadioHashMenu from './RadioHashMenu';

const filters = {
  limit: 30,
  skip: 0,
  name: 'Jingle',
  toolName: 'radio'
};

const projection = ['name', 'type', 'toolName', 'isActive'];

const RadioHash = () => {
  const isLoading = useAppSelector(
    (state: any) => state?.planRequests?.planLevelIsLoading
  );

  const dispatch = useAppDispatch();
  let { planId } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    if (planId && pathname) {
      dispatch(fetchPlan(planId));
    }
  }, [planId, pathname, dispatch]);

  useEffect(() => {
    dispatch(getMediaOptionTemplateId({ filters, projection }));
  }, [dispatch]);

  return (
    <Stack
      direction="row"
      sx={{ backgroundColor: '#fff', height: 'calc(100% - 64px)' }}
    >
      <RadioHashMenu />
      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={6}
          sx={{ marginTop: '120px', height: '300px', width: '100%' }}
        >
          <Typography>Loading...</Typography>
          <CircularProgress size={20} />
        </Stack>
      ) : (
        <Stack sx={{ flexGrow: 1, backgroundColor: '#fff', overflow: 'auto' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </Stack>
      )}
    </Stack>
  );
};

export default RadioHash;
