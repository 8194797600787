import { IBrand, ICampaignDetails, IMediaSpecificDetail } from './interface';

interface IGetCampaignBriefStaticData {
  brand?: IBrand;
  campaignDetails?: ICampaignDetails;
  mediaSpecificDetails?: IMediaSpecificDetail[];
}

interface IGetBudgetFromMediaSpecificDetails {
  mediaSpecificDetails?: IMediaSpecificDetail[];
  verticalName?: string;
}

export interface ICampaignBriefStaticData {
  productDescription: string;
  campaignDescription: string;
  budget: string | number;
  competition: string[];
  duration: string;
}

interface IGetDataFromMediaSpecificDetails {
  mediaSpecificDetails?: IMediaSpecificDetail[];
  verticalName?: string;
  key?: 'budget' | 'duration.value';
}

const campaignBriefStaticData: ICampaignBriefStaticData = {
  productDescription: '',
  campaignDescription: '',
  budget: '',
  competition: [],
  duration: ''
};

const getBudgetFromMediaSpecificDetails = ({
  mediaSpecificDetails = [],
  verticalName = 'digital'
}: IGetBudgetFromMediaSpecificDetails) => {
  const verticalMediaSpecificDetail = mediaSpecificDetails?.find(
    (mediaSpecificDetail) => mediaSpecificDetail.tool === verticalName
  );
  if (verticalMediaSpecificDetail !== undefined) {
    const { budget = 0 } = verticalMediaSpecificDetail;
    return budget;
  }
  return 0;
};

const getDurationWithUnits = (duration: {
  value: string | number;
  unit: string;
  flexible: boolean;
}) => {
  const { value, unit } = duration;
  return value && unit ? `${value} ${unit}` : '';
};

const getDurationFromMediaSpecificDetails = ({
  mediaSpecificDetails = [],
  verticalName = 'digital'
}: IGetDataFromMediaSpecificDetails) => {
  const verticalMediaSpecificDetail = mediaSpecificDetails?.find(
    (mediaSpecificDetail) => mediaSpecificDetail.tool === verticalName
  );
  if (verticalMediaSpecificDetail !== undefined) {
    return getDurationWithUnits(verticalMediaSpecificDetail.duration);
  }
  return '-';
};

export const getCampaignBriefStaticData = ({
  brand,
  campaignDetails,
  mediaSpecificDetails
}: IGetCampaignBriefStaticData) => {
  if (brand && campaignDetails) {
    campaignBriefStaticData.productDescription =
      brand?.productDescription ?? '';
    campaignBriefStaticData.campaignDescription =
      campaignDetails?.campaignRequirement ?? '';
    campaignBriefStaticData.budget =
      getBudgetFromMediaSpecificDetails({
        mediaSpecificDetails: mediaSpecificDetails
      }) ||
      (campaignDetails?.budget ?? 10_000);
    campaignBriefStaticData.competition =
      brand?.competitors?.map((item) => item?.brand ?? '-') ?? [];
    campaignBriefStaticData.duration =
      getDurationFromMediaSpecificDetails({
        mediaSpecificDetails: mediaSpecificDetails
      }) ||
      getDurationWithUnits(campaignDetails.duration) ||
      `1 day`;
  }
  return campaignBriefStaticData;
};
