import {
    MustInclude,
    MustExclude,
    NoPreference,
    DeleteReason,
    Vertical,
    Radio,
    Ppt
  } from "./Icons";
  import {
    CampaignBrief,
    FrequencyCalculator,
    FileUpload,
    TgBank,
    MixMediaModular,
    BudgetAllocator,
    CampaignDuration,
    CampaignCalendar,
    Summary,
    RadioBrief,
    BackgroundImage
  } from './MenuIcons';
  import {
    Airplane,
    Movie,
    MonitorCellphone,
    BookOpen,
    Newspaper,
    Car,
    Television,
    TelevisionClassic,
    AccountTieVoice
  } from 'mdi-material-ui';
  
  type IIconProps = {
    iconName: string;
    color?: string;
    size?: string;
  };
  
  type IIcons = {
    [key: string]: {
      icon: any;
      color: string;
    };
  };
  
  export const icons: IIcons = {
    television: {
      icon: TelevisionClassic,
      color: '#4E3BC9'
    },
    radio: {
      icon: Radio,
      color: '#4E3BC9'
    },
    outdoor: {
      icon: Television,
      color: '#4E3BC9'
    },
    nontraditional: {
      icon: Car,
      color: '#4E3BC9'
    },
    newspaper: {
      icon: Newspaper,
      color: '#4E3BC9'
    },
    magazine: {
      icon: BookOpen,
      color: '#4E3BC9'
    },
    'influencer-marketing': {
      icon: AccountTieVoice,
      color: '#4E3BC9'
    },
    digital: {
      icon: MonitorCellphone,
      color: '#4E3BC9'
    },
    cinema: {
      icon: Movie,
      color: '#4E3BC9'
    },
    airport: {
      icon: Airplane,
      color: '#4E3BC9'
    },
    'Must Include': {
      icon: MustInclude,
      color: '#00A154'
    },
    'Must Exclude': {
      icon: MustExclude,
      color: '#FD0000'
    },
    'No Preferences': {
      icon: NoPreference,
      color: '#DFA000'
    },
    deleteReasonIcon: {
      icon: DeleteReason,
      color: '#4E3BC9'
    },
    vertical: {
      icon: Vertical,
      color: '#4E3BC9'
    },
    campaignBrief: {
      icon: CampaignBrief,
      color: '#637085'
    },
    frequencyCalculator: {
      icon: FrequencyCalculator,
      color: '#637085'
    },
    fileUpload: {
      icon: FileUpload,
      color: '#637085'
    },
    tgBank: {
      icon: TgBank,
      color: '#637085'
    },
    mixMediaModular: {
      icon: MixMediaModular,
      color: '#637085'
    },
    budgetAllocator: {
      icon: BudgetAllocator,
      color: '#637085'
    },
    campaignDuration: {
      icon: CampaignDuration,
      color: '#637085'
    },
    campaignCalendar: {
      icon: CampaignCalendar,
      color: '#637085'
    },
    summary: {
      icon: Summary,
      color: '#637085'
    },
    radioBrief: {
      icon: RadioBrief,
      color: "#637085"
    },
    mediaSpace: {
      icon: BackgroundImage,
      color: "#637085"
    },
    ppt: {
      icon: Ppt,
      color: "#637085"
    }
  };
  
  const Icon = (props: IIconProps) => {
    const { iconName, color, size } = props;
  
    const Icon = icons[iconName].icon;
    if (size) {
      return (
        <Icon
          sx={{ fontSize: size, color: color ? color : icons[iconName].color }}
        />
      );
    } else {
      return <Icon color={color ? color : icons[iconName].color} />;
    }
  };
  
  export default Icon;
  