import { Button, Stack, Typography } from '@mui/material';
import { GenderFemale, GenderMale } from 'mdi-material-ui';
import { useFormContext, useWatch } from 'react-hook-form';

import { TFilters } from '../TargetGroup';

interface IGenderProps {
  targetKey: string;
  calculateAudienceSize?: (updatedFilters: Partial<TFilters>) => void;
}

const genderList = ['Male', 'Female'];

const Gender = ({ targetKey, calculateAudienceSize }: IGenderProps) => {
  const { setValue, control } = useFormContext(),
    gender = useWatch({ name: targetKey, control }),
    genderHelper = new Set<string>(gender);

  return (
    <Stack sx={{ gap: '0.75rem' }}>
      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        Gender *
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ flexWrap: 'wrap', gap: '0.75rem', padding: '0 0.75rem' }}
      >
        {genderList.map((genderItem, index) => {
          const hasGender = genderHelper.has(genderItem);
          return (
            <Button
              size="small"
              variant={hasGender ? 'contained' : 'outlined'}
              key={index}
              startIcon={
                genderItem === genderList[0] ? <GenderMale /> : <GenderFemale />
              }
              onClick={() => {
                if (hasGender) {
                  genderHelper.delete(genderItem);
                  setValue(targetKey, [...genderHelper]);
                } else {
                  genderHelper.add(genderItem);
                  setValue(targetKey, [...genderHelper]);
                }
                if (calculateAudienceSize) {
                  calculateAudienceSize({ gender: [...genderHelper] });
                }
              }}
            >
              {genderItem}
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default Gender;
