import React from 'react';
import { Stack } from '@mui/material';

import classes from '../Stepper/Stepper.module.css';

interface IWrapperContainerProps {
  children: React.ReactNode;
}

const WrapperContainer = ({ children }: IWrapperContainerProps) => (
  <Stack
    className={`${classes.scroll_bar}`}
    sx={{
      padding: '1rem',
      gap: '1rem',
      overflow: 'auto',
      height: "100%",
    }}
  >
    {children}
  </Stack>
);

export default WrapperContainer;
