import { lazy } from 'react';

const OutdoorBrief = lazy(() => import("./OutdoorBrief"));

const outdoorBriefConfig = {
  name: "Outdoor Brief",
  path: "outdoor-brief",
  element: <OutdoorBrief />,
  icon: 'radioBrief'
};

export default outdoorBriefConfig;
