import { lazy } from 'react';
import { SitemapOutline } from 'mdi-material-ui';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../../store';

const TasksList = lazy(() => import('./TasksList/index'));
const TaskManagment = lazy(() => import('../index'));

// const TasksConfig = {
//   name: 'Tasks',
//   path: 'tasks',
//   element: <Outlet />,
//   Icon: SitemapOutline,
//   loader: () => {
//     return import('./redux/slice').then((slice) => {
//       injectReducer('tasks', slice.default);
//       return true;
//     });
//   },
//   children: [{ path: '', element: <TasksList /> }]
// };

const FinanceTasksConfig = {
  name: 'Finance Team',
  path: 'finance',
  element: <Outlet />,
  Icon: SitemapOutline,
  loader: () => {
    return import('../redux/slice').then((slice) => {
      injectReducer('tasks', slice.default);
      return true;
    });
  },
  children: [
    {
      path: '',
      element: <TasksList />
    },
    {
      path: ':taskId',
      element: <TaskManagment />
    }
  ]
};

export default FinanceTasksConfig;
