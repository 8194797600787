import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';

import { IGeo, IVendor } from '../interface';
import { ISelectedVendorsForEachLocationSearch } from '../VendorCollection';

export const outdoorHashVendorCollection = createAppAsyncThunk<any, IGeo>(
  'plan-requests/outdoorHashVendorCollection',
  async (data, { extra: { API }, dispatch }) => {
    try {
      if (data && data?.hash) {
        const response = await API.get(
          `/companies/outdoor-vendors/${data.hash}`
        );

        return { availableVendors: response.data, geo: data };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

type TOutdoorHashSelectedVendors = {
  vendors:
    | { location: string; vendors: IVendor[] }[]
    | ISelectedVendorsForEachLocationSearch[];
  sendEmail?: boolean;
  hashName: string;
};

export const outdoorHashSelectedVendors = createAppAsyncThunk<
  any,
  TOutdoorHashSelectedVendors
>(
  'plan-requests/outdoorHashSelectedVendors',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data && data?.vendors && data?.hashName) {
        let url = `/plan-requests/${planId}/hash/outdoor/vendors`;
        if (data?.sendEmail) url += '?email=true';
        const response = await API.put(url, {
          vendors: data.vendors
        });

        if (data?.sendEmail && response?.status === 200) {
          dispatch(
            SnackBarActions.openSnack({
              message: 'Selected vendors have been notified via email.',
              open: true,
              type: 'success'
            })
          );
        }

        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashVendorCollectionExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(outdoorHashVendorCollection.fulfilled, (state, action) => {
    if (action?.payload?.availableVendors && action?.payload?.geo) {
      if (state?.outdoorHashVendorCollection?.length) {
        const isThisVendorCollectionAvailable =
          state.outdoorHashVendorCollection.find(
            (vendor) => vendor?.geo?.hash === action.payload?.geo?.hash
          );
        if (!isThisVendorCollectionAvailable) {
          state.outdoorHashVendorCollection = [
            ...state.outdoorHashVendorCollection,
            action.payload
          ];
        }
      } else {
        state.outdoorHashVendorCollection = [action.payload];
      }
    }
    return;
  });
  builder.addCase(outdoorHashSelectedVendors.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan && action?.payload?.hashName) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan[action.payload.hashName as keyof IPlan] =
        action.payload.updatedPlan[action.payload.hashName];
    }
  });
};
