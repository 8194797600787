import React, { Suspense, useEffect, useState } from 'react';
import { useAppDispatch } from './hooks';
import { getSession } from './sessionSlice';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Sidenav from './Sidenav';
import Header from './Header';

import '@fontsource/outfit/300.css';
import '@fontsource/outfit/400.css';
import '@fontsource/outfit/500.css';
import '@fontsource/outfit/700.css';
import { Outlet } from 'react-router';
import { Toolbar } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import SnackBar from './Shared/SnackBar';

const getToken = () => window.localStorage && window.localStorage.getItem('authToken');

function App() {
	const dispatch = useAppDispatch();
	const [token, setToken] = useState(getToken());
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (token) dispatch(getSession())
		if (!token) navigate(`/login?redirect=${location?.pathname}`);
	}, [token, dispatch])

	//event listener for token changes
	useEffect(() => {

		//handler for token change
		const handleLocalStorageChange = () => {
			const newToken = getToken();
			if (newToken !== token) setToken(newToken);
		}

		window.addEventListener("storage", handleLocalStorageChange)
		return () => window.removeEventListener("storage", handleLocalStorageChange)
	}, [token])


	return (
		<Box sx={{ display: 'flex' , height:"100%"}}>
			<CssBaseline />
			<Header />
			<Sidenav />
			<SnackBar />
			<Box
				component="main"
				sx={{ flexGrow: 1, width: { sm: `calc(100% - ${70}px)` } }}
			>
				<Toolbar />
				<Suspense fallback={<div>Loading</div>} >
					<Outlet />
				</Suspense>
			</Box>
		</Box>
	);
}

export default App;
