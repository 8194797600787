import { lazy } from 'react';

const ListingAndTagging = lazy(() => import("./ListingAndTagging"));

const listingAndTaggingConfig = {
  name: "Listing & Tagging",
  path: "listing-tagging",
  element: <ListingAndTagging />,
  icon: 'radioBrief'
};

export default listingAndTaggingConfig;