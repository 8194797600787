import CampaignBrief from './CampaignBrief/index';
import PrintBrief from './PrintBrief/index';
import MediaSpace from './MediaSpace/index';
import RankingFileUpload from './RankingFileUpload/index';
import MixMediaModeler from './MixMediaModeler/index';
import CampaignDuration from './CampaignDuration/index';
import BudgetAllocator from './BudgetAllocator/index';
import CampaignCalendar from './CampaignCalendar/index';
import Summary from './Summary/index';

export const printHashChildRoutes = [
  {
    ...CampaignBrief
  },
  {
    ...PrintBrief
  },
  {
    ...MediaSpace
  },
  {
    ...RankingFileUpload
  },
  {
    ...MixMediaModeler
  },
  {
    ...CampaignDuration
  },
  {
    ...BudgetAllocator
  },
  {
    ...CampaignCalendar
  },
  {
    ...Summary
  }
];

export const newStage: { [key: string]: string } = {
  '0': '',
  '10': 'print-brief',
  '20': 'media-space',
  '30': 'ranking-file-upload',
  '40': 'media-mix-modeler',
  '50': 'campaign-duration',
  '60': 'budget-allocator',
  '70': 'campaign-calendar',
  '80': 'summary',
  '90': 'summary'
};

export const stages: { [key: string]: string } = {
  '': '0',
  'print-brief': '10',
  'media-space': '20',
  'ranking-file-upload': '30',
  'media-mix-modeler': '40',
  'campaign-duration': '50',
  'budget-allocator': '60',
  'campaign-calendar': '70',
  summary: '80'
};
