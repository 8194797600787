import {
  ICampaignDetails,
  IGeo,
  IMediaSpecificDetail,
  ITargetGroup
} from './interface';

export interface IBrief {
  campaignDetails: Partial<ICampaignDetails>;
  targetGroup: Partial<ITargetGroup>;
  mediaSpecificDetails: IMediaSpecificDetail[];
}

interface IGetTargetGroupStaticData {
  brief: IBrief;
  toolName?: string;
}

const getTargetGroupBudgetFromCampaignDetails = ({
  budget
}: {
  budget: string | number | undefined;
}) => {
  let updatedBudget = budget ?? 10_000;
  if (typeof updatedBudget === 'string') {
    updatedBudget = parseInt(updatedBudget);
  }
  return updatedBudget;
};

const getTargetGroupDurationFromCampaignDetails = ({
  duration
}: {
  duration:
    | {
        value: string | number;
        unit: string;
        flexible: boolean;
      }
    | undefined;
}) => {
  let durVal = duration?.value ?? 1;
  if (typeof durVal === 'string') {
    durVal = parseInt(durVal);
  }
  return {
    value: durVal,
    unit: duration?.unit ?? 'days',
    flexible: duration?.flexible ?? false
  };
};

const getTargetGroupAgeFromBriefTargetGroup = ({
  age
}: {
  age: string[] | undefined;
}) => {
  return (age && age?.length !== 0) ? age : ['12 - 15', '16 - 19'];
};

const getTargetGroupIncomeFromBriefTargetGroup = ({
  income
}: {
  income: string[] | undefined;
}) => {
  return income ?? [];
};

const getTargetGroupGenderFromBriefTargetGroup = ({
  gender
}: {
  gender: string[] | undefined;
}) => {
  return gender ?? [];
};

const getTargetGroupGeosFromBriefTargetGroup = ({
  geos
}: {
  geos: IGeo[] | undefined;
}) => {
  return geos ?? [];
};

export const getTargetGroupStaticData = ({
  brief,
  toolName = 'digital'
}: IGetTargetGroupStaticData) => {
  const mediaSpecificDetails = brief?.mediaSpecificDetails ?? [];
  const digitalMediaSpecificDetails = mediaSpecificDetails.find(
    (mediaSpecificDetail) => mediaSpecificDetail.tool === 'digital'
  );

  return {
    tool: digitalMediaSpecificDetails?.tool ?? toolName,
    station: digitalMediaSpecificDetails?.station ?? "",
    language: digitalMediaSpecificDetails?.language ?? "",
    budget:
      digitalMediaSpecificDetails?.budget ??
      getTargetGroupBudgetFromCampaignDetails({
        budget: brief?.campaignDetails?.budget
      }),
    duration:
      digitalMediaSpecificDetails?.duration ??
      getTargetGroupDurationFromCampaignDetails({
        duration: brief?.campaignDetails?.duration
      }),
    age:
      digitalMediaSpecificDetails?.age ??
      getTargetGroupAgeFromBriefTargetGroup({ age: brief?.targetGroup?.age }),
    gender:
      digitalMediaSpecificDetails?.gender ??
      getTargetGroupGenderFromBriefTargetGroup({
        gender: brief?.targetGroup?.gender
      }),
    income:
      digitalMediaSpecificDetails?.income ??
      getTargetGroupIncomeFromBriefTargetGroup({
        income: brief?.targetGroup?.income
      }),
    geos:
      digitalMediaSpecificDetails?.geos ??
      getTargetGroupGeosFromBriefTargetGroup({ geos: brief?.targetGroup?.geos })
  };
};