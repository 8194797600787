import { lazy } from 'react';
import { TelevisionPlay } from 'mdi-material-ui';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../store';

const AdsList = lazy(() => import('./AdsList'));
const AddAd = lazy(() => import('./AddAd'));

const AdsConfig = {
  name: 'Ads',
  path: 'ads',
  element: <Outlet />,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('ad', slice.default);
      return true;
    });
  },
  Icon: TelevisionPlay,

  children: [
    {
      path: '',
      element: <AdsList />
    },
    {
      path: 'new',
      element: <AddAd />
    },
    {
      path: ':adId/edit',
      element: <AddAd />
    }
  ]
};

export default AdsConfig;
