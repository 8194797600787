import React, { useState } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useWatch } from 'react-hook-form';
import { Pencil } from 'mdi-material-ui';

import AudienceDialog from '../AudienceDialog';
import ContentCardRenderView from '../../Shared/ContentRenderView';

import ConvertToCurrencySystem from '../../../../Shared/ConvertToCurrency';
import { getGeo } from '../../Utils/getGeo';

import audienceSizeImg from '../../Shared/Assets/audienceSize.svg';

const labels = ['Geo Location', 'Gender', 'Age', 'Income'];
const interests = [
  {
    label: 'Behaviour/Interest',
    compType: 'audienceContext',
    key: 'interest'
  },
  { label: 'Persona', compType: 'userPersona', key: 'interest' }
];

export const getTargetKey = ({
  label,
  targetKey
}: {
  label: string;
  targetKey: string;
}) => {
  switch (label) {
    case labels[0]:
      return `${targetKey}.geos`;
    case labels[1]:
      return `${targetKey}.gender`;
    case labels[2]:
      return `${targetKey}.age`;
    case labels[3]:
      return `${targetKey}.income`;
    default:
      return `${targetKey}.age`;
  }
};

const CustomListItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  width: 'auto',
  padding: '0.125rem',
  '& .MuiListItemText-primary::after': {
    content: '" :"',
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
    color: theme.typography.body2.color
  },
  '& .MuiListItemText-secondary': {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.typography.h1.fontSize,
    lineHeight: theme.typography.h1.lineHeight,
    color: theme.typography.h1.color
  }
}));

const Required = () => (
  <Typography sx={{ color: 'red' }}>
    &#129300; This field is required*
  </Typography>
);

export const RenderGeoLocation = ({
  targetKey = 'digitalHash.targetGroup.geos'
}: {
  targetKey: string;
}) => {
  const geoLocation = useWatch({ name: targetKey });
  return geoLocation?.length === 0 ? (
    <Required />
  ) : (
    <Typography variant="body1">{getGeo(geoLocation)}</Typography>
  );
};

const RenderData = ({
  targetKey = 'digitalHash.targetGroup.age'
}: {
  targetKey: string;
}) => {
  const data = useWatch({ name: targetKey }) ?? [];
  return data?.length === 0 ? (
    <Required />
  ) : (
    <Typography variant="body1">{data.join(', ')}</Typography>
  );
};

const RenderChildren = ({
  label,
  targetKey
}: {
  label: string;
  targetKey: string;
}) => {
  switch (label) {
    case labels[0]:
      return (
        <RenderGeoLocation targetKey={getTargetKey({ label, targetKey })} />
      );
    case labels[1]:
      return <RenderData targetKey={getTargetKey({ label, targetKey })} />;
    case labels[2]:
      return <RenderData targetKey={getTargetKey({ label, targetKey })} />;
    case labels[3]:
      return <RenderData targetKey={getTargetKey({ label, targetKey })} />;
    default:
      return <Typography>-</Typography>;
  }
};

export const AudienceSizeView = ({ targetKey }: { targetKey: string }) => {
  const [open, setOpen] = useState(false);
  const audienceSize = useWatch({ name: `${targetKey}.audienceSize` }) ?? 0;
  const handleClose = () => setOpen(false);
  const handleOpen = (data: boolean) => setOpen(data);
  return (
    <Stack
      sx={{
        backgroundColor: '#fff',
        borderRadius: '0.5rem',
        padding: '1rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
      }}
    >
      <IconButton
        onClick={() => handleOpen(true)}
        sx={{
          alignSelf: 'flex-end',
          borderRadius: '8px',
          backgroundColor: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
        }}
      >
        <Pencil />
      </IconButton>
      <Typography variant="body1">Audience Size:</Typography>
      <Typography
        variant="h1"
        sx={{
          fontWeight: 600,
          fontSize: '80px',
          lineHeight: '96px'
        }}
      >
        {ConvertToCurrencySystem({ value: audienceSize, currency: false })}
      </Typography>
      {open ? (
        <AudienceDialog
          audienceSizeImg={audienceSizeImg}
          handleClose={handleClose}
          handleOpen={handleOpen}
          handleUpdateCustomAudience={() => {}}
          open={open}
          targetKey={`${targetKey}.audienceSize`}
        />
      ) : null}
    </Stack>
  );
};

const RenderText = ({
  targetKey,
  isGeo = false,
  interestKey = false
}: {
  targetKey: string;
  isGeo?: boolean;
  interestKey?: boolean;
}) => {
  const data = useWatch({ name: targetKey }) ?? '';
  let finalDataList = '';
  if (isGeo) {
    const geoData = getGeo(data);
    finalDataList = geoData ? geoData : '-';
  } else if (interestKey) {
    finalDataList = data ? data : '-';
  } else {
    const dataList = data.join(', ');
    finalDataList = dataList ? dataList : '-';
  }
  return <React.Fragment>{finalDataList}</React.Fragment>;
};

export const TargetGroupDetailsAsText = ({
  targetKey,
  compType
}: {
  targetKey: string;
  compType: string;
}) => (
  <List sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.75rem' }}>
    {labels.map((label, idx) => (
      <CustomListItem key={`${label}-${idx}`} title={label}>
        <ListItemText
          primary={label}
          secondary={
            <RenderText
              isGeo={label === labels[0]}
              targetKey={getTargetKey({
                label,
                targetKey: `digitalHash.audienceContext`
              })}
            />
          }
        />
      </CustomListItem>
    ))}
    {interests
      .filter((item) => item.compType === compType)
      .map((interest, idx) => (
        <CustomListItem
          key={`${interest.compType}-${idx}`}
          title={interest.label}
        >
          <ListItemText
            primary={interest.label}
            secondary={
              <RenderText
                isGeo={false}
                targetKey={`${targetKey}.${compType}.${interest.key}`}
                interestKey={true}
              />
            }
          />
        </CustomListItem>
      ))}
  </List>
);

export const TargetGroupDetails = ({ targetKey }: { targetKey: string }) => (
  <React.Fragment>
    {labels.map((label, idx) => (
      <ContentCardRenderView key={`${label}-${idx}`} title={label}>
        <RenderChildren label={label} targetKey={targetKey} />
      </ContentCardRenderView>
    ))}
  </React.Fragment>
);

const TargetGroupRightSideSection = ({
  targetKey = 'digitalHash.targetGroup'
}: {
  targetKey?: string;
}) => (
  <React.Fragment>
    <TargetGroupDetails targetKey={targetKey} />
    <AudienceSizeView targetKey={targetKey} />
  </React.Fragment>
);

export default TargetGroupRightSideSection;
