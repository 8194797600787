import { lazy } from 'react';

const GeoClusteringMain = lazy(() => import('./GeoClusteringMain'));

const GeoClusteringMainConfig = {
  name: "Geo Clustering",
  path: 'geo-clustering',
  element: <GeoClusteringMain />,
  icon: 'budget-allocator',
  state: 60
};

export default GeoClusteringMainConfig;
