import React from 'react';
import { CircularProgress, Stack } from '@mui/material';

interface ILoaderProps {
  children: React.ReactNode;
  isLoading: boolean;
}

const Loader = ({ children, isLoading }: ILoaderProps) => {
  if (!isLoading) return <React.Fragment>{children}</React.Fragment>;

  return (
    <Stack sx={{ display: 'grid', placeContent: 'center', height: '400px' }}>
      <CircularProgress />
    </Stack>
  );
};

export default Loader;
