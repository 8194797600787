import { lazy } from 'react';

const MediaPlan = lazy(() => import('./MediaPlan'));

const MediaPlanConfig = {
  name: 'Media Plan',
  path: 'media-plan',
  element: <MediaPlan />,
  icon: 'mediaPlans',
};

export default MediaPlanConfig;
