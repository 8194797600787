import ContextFileRightSideSection from '../AudienceContextFile/AudienceContextFileRightSideSection';
import CampaignBriefRightSideSection from '../CampaignBrief/CampaignBriefRightSideSection';
import TargetGroupRightSideSection from '../TargetGroup/TargetGroupRightSideSection';

interface IRightSideContent {
  activeStep: number;
}

const RightSideContent = ({ activeStep }: IRightSideContent) => {
  switch (activeStep) {
    case 0:
      return <CampaignBriefRightSideSection />;
    case 1:
      return <TargetGroupRightSideSection />;
    case 2:
      return (
        <ContextFileRightSideSection
          key={'audienceContext'}
          targetKey="digitalHash.audienceContext"
          compType="audienceContext"
        />
      );
    case 3:
      return (
        <ContextFileRightSideSection
          key={'userPersona'}
          targetKey="digitalHash.userPersona"
          compType="userPersona"
        />
      );
    default:
      return <></>;
  }
};

export default RightSideContent;
