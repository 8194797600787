import { createSlice } from '@reduxjs/toolkit';

export interface Snack {
  open: boolean;
  message: string;
  type?: string;
  duration?: number;
}

export interface InitialState {
  openSnack: Snack;
}

const initialState: InitialState = {
  openSnack: {
    open: false,
    message: '',
    type: 'info',
    duration: 5000
  }
};

const SnackBar = createSlice({
  name: 'snackBar',
  initialState,
  reducers: {
    openSnack(state, action) {
      const { open = false, message = '', type, duration } = action.payload;
      state.openSnack.open = open;
      state.openSnack.message = message;
      if (duration) state.openSnack.duration = duration;
      if (type) state.openSnack.type = type;
    }
  }
});

export const SnackBarActions = SnackBar.actions;

export default SnackBar.reducer;
