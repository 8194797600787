// Type guard to check if the value is a number
export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};

export const isValidNumber = (value: any): value is number => {
  return typeof value === 'number' && !isNaN(value);
};

export const isValidNonZeroNumber = (value: unknown): value is number => {
  return typeof value === 'number' && !isNaN(value) && value !== 0;
};

export const isValidValue = (data: unknown): data is number => {
  return isNumber(data) && isValidNonZeroNumber(data);
};
