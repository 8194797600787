import { lazy } from 'react';
import { StarCircle } from 'mdi-material-ui';
import { injectReducer } from '../store';
import { Outlet } from 'react-router-dom';

const BrandList = lazy(() => import('./BrandList'));

const AddBrand = lazy(() => import('./AddBrand/index'));

const BrandConfig = {
  name: 'Brands',
  path: 'brands',
  element: <Outlet />,
  Icon: StarCircle,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('brands', slice.default);
      return true;
    });
  },

  children: [
    { path: '', element: <BrandList /> },
    {
      path: 'new',
      element: <AddBrand />
    },
    {
      path: ':brandId/edit',
      element: <AddBrand />
    }
  ]
};

export default BrandConfig;
