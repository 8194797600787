import { Button, Stack, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { TFilters } from '../TargetGroup';

interface IIncomeProps {
  targetKey: string;
  calculateAudienceSize?: (updatedFilters: Partial<TFilters>) => void;
}

const incomeList = ['Low Income', 'High Income', 'Middle Income'];

const Income = ({ targetKey, calculateAudienceSize }: IIncomeProps) => {
  let { setValue, control } = useFormContext(),
    income = useWatch({ name: targetKey, control }),
    incomeHelper = new Set<string>(income);

  return (
    <Stack sx={{ gap: '0.75rem' }}>
      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        Income
      </Typography>
      <Stack
        direction="row"
        sx={{ flexWrap: 'wrap', gap: '0.75rem', padding: '0 0.75rem' }}
      >
        {incomeList.map((incomeItem, index) => {
          const hasIncome = incomeHelper.has(incomeItem);
          return (
            <Button
              size="small"
              variant={hasIncome ? 'contained' : 'outlined'}
              key={index}
              onClick={() => {
                if (hasIncome) {
                  incomeHelper.delete(incomeItem);
                  setValue(targetKey, [...incomeHelper]);
                } else {
                  incomeHelper.add(incomeItem);
                  setValue(targetKey, [...incomeHelper]);
                }
                if (calculateAudienceSize) {
                  calculateAudienceSize({ income: [...incomeHelper] });
                }
              }}
            >
              {incomeItem?.split(' ')[0]}
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default Income;
