import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';

type TCalendar = {
  location: string;
  vendorInventory: {
    vendorInventoryId: string;
    mediaId: string;
    months: { weekNo: number; value: number }[];
  }[];
};

export const outdoorHashCampaignCalendar = createAppAsyncThunk<
  any,
  { hashName: string; calendar: TCalendar[] }
>(
  'plan-requests/outdoorHashCampaignCalendar',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data.hashName && data.calendar) {
        const response = await API.put(
          `/plan-requests/${planId}/hash/outdoor/calendar`,
          {
            calendar: data.calendar
          }
        );

        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashCampaignCalendarExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(outdoorHashCampaignCalendar.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan && action?.payload?.hashName) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan[action.payload.hashName as keyof IPlan] =
        action.payload.updatedPlan[action.payload.hashName];
    }
  });
};
