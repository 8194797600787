import { lazy } from 'react';
import { injectReducer } from '../store';

const CampaignAnalytics = lazy(() => import('./CampaignAnalytics'));

const Icon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6875 8.80055L14.8064 5.3487C14.5704 5.13772 14.2483 5.01758 13.9166 5.01758L6.60729 5C5.72073 5 5 5.65638 5 6.46513V18.5319C5 19.326 5.74305 20 6.62323 20H17.4342C18.2984 20 19 19.3407 19 18.529V9.50088C19 9.22837 18.8884 8.97929 18.6875 8.80055ZM14.7904 6.9662L16.7964 8.7478H15.138C14.9531 8.7478 14.7904 8.61594 14.7904 8.4665V6.9662ZM17.4342 18.8279H6.62323C6.44465 18.8279 6.27563 18.6843 6.27563 18.529V6.46513C6.27563 6.30983 6.43189 6.1721 6.60729 6.1721L13.5148 6.18969V8.4665C13.5148 9.26646 14.2419 9.91991 15.138 9.91991H17.7244V18.529C17.7244 18.6755 17.6159 18.8279 17.4342 18.8279Z"
      fill="currentColor"
    />
    <path
      d="M9.46469 9.48037C9.1139 9.48037 8.82688 9.74409 8.82688 10.0664V16.3958C8.82688 16.7181 9.1139 16.9818 9.46469 16.9818C9.81549 16.9818 10.1025 16.7181 10.1025 16.3958V10.0664C10.1025 9.74409 9.81549 9.48037 9.46469 9.48037Z"
      fill="currentColor"
    />
    <path
      d="M12.0159 11.0041C11.6651 11.0041 11.3781 11.2678 11.3781 11.5902V16.3958C11.3781 16.7181 11.6651 16.9818 12.0159 16.9818C12.3667 16.9818 12.6538 16.7181 12.6538 16.3958V11.5902C12.6538 11.2678 12.3667 11.0041 12.0159 11.0041Z"
      fill="currentColor"
    />
    <path
      d="M14.5672 12.5278C14.2164 12.5278 13.9294 12.7916 13.9294 13.1139V16.3958C13.9294 16.7181 14.2164 16.9818 14.5672 16.9818C14.918 16.9818 15.205 16.7181 15.205 16.3958V13.1139C15.205 12.7916 14.918 12.5278 14.5672 12.5278Z"
      fill="currentColor"
    />
  </svg>
);

const CampaignAnalyticsConfig = {
  name: 'R&A',
  path: 'campaign-analytics',
  element: <CampaignAnalytics />,
  Icon,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('campaignAnalytics', slice.default);
      return true;
    });
  }
};

export default CampaignAnalyticsConfig;
