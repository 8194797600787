import { AxiosError } from 'axios';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';

import { IVendorInventory } from '../interface';

interface IOutdoorHashBulkAssignTags {
  tags: string[];
  inventoryIds: string[];
  hashName: string;
}

interface IOutdoorHashSelectMedia {
  mediaId: string;
  inventoryId: string;
  hashName: string;
}

interface IOutdoorHashCreateMedia {
  inventory: IVendorInventory & { file: File | null };
  hashName: string;
}

export const outdoorHashUploadMediaLogo = createAppAsyncThunk<
  any,
  { file: File }
>(
  'plan-requests/outdoorHashUploadMediaLogo',
  async (data, { extra: { API }, dispatch }) => {
    const formData = new FormData();
    formData.append('file', data.file);

    try {
      if (data.file) {
        const response = await API.post(`media/upload/logo`, formData);
        dispatch(
          SnackBarActions.openSnack({
            message: 'Image uploaded successfully',
            open: true,
            type: 'success'
          })
        );
        return response?.data?.imageUrl;
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashBulkAssignTags = createAppAsyncThunk<
  any,
  IOutdoorHashBulkAssignTags
>(
  'plan-requests/outdoorHashBulkAssignTags',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    try {
      if (data?.tags && data?.inventoryIds && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/outdoor/inventory/tags`,
          {
            tags: data.tags,
            inventoryIds: data.inventoryIds
          }
        );
        dispatch(
          SnackBarActions.openSnack({
            message: 'Tags updated successfully',
            open: true,
            type: 'success'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashSelectMedia = createAppAsyncThunk<
  any,
  IOutdoorHashSelectMedia
>(
  'plan-requests/outdoorHashSelectMedia',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data?.inventoryId && data?.mediaId && data?.hashName) {
        const response = await API.put(
          `plan-requests/${planId}/hash/outdoor/inventory/${data.inventoryId}/medias/${data.mediaId}`
        );
        dispatch(
          SnackBarActions.openSnack({
            message: 'The chosen media has been updated.',
            open: true,
            type: 'success'
          })
        );
        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

const getCreateMediaFormData = (file: File, inventory: object): FormData => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('inventory', JSON.stringify(inventory));

  return formData;
};

export const outdoorHashCreateMedia = createAppAsyncThunk<
  any,
  IOutdoorHashCreateMedia
>(
  'plan-requests/outdoorHashCreateMedia',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id,
      { file, logo, ...inventory } = data.inventory;

    if (!planId || !data?.inventory || !data?.hashName || !file)
      return undefined;

    const formData = getCreateMediaFormData(file, inventory);

    try {
      const response = await API.post(
        `plan-requests/${planId}/hash/outdoor/create-media`,
        formData
      );
      dispatch(
        SnackBarActions.openSnack({
          message: 'The media has been created.',
          open: true,
          type: 'success'
        })
      );
      return {
        updatedPlan: response.data?.updatedPlanRequest,
        hashName: data?.hashName
      };
    } catch (err: unknown) {
      const errorMessage =
        err instanceof AxiosError && err.response
          ? err.response.data?.message || 'Error Occurred'
          : 'Unexpected error occurred';

      console.error('Error:', err);

      dispatch(
        SnackBarActions.openSnack({
          message: errorMessage,
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

const isValidPayload = (
  payload: any
): payload is { updatedPlan: any; hashName: keyof IPlan } =>
  payload &&
  typeof payload.updatedPlan !== 'undefined' &&
  typeof payload.hashName === 'string';

const updatePlanState = (
  state: InitialState,
  payload: { updatedPlan: any; hashName: keyof IPlan }
) => {
  if (isValidPayload(payload)) {
    state.plan.currentState = payload.updatedPlan.currentState;
    state.plan[payload.hashName] = payload.updatedPlan[payload.hashName];
  }
};

export const outdoorHashListingAndTaggingExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder
    .addCase(outdoorHashBulkAssignTags.fulfilled, (state, action) =>
      updatePlanState(state, action.payload)
    )
    .addCase(outdoorHashSelectMedia.fulfilled, (state, action) =>
      updatePlanState(state, action.payload)
    )
    .addCase(outdoorHashCreateMedia.fulfilled, (state, action) =>
      updatePlanState(state, action.payload)
    );
};