import { Stack, Typography } from '@mui/material';

const NotFound = () => (
  <Stack
    justifyContent="center"
    alignItems="center"
    gap="1rem"
    sx={{
      height: 'calc(100% - 64px)'
    }}
  >
    <Typography variant="h1" sx={{ fontSize: '42px', fontWeight: 600 }}>
      404
    </Typography>
    <Typography variant="h1">Page not found</Typography>
    <Typography variant="h3" sx={{ textAlign: 'center' }}>
      Oops! The page you’re looking for doesn’t exist, or something went wrong.{' '}
      <br />
      Please check the URL or try again later.
    </Typography>
    <Typography>
      Lost? You can either go back or head over to the <a href="/">Home page</a>{' '}
      to find a new path.
    </Typography>
  </Stack>
);

export default NotFound;
