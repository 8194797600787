import MultiVerticalBrief from './MultiVerticalBrief/index';
import TargetGroup from './TargetGroup/index';
import RankingDataInput from './RankingDataInput/index';
import BdiVsCdi from './BdiVsCdi/index';
import MediaMap from './MediaMap/index';
import BudgetSplit from './BudgetSpit/index';
import GeoClustering from './GeoClustering/index';
import BudgetAllocator from './BudgetAllocator/index';
import CampaignCalendar from './CampaignCalendar/index';
import CaseStudy from './CaseStudy/index';

export const hashV2ChildRoutes = [
  {
    ...MultiVerticalBrief
  },
  {
    ...TargetGroup
  },
  {
    ...RankingDataInput
  },
  {
    ...BdiVsCdi
  },
  {
    ...MediaMap
  },
  {
    ...BudgetSplit
  },
  {
    ...GeoClustering
  },
  {
    ...BudgetAllocator
  },
  {
    ...CampaignCalendar
  },
  {
    ...CaseStudy
  }
];

export const stagesHelper: Record<number, { name: string; path: string }> = {
  0: {
    name: 'Multi Vertical Brief',
    path: ''
  },
  10: {
    name: 'Target Group',
    path: 'target-group'
  },
  20: {
    name: 'Ranking Data Input',
    path: 'ranking-data-input'
  },
  30: {
    name: 'BDI Vs CDI',
    path: 'bdi-vs-cdi'
  },
  40: {
    name: 'Media Map',
    path: 'media-map'
  },
  50: {
    name: 'Budget Split',
    path: 'budget-split'
  },
  60: {
    name: 'Geo Clustering',
    path: 'geo-clustering'
  },
  70: {
    name: 'Budget Allocator',
    path: 'budget-allocator'
  },
  80: {
    name: 'Campaign Calendar',
    path: 'campaign-calendar'
  },
  90: {
    name: "KPI's & Case Study",
    path: 'kpi-case-study'
  }
};

const getStagesWithCurrentStateAsKey = () => {
  let stages: Record<number, string> = {};

  for (const key in stagesHelper) {
    stages[key] = stagesHelper[key].path;
  }

  return stages;
};

const getStagesWithPathAsKey = () => {
  let stages: Record<string, number> = {};

  for (const key in stagesHelper) {
    stages[stagesHelper[key].path || 'multi-vertical-brief'] = Number(key);
  }

  return stages;
};

export const hashStagesWithCurrentStateAsKey: Record<number, string> =
  getStagesWithCurrentStateAsKey();
export const hashStagesWithPathAsKey: Record<string, number> =
  getStagesWithPathAsKey();
