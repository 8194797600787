import { DatabaseOutline } from 'mdi-material-ui';
import { lazy } from 'react';

const Mash = lazy(() => import('./Mash'));

const MashConfig = {
  name: 'Mash',
  path: 'mash',
  element: <Mash />,
  icon: DatabaseOutline
};

export default MashConfig;
