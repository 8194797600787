import { Suspense, useEffect, useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { CircularProgress, Stack, Typography } from '@mui/material';

import HashMain from './HashMain';
import HashNav from '../../../components/SharedHashV2/HashNav';

import { fetchPlan } from '../redux/slice';
import { fetchCategory } from './redux/reducers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hashStagesWithPathAsKey, hashV2ChildRoutes } from './config';

export interface ICategoryConfig {
  key: string;
  value: string;
}

const Fallback = () => (
  <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
    <Typography>Loading...</Typography>
  </Stack>
);

const HashV2 = () => {
  const [categoryConfig, setCategoryConfig] = useState<ICategoryConfig[]>([]);
  const isLoading = useAppSelector(
      (state: any) => state?.planRequests?.planLevelIsLoading
    ),
    renderRef = useRef(false);

  const dispatch = useAppDispatch(),
    { planId } = useParams();

  useEffect(() => {
    if (!renderRef.current && planId) {
      dispatch(fetchPlan(planId));
      renderRef.current = true;
    }
  }, [planId, dispatch]);

  useEffect(() => {
    dispatch(fetchCategory()).then((response) => {
      if (response?.payload?.categoryConfig) {
        const helperCategoryConfig = response.payload.categoryConfig,
          formattedCategoryConfig = Object.keys(helperCategoryConfig).map(
            (config) => ({ key: config, value: helperCategoryConfig[config] })
          );
        setCategoryConfig(formattedCategoryConfig);
      }
    });
  }, [dispatch]);

  return (
    <Stack
      direction="row"
      sx={{ backgroundColor: '#fff', height: 'calc(100% - 64px)' }}
    >
      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{
            marginTop: '120px',
            height: '300px',
            width: '100%',
            gap: '1rem'
          }}
        >
          <CircularProgress size={20} />
          <Typography>Loading...</Typography>
        </Stack>
      ) : (
        <Stack
          direction="row"
          sx={{ backgroundColor: '#FAFBFC', width: '100%', height: '100%' }}
        >
          <HashNav
            hashStagesWithPathAsKey={hashStagesWithPathAsKey}
            hashV2ChildRoutes={hashV2ChildRoutes}
          />
          <HashMain categoryConfig={categoryConfig}>
            <Suspense fallback={<Fallback />}>
              <Outlet />
            </Suspense>
          </HashMain>
        </Stack>
      )}
    </Stack>
  );
};

export default HashV2;
