import { lazy } from 'react';
import { SquareEditOutline } from 'mdi-material-ui';
import { injectReducer } from '../store';

const Roles = lazy(() => import('./RolesTable'));

const RolesConfig = {
  name: 'User Roles',
  path: 'roles',
  element: <Roles />,
  Icon: SquareEditOutline,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('roles', slice.default);
      return true;
    });
  }
};

export default RolesConfig;
