import { lazy } from 'react';

const ReportsAndAnalytics = lazy(() => import('./ReportsAndAnalytics'));

const ReportsAndAnalyticsConfig = {
  name: 'Reports & Analytics',
  path: 'reports-and-analytics',
  element: <ReportsAndAnalytics />,
  icon: 'reportsAndAnalytics'
};

export default ReportsAndAnalyticsConfig;
