import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';

import { useAppDispatch } from '../../../hooks';
import { updateHashV2State } from '../redux/reducers';
import { LOADING_STATE } from '../HashMainContent';

import {
  hashStagesWithPathAsKey,
  hashStagesWithCurrentStateAsKey
} from '../config';
import { handleCampaignBriefError } from '../ErrorHandler/campaignBriefErrorHandler';
import { handleTargetGroupError } from '../ErrorHandler/targetGroupErrorHandler';
import { handleRankingFileUploadError } from '../ErrorHandler/rankingFileUploadErrorHandling';
import { handleNextStep, triggerError } from './helperFunctions';

import classes from '../../../../components/SharedHashV2/styles.module.css';
import { handleKpiCaseStudyError } from '../ErrorHandler/kpiCaseStudyErrorHandler';
import { handleBudgetSplitError } from '../ErrorHandler/budgetSplitErrorHandling';

type TStepperAction = {
  loadingState: LOADING_STATE;
  handleLoading: (loadingState: LOADING_STATE) => void;
};

const hashName = 'hashV2';

const helper: { [k: number]: string } = {
  0: 'hashV2.campaignBrief',
  10: 'hashV2.targetGroup',
  20: 'hashV2.locations',
  30: 'hashV2.locations',
  40: 'hashV2.locations',
  50: 'hashV2.locations',
  60: 'hashV2.geoClustering',
  70: 'hashV2.clusters',
  80: 'hashV2.clusters',
  90: 'hashV2.caseStudy'
};

const ActionFooter = ({ loadingState, handleLoading }: TStepperAction) => {
  const { getValues, setValue } = useFormContext(),
    dispatch = useAppDispatch(),
    navigate = useNavigate(),
    { planId } = useParams(),
    location = useLocation(),
    path = location.pathname,
    segments = path.split('/'),
    stage = segments[segments.length - 1],
    step = hashStagesWithPathAsKey[stage] ?? 0,
    disableBackBtn = step === 0,
    stateUpdate = step + 10,
    nextStep =
      hashStagesWithCurrentStateAsKey[stateUpdate] ??
      hashStagesWithCurrentStateAsKey[0];

  const callbackFunFinally = () => {
    handleLoading(LOADING_STATE.DEFAULT);
    if (step === 90) navigate(`/plans`);
  };

  const handleLoadingStateGeneric = (loadingState: LOADING_STATE) =>
    handleLoading(loadingState);

  const handleNextStepGeneric = (
    error: string,
    step: number,
    callbackFun: () => void,
    callbackFunFinally: () => void,
    key: string = hashName,
    handleLoadingState: () => void
  ) => {
    if (error) {
      triggerError(error, dispatch);
      return;
    }

    handleLoadingState();

    const values = getValues(key);
    handleNextStep(step, dispatch, values, callbackFun, callbackFunFinally);
  };

  const handleNext = () => {
    const key = hashName;

    const stepHandlers = {
      0: handleCampaignBriefError,
      10: handleTargetGroupError,
      20: handleRankingFileUploadError,
      50: handleBudgetSplitError,
      90: handleKpiCaseStudyError
    };

    const value = getValues(`${helper[step]}`),
      error = stepHandlers[step as keyof typeof stepHandlers]
        ? stepHandlers[step as keyof typeof stepHandlers](value)
        : '';

    handleNextStepGeneric(
      error,
      step,
      callbackFun,
      callbackFunFinally,
      key,
      () => handleLoadingStateGeneric(LOADING_STATE.SAVE)
    );
  };

  const handleDraft = () => {
    const key = hashName;

    const stepHandlers = {
      0: handleCampaignBriefError,
      10: handleTargetGroupError,
      20: handleRankingFileUploadError,
      50: handleBudgetSplitError,
      90: handleKpiCaseStudyError
    };

    const value = getValues(`${helper[step]}`),
      error = stepHandlers[step as keyof typeof stepHandlers]
        ? stepHandlers[step as keyof typeof stepHandlers](value)
        : '';

    // since this is a draft save we don't have to pass the callbackFun
    handleNextStepGeneric(
      error,
      step,
      () => {
        callbackFunFinally();
      },
      callbackFunFinally,
      key,
      () => handleLoadingStateGeneric(LOADING_STATE.DRAFT)
    );
  };

  const callbackFun = async () => {
    try {
      const response = await dispatch(
        updateHashV2State({
          state: stateUpdate,
          hashName: hashName
        })
      );

      handleResponse(response);
    } catch (error) {
      console.error('Error updating state:', error);
    } finally {
      callbackFunFinally();
    }
  };

  const handleResponse = (response: any) => {
    const updatedPlan = response?.payload?.updatedPlan;

    if (updatedPlan?.hashV2) setValue(hashName, updatedPlan.hashV2);

    if (!response?.error) navigateToNextStep();
  };

  const navigateToNextStep = () => {
    const nextStepPath = nextStep !== undefined ? `/${nextStep}` : '';
    navigate(`/plans/${planId}/hash-v2${nextStepPath}`);
  };

  const navigateToPrevStep = () => {
    const prevPath = hashStagesWithCurrentStateAsKey[step - 10],
      prevStepPath = prevPath ? `/${prevPath}` : '';

    navigate(`/plans/${planId}/hash-v2${prevStepPath}`);
  };

  return (
    <Box className={classes.stepper_action_container}>
      {step > 0 ? (
        <Button
          variant="outlined"
          size="small"
          disabled={disableBackBtn}
          onClick={navigateToPrevStep}
          sx={{ minWidth: '100px' }}
        >
          Back
        </Button>
      ) : null}
      <Button
        disabled={
          loadingState === LOADING_STATE.DRAFT ||
          loadingState === LOADING_STATE.SAVE
        }
        size="small"
        startIcon={
          loadingState === LOADING_STATE.DRAFT ? (
            <CircularProgress size={20} />
          ) : null
        }
        variant="contained"
        onClick={handleDraft}
        sx={{ ml: 'auto', mr: 1, minWidth: '100px' }}
      >
        Save As Draft
      </Button>
      <Button
        disabled={
          loadingState === LOADING_STATE.SAVE ||
          loadingState === LOADING_STATE.DRAFT
        }
        size="small"
        startIcon={
          loadingState === LOADING_STATE.SAVE ? (
            <CircularProgress size={20} />
          ) : null
        }
        variant="contained"
        onClick={handleNext}
        sx={{ mr: 1, minWidth: '100px' }}
      >
        {true ? 'Next' : 'Go To Plan'}
      </Button>
    </Box>
  );
};

export default ActionFooter;
