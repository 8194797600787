import { SubtitlesOutline } from 'mdi-material-ui';
import { lazy } from 'react';

const Seo = lazy(() => import('./Seo'));

const SeoConfig = {
  name: 'SEO Title & Meta Description',
  path: 'seo',
  element: <Seo />,
  icon: SubtitlesOutline
};

export default SeoConfig;
