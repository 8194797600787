import BannerConfig from './Form/Banner';
import CompanyOverviewConfig from './Form/CompanyOverview';
import FaqConfig from './Form/Faq';
import MashConfig from './Form/Mash';
import SeoConfig from './Form/Seo';
import Analytics from './Form/Analytics';

export const fieldsRoute = [
  { ...BannerConfig },
  { ...Analytics },
  { ...MashConfig },
  { ...CompanyOverviewConfig },
  { ...FaqConfig },
  { ...SeoConfig }
];
