import { lazy } from 'react';

const Summary = lazy(() => import("./Summary"));

const SummaryConfig = {
    name: 'Summary',
    path: 'summary',
    element: <Summary />,
    icon: 'summary',
};

export default SummaryConfig;
