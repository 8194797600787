import { lazy } from 'react';

const MixMediaModeler = lazy(() => import('./MixMediaModeler'));

const MixMediaModelerConfig = {
  name: 'MMM',
  path: 'media-mix-modeler',
  element: <MixMediaModeler />,
  icon: 'mixMediaModular'
};

export default MixMediaModelerConfig;
