import { Box, List, Typography } from '@mui/material';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Check } from 'mdi-material-ui';
import styles from './hashMenu.module.css';
import Icon from '../../../components/SharedHashes/Shared/SharedIcons';
import { useAppSelector } from '../../hooks';

type THashMenu = {
    stages: {
        [key: string]: string;
    },
    newStage: {
        [key: string]: string;
    },
    config: {
        name: string;
        path: string;
        element: React.JSX.Element;
        icon: string;
    }[]
}

const HashMenu = ({newStage, stages, config}: THashMenu) => {
  const plan = useAppSelector((state: any) => state.planRequests?.plan),
    location = useLocation(),
    { planId } = useParams(),
    path = location.pathname,
    segments = path.split('/');
  let stage = segments[segments.length - 1];

  if (stage === planId) {
    stage = '';
  }

  return (
    <Box sx={{ backgroundColor: '#F5F4FC', minWidth: '220px' }}>
      {plan && plan._id && (
        <List sx={{ paddingTop: '16px' }}>
          {config
            .filter((r: any) => !('display' in r && r.display === false))
            .map((item, idx) => (
              <NavLink
                key={idx}
                className={({ isActive }) => {
                  return isActive
                    ? `${styles.NavItem} ${styles.active}`
                    : styles.NavItem;
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  pointerEvents:
                    stages[item.path] >
                    stages[newStage[plan?.currentState ?? 0]]
                      ? 'none'
                      : 'auto'
                }}
                to={item.path}
                end
              >
                <Box
                  sx={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    display: 'grid',
                    placeContent: 'center',
                    color:
                      stages[item.path] <
                        stages[newStage[plan?.currentState ?? 0]] ||
                      (stages[item.path] === stages['summary'] &&
                        plan?.currentState === 90)
                        ? '#FFF'
                        : '#637085',
                    backgroundColor:
                      stages[item.path] <=
                      stages[newStage[plan?.currentState ?? 0]]
                        ? stages[item.path] === stages['summary'] &&
                          plan?.currentState === 90
                          ? '#277252'
                          : stages[item.path] ===
                            stages[newStage[plan?.currentState ?? 0]]
                          ? '#4E3BC9'
                          : '#277252'
                        : 'transparent',
                    border:
                      stages[item.path] < stages[stage]
                        ? 'none'
                        : '1px solid #637085'
                  }}
                >
                  {stages[item.path] <
                    stages[newStage[plan?.currentState ?? 0]] ||
                  (stages[item.path] === stages['summary'] &&
                    plan?.currentState === 90) ? (
                    <Check sx={{ fontSize: '20px', color: 'inherit' }} />
                  ) : (
                    <Icon
                      color={
                        stages[item.path] ===
                        stages[newStage[plan?.currentState ?? 0]]
                          ? '#fff'
                          : '#637085'
                      }
                      iconName={item.icon}
                    />
                  )}
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    color: 'inherit',
                    paddingLeft: '4px',
                    fontWeight:
                      stages[item.path] ===
                      stages[newStage[plan?.currentState ?? 0]]
                        ? 600
                        : 400
                  }}
                >
                  {item.name}
                </Typography>
              </NavLink>
            ))}
        </List>
      )}
    </Box>
  );
};

export default HashMenu;
