import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Pencil } from 'mdi-material-ui';

import CustomizedModal from '../../../../../components/SharedHashes/Shared/CustomizedModal';
import TargetGroup from '../../TargetGroup/TargetGroup';

const targetKey = 'digitalHash.audienceContext';

const TargetGroupPopUp = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <IconButton
        disableRipple
        sx={{ alignSelf: 'flex-end', backgroundColor: '#4e3bc9' }}
        onClick={() => setOpen(true)}
      >
        <Pencil sx={{ fontSize: '1rem', color: '#fff' }} />
      </IconButton>
      <CustomizedModal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        title={'Demographics filters'}
        width={'800px'}
      >
        <TargetGroup targetKey={targetKey} />
      </CustomizedModal>
    </React.Fragment>
  );
};

export default TargetGroupPopUp;
