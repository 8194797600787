import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';
import { TLocation } from '../interface';

export const printHashCampaignCalendar = createAppAsyncThunk<
  any,
  { locations: TLocation[]; hashName: string; hashSpecificPath: string }
>(
  'plan-requests/printHashCampaignCalendar',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data && data?.locations && data?.hashSpecificPath) {
        const response = await API.put(
          `/plan-requests/${planId}/${data.hashSpecificPath}`,
          {
            locations: [...data.locations]
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const printHashCampaignCalendarExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(printHashCampaignCalendar.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan && action?.payload?.hashName) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan[action.payload.hashName as keyof IPlan] =
        action.payload.updatedPlan[action.payload.hashName];
    }
  });
};
