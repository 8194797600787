import { isValidNonZeroNumber } from '../DigitalHash/BudgetAllocator/CalculationFormulas/utils';

interface IGetCampaignBudget {
  campaignDetails: { budget: string | number };
}

type TUnit = 'months' | 'weeks' | 'days';

interface IGetCampaignDuration {
  campaignDetails: {
    duration: { flexible: boolean; unit: TUnit; value: unknown } | undefined;
  };
}

type TGetCampaignBrief = IGetCampaignBudget & IGetCampaignDuration;

const defaultBudget = 10_000,
  audienceType = 'B2B',
  creativeType: string[] = [],
  campaignCategory = 'agriculture',
  campaignObjective = [
    {
      key: 'awareness',
      isSelected: true,
      options: [
        {
          key: 'launch',
          isSelected: false
        },
        {
          key: 'reminder',
          isSelected: false
        }
      ]
    },
    {
      key: 'consideration',
      isSelected: false,
      options: [
        {
          key: 'engagement',
          isSelected: false
        },
        {
          key: 'traffic',
          isSelected: false
        }
      ]
    },
    {
      key: 'trial',
      isSelected: false,
      options: [
        {
          key: 'online sales',
          isSelected: false
        },
        {
          key: 'store footfall',
          isSelected: false
        },
        {
          key: 'app action',
          isSelected: false
        }
      ]
    }
  ];

export const getCampaignBudget = ({ campaignDetails }: IGetCampaignBudget) => {
  const { budget } = campaignDetails;
  let formattedBudget = defaultBudget;

  if (budget && isValidNonZeroNumber(Number(budget)))
    formattedBudget = Number(budget);

  return formattedBudget;
};

export const getCampaignDuration = ({
  campaignDetails: { duration }
}: IGetCampaignDuration) => {
  if (!duration) return 1;

  const { unit = 'days', value = 1 } = duration;

  let formattedDuration = 0,
    updatedValue: number;

  if (value && isValidNonZeroNumber(Number(value)))
    updatedValue = Number(value);
  else updatedValue = 1;

  switch (unit) {
    case 'days':
      formattedDuration = updatedValue;
      break;
    case 'months':
      formattedDuration = updatedValue * 30;
      break;
    case 'weeks':
      formattedDuration = updatedValue * 7;
      break;
    default:
      formattedDuration = updatedValue;
      break;
  }

  return formattedDuration;
};

export const getCampaignBrief = (brief: TGetCampaignBrief) => ({
  campaignObjective,
  productName: [],
  plannersInput: '',
  audienceType,
  creativeType,
  campaignCategory,
  duration: getCampaignDuration(brief),
  budget: getCampaignBudget(brief)
});
