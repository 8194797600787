import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

const Operations = lazy(() => import('./Operations'));
const Uploads = lazy(() => import('./Uploads'));

const Wrapper = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Outlet />
  </Suspense>
);

const OperationsConfig = {
  name: 'Operations',
  path: 'operations',
  element: <Wrapper />,
  icon: 'operations',
  children: [
    {
      path: '',
      element: <Operations />
    },
    {
      path: ':mopId',
      element: <Uploads />,
      display: false
    }
  ]
};

// export const UploadsConfig = {
//   name: 'Uploads',
//   path: `operations/:mopId/uploads`,
//   element: <Uploads />,
//   icon: '',
//   display: false
// };

export default OperationsConfig;
