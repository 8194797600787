import { ILocation } from '../BudgetSpit/interface';

const maxAllowedPrioritySelection = 5;

export const handleBudgetSplitError = (locations: ILocation[]) => {
  let error = '';

  if (
    locations.filter((location) => location?.priorityMarket).length >
    maxAllowedPrioritySelection
  )
    error = `Selection limit reached: You can select up to ${maxAllowedPrioritySelection} priority markets only.`;
  return error;
};
