import React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import NavItem from './NavItem';
import { childRoutes } from '../router';

const drawerWidth = 70;

function Sidenav() {
	return (
		<Drawer
		variant="permanent"
		sx={{
			width: drawerWidth,
			flexShrink: 0,
			[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
			zIndex:0
		}}
	>
		<Toolbar />
		<Box sx={{ overflow: 'auto' }}>
			<List>
				{
					childRoutes.map(({name, Icon, path}, index) => <NavItem text={name} key={index} Icon={Icon} path={path} />)
				}
			</List>
		</Box>
	</Drawer>
	)
}

export default Sidenav;