import { lazy } from 'react';

const PrintBrief = lazy(() => import('./PrintBrief'));

const PrintBriefConfig = {
  name: 'Print Brief',
  path: 'print-brief',
  element: <PrintBrief />,
  icon: 'radioBrief'
};

export default PrintBriefConfig;
