import axios from 'axios';
// import https from 'https';

const slsHost = process.env.REACT_APP_SLS_HOST;

const axiosConfig = {
	// httpsAgent: new https.Agent({
	// 	rejectUnauthorized: false
	// }),
	baseURL: slsHost
};

const slsService = axios.create(axiosConfig);

slsService.interceptors.request.use(function (config) {
	const authToken = (typeof (Storage) !== 'undefined' && localStorage.getItem('authToken'));
	if (authToken)
		config.headers['Authorization'] = 'Bearer ' + authToken;
	return config;
});

slsService.interceptors.response.use(function (res) {
	if (res.data.token)
		localStorage.setItem('authToken', res.data.token);
	return res;
});

export default slsService;