import { useState } from 'react';
import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import ActionFooter from './ActionFooter';

import { useAppSelector } from '../../hooks';
import { hashStagesWithPathAsKey } from './config';
import { getCampaignBrief } from './getDefaultValues';

import Loader from '../../../components/SharedHashV2/Loader';
import DoWeHaveContentToShow from '../../../components/SharedHashV2/DoWeHaveContentToShow';

import { ICategoryConfig } from './index';

interface IHashMainContentProps {
  children: React.ReactNode;
  categoryConfig: ICategoryConfig[];
}

export enum LOADING_STATE {
  DEFAULT = 'default',
  SAVE = 'save',
  DRAFT = 'draft'
}

const HashMainContent = ({
  children,
  categoryConfig
}: IHashMainContentProps) => {
  const plan = useAppSelector((state: any) => state.planRequests?.plan),
    userName = useAppSelector((state: any) => state?.session?.user?.name) ?? '',
    { hashV2CurrentState = 0 } = plan,
    [isLoading, setIsLoading] = useState(LOADING_STATE.DEFAULT);

  const methods = useForm({
    defaultValues: {
      _campaign: {
        ...plan?._campaign
      },
      hashV2: {
        ...(plan?.hashV2 ? plan.hashV2 : {}),
        campaignBrief:
          plan?.hashV2?.campaignBrief ?? getCampaignBrief(plan._campaign.brief)
      },
      categoryConfig
    }
  });

  const handleLoading = (loadingState: LOADING_STATE) =>
    setIsLoading(loadingState);

  return (
    <FormProvider {...methods}>
      <Stack
        sx={{
          overflow: 'auto',
          width: '100%',
          height: '100%'
        }}
      >
        <Loader
          isLoading={
            isLoading === LOADING_STATE.DRAFT ||
            isLoading === LOADING_STATE.SAVE
          }
        >
          <DoWeHaveContentToShow
            currentState={hashV2CurrentState}
            hashStagesWithPathAsKey={hashStagesWithPathAsKey}
            userName={userName}
          >
            {children}
          </DoWeHaveContentToShow>
        </Loader>
        <ActionFooter handleLoading={handleLoading} loadingState={isLoading} />
      </Stack>
    </FormProvider>
  );
};

export default HashMainContent;
