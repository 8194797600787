import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { InitialState } from '../../../redux/slice';

type TPayloadAction = {
  locationIdx: number;
  toolName: string;
  selectedWeeks: number[];
};

export const campaignCalendarReducers = {
  setCampaignCalendarSelectedWeeks(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, selectedWeeks, toolName } = action.payload;

    if (state.plan?.hash){
      state.plan.hash.locations[locationIdx].rankingData =
      state.plan.hash.locations[locationIdx].rankingData.map((data) => {
        if (data.toolName === toolName) {
          return { ...data, selectedWeeks };
        }
        return data;
      });
    }
  }
};
