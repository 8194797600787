import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../hooks";

interface InitialState {
	filters:{
		[key:string]:any, 
		productCategory:string, 
		brand:string[], 
		vertical?:string, 
		year:string
	},
	adexData:any,
	aggregate: boolean
}

interface Params {
	filters?: {},
	skip?: number,
	limit?: number
}

const initialState: InitialState  = {
	filters :{
		productCategory:'Pharma & Healthcare',
		brand:[],
		vertical:'',
		year:'2021'
	},
	adexData:[],
	aggregate: true
}

export const fetchAdexData = createAppAsyncThunk<void, Params>('adex/fetchList', async(args, {extra: {API}}) => {
	return API
		.get(`/ad-ex?params=${encodeURIComponent(JSON.stringify(args))}`)
		.then((data : any) => data.data.adEx )
})

const adexSlice = createSlice({
	name:'adex',
	initialState,
	reducers:{
		setFilters(state, action:PayloadAction<{filterKey:string; value:string | string[]}>){
			state.filters[action.payload.filterKey]= action.payload.value;
		}
	},
	extraReducers:(builder) => {
		builder.addCase(fetchAdexData.fulfilled, (state, action) => {
			state.adexData = action.payload
		})
	},	
})

export const adexActions = adexSlice.actions

export default adexSlice.reducer;