import { lazy } from 'react';

const RankingDataInputMain = lazy(() => import('./RankingDataInputMain'));

const RankingDataInputMainConfig = {
  name: 'Ranking Data Input',
  path: 'ranking-data-input',
  element: <RankingDataInputMain />,
  icon: 'ranking-data-input',
  state: 20
};

export default RankingDataInputMainConfig;
