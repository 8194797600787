import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PriceCalculatorForm from './PriceCalculatorForm';
import ItemsTable from './ItemsTable';
import { ITDSTax } from '../interface';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

interface IBillUploadFormProps {
  isSubmitting: boolean;
  bill: any;
  tds_taxes: ITDSTax[];
  poId?: string;
  onBillSubmit: (data: any) => void;
}

interface IData {
  name: string;
  value: string | number;
}

const BillUploadForm = ({
  isSubmitting,
  bill,
  tds_taxes,
  onBillSubmit
}: IBillUploadFormProps) => {
  const navigate = useNavigate();
  const { control, reset, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues: { ...bill }
  });

  const { fields, remove } = useFieldArray({
    control,
    name: 'line_items'
  });

  const onSubmit = (data: any) => {
    let bill = JSON.parse(JSON.stringify(data));
    bill.total = bill?.sub_total + bill?.tax_total + bill?.tds_amount;
    onBillSubmit(bill);
  };

  const onTDSChange = (tds_id: string) => {
    setValue('tds_tax_id', tds_id);
    if (tds_taxes) {
      let tds: ITDSTax | null =
        tds_taxes?.find((item: ITDSTax) => item.tax_id === tds_id) || null;
      if (tds) {
        setValue(
          'tds_amount',
          getValues('sub_total') * (tds?.tax_percentage / 100)
        );
        setValue('tds_percent', tds?.tax_percentage);
      }
    }
  };

  const data = [
    {
      name: 'Order Number',
      value: bill?.reference_number
    },
    {
      name: 'Vendor Name',
      value: bill?.vendor_name
    },
    {
      name: 'Source of Supply',
      value: bill?.source_of_supply
    },
    {
      name: 'Destination of Supply',
      value: bill?.destination_of_supply
    },
    {
      name: 'Branch Name',
      value: bill?.branch_name
    }
  ];

  return (
    <>
      <Grid container spacing={4} p={4}>
        <Grid item xs={12}>
          <Typography variant="h2">Details</Typography>
        </Grid>
        {data.map((item: IData) => (
          <>
            <Grid item xs={4}>
              <Typography>{item.name}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{item.value}</Typography>
            </Grid>
          </>
        ))}
      </Grid>
      <Divider />
      <form onSubmit={(e) => handleSubmit(onSubmit)(e)}>
        <Grid container spacing={4} p={4}>
          <Grid item xs={4}>
            <Typography>Bill#*</Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name="bill_number"
              control={control}
              render={({ field }) => <TextField type="number" {...field} />}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Bill Date</Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={field.value === '' ? null : new Date(field.value)}
                    onChange={(newValue: any) =>
                      field.onChange(
                        (field.value = format(new Date(newValue), 'yyyy-LL-dd'))
                      )
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Due Date</Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name="due_date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={field.value === '' ? null : new Date(field.value)}
                    onChange={(newValue: any) =>
                      field.onChange(
                        (field.value = format(new Date(newValue), 'yyyy-LL-dd'))
                      )
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Vendor Invoice Number*</Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name={`custom_fields[${bill?.custom_fields?.findIndex(
                (item: any) => item?.customfield_id === '303149000002180003'
              )}].value`}
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    field.onChange((field.value = event.target.value))
                  }
                  value={field.value}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Payment Schedule On</Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name={`custom_fields[${bill?.custom_fields?.findIndex(
                (item: any) => item?.customfield_id === '303149000011852127'
              )}].value`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={field.value === '' ? null : new Date(field.value)}
                    onChange={(newValue: any) =>
                      field.onChange(
                        (field.value = format(new Date(newValue), 'yyyy-LL-dd'))
                      )
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Actual Invoice Date</Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name={`custom_fields[${bill?.custom_fields?.findIndex(
                (item: any) => item?.customfield_id === '303149000029275915'
              )}].value`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={field.value === '' ? null : new Date(field.value)}
                    onChange={(newValue: any) =>
                      field.onChange(
                        (field.value = format(new Date(newValue), 'yyyy-LL-dd'))
                      )
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>Notes</Typography>
          </Grid>
          <Grid item xs={10}>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  InputProps={{
                    style: {
                      height: '100px',
                      background: '#F7F8F9'
                    }
                  }}
                  placeholder="Please add notes"
                  rows={4}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ItemsTable
              lineItems={fields}
              onDeleteRow={(index: number) => remove(index)}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <PriceCalculatorForm
              bill={bill}
              taxes={tds_taxes}
              total_tax={watch('tax_total' || 0)}
              tdsAmount={watch('tds_amount') || 0}
              tdsTaxId={watch('tds_tax_id') || ''}
              onTDSChange={onTDSChange}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={4}
          p={8}
        >
          <Button
            variant="text"
            sx={{
              fontWeight: 500,
              padding: '6px 12px',
              fontSize: '14px',
              borderRadius: '4px'
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: 500,
              padding: '6px 12px',
              fontSize: '14px',
              borderRadius: '4px'
            }}
            type="submit"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={18} />}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default BillUploadForm;
