import { lazy } from 'react';

const Allocator = lazy(() => import('./Allocator'));

const AllocatorConfig = {
  name: 'Budget Allocator',
  path: 'budget-allocator',
  element: <Allocator />
};

export default AllocatorConfig;
