import {
  digitalHashDoBudgetAllocatorCalculation,
  updateDigitalHashAudienceContext,
  updateDigitalHashBudgetAllocator,
  updateDigitalHashCampaignBrief,
  updateDigitalHashTargetGroup,
  updateDigitalHashUserPersona
} from './redux/reducers';
import { AppDispatch } from '../../../store';

import { TAudienceContext } from '../AudienceContextFile/interface';
import { IDigitalHash } from '../BudgetAllocator/interface';
import { ICampaignBrief } from '../CampaignBrief/interface';
import { ITargetGroup } from '../TargetGroup/interface';
import { TUserPersona } from '../UserPersonaFile/interface';

type TSendRequestCommonParameter = {
  dispatch: AppDispatch;
  callbackFun: () => void;
  callbackFunFinally: () => void;
};

type TSendCampaignBriefPutRequest = TSendRequestCommonParameter & {
  campaignBrief: ICampaignBrief;
};

type TSendTargetGroupPutRequest = TSendRequestCommonParameter & {
  targetGroup: ITargetGroup;
};

type TSendAudienceContextPutRequest = TSendRequestCommonParameter & {
  audienceContext: TAudienceContext;
};

type TSendUserPersonaPutRequest = TSendRequestCommonParameter & {
  userPersona: TUserPersona;
};

type TSendAudienceSelectionPutRequest = TSendRequestCommonParameter;

const hashName = 'digitalHash';

const handleResponse = (
  dispatchAction: Promise<any>,
  callbackFun: () => void,
  callbackFunFinally: () => void
) => {
  dispatchAction.then((response) => {
    if (response?.payload) {
      callbackFun();
    } else {
      callbackFunFinally();
    }
  });
};

export const sendCampaignBriefPutRequest = ({
  dispatch,
  callbackFun,
  campaignBrief,
  callbackFunFinally
}: TSendCampaignBriefPutRequest) => {
  const dispatchAction = dispatch(
    updateDigitalHashCampaignBrief({
      campaignBrief,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendTargetGroupPutRequest = ({
  dispatch,
  callbackFun,
  targetGroup,
  callbackFunFinally
}: TSendTargetGroupPutRequest) => {
  const dispatchAction = dispatch(
    updateDigitalHashTargetGroup({
      targetGroup,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendAudienceContextPutRequest = ({
  dispatch,
  callbackFun,
  audienceContext,
  callbackFunFinally
}: TSendAudienceContextPutRequest) => {
  const dispatchAction = dispatch(
    updateDigitalHashAudienceContext({
      audienceContext,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendUserPersonaPutRequest = ({
  dispatch,
  callbackFun,
  userPersona,
  callbackFunFinally
}: TSendUserPersonaPutRequest) => {
  const dispatchAction = dispatch(
    updateDigitalHashUserPersona({
      userPersona,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendAudienceSelectionPutRequest = ({
  dispatch,
  callbackFun,
  callbackFunFinally
}: TSendAudienceSelectionPutRequest) => {
  const dispatchAction = dispatch(
    digitalHashDoBudgetAllocatorCalculation({
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendUpdateBudgetAllocatorPutRequest = ({
  dispatch,
  digitalHash,
  callbackFunFinally
}: {
  dispatch: AppDispatch;
  digitalHash: IDigitalHash;
  callbackFunFinally: () => void;
}) => {
  dispatch(
    updateDigitalHashBudgetAllocator({
      digitalHash,
      hashName
    })
  ).finally(() => {
    callbackFunFinally();
  });
};
