import { lazy } from 'react';

const RadioBrief = lazy(() => import("./RadioBrief"));

const RadioBriefConfig = {
  name: 'Radio Brief',
  path: 'radio-brief',
  element: <RadioBrief />,
  icon: 'radioBrief'
};

export default RadioBriefConfig;
