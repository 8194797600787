import { IconButton, Stack, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';

interface IPaginationProps {
  onPageChange: (page: number) => void;
  params: any;
  dataSize: number;
  rowsPerPage: number;
}
const Pagination = ({
  onPageChange,
  params,
  dataSize,
  rowsPerPage
}: IPaginationProps) => {
  const currentPage = params?.skip ? params.skip / rowsPerPage + 1 : 1;
  const endItem = (currentPage - 1) * rowsPerPage + dataSize;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={1}
    >
      <Typography variant="h5">
        {dataSize > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0}-{endItem} of
        more than {endItem}
      </Typography>
      <IconButton
        onClick={() => onPageChange(0)}
        disabled={!Boolean(params?.skip)}
      >
        <ChevronLeft
          sx={{
            color: !Boolean(params?.skip) ? '#dedede' : 'black'
          }}
        />
      </IconButton>
      <IconButton
        onClick={() => onPageChange(1)}
        disabled={dataSize < rowsPerPage}
      >
        <ChevronRight
          sx={{
            color: dataSize < rowsPerPage ? '#dedede' : 'black'
          }}
        />
      </IconButton>
    </Stack>
  );
};
export default Pagination;
