import { lazy } from 'react';

const UserPersonaFile = lazy(() => import('./UserPersonaFile'));

const UserPersonaFileConfig = {
  name: 'User Persona File',
  path: 'user-persona-file',
  element: <UserPersonaFile />,
  icon: 'userPersonaFile'
};

export default UserPersonaFileConfig;
