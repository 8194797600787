import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

interface ICheckboxFilterProps {
  defaultValue: boolean;
  onChange: (value: boolean) => void;
  message: string;
}

const CheckboxFilter = ({
  defaultValue,
  onChange,
  message
}: ICheckboxFilterProps) => {
  const [checked, setChecked] = useState<boolean>(defaultValue || false);
  return (
    <Box p={2}>
      <FormControlLabel
        label={<Typography variant="h5">{message}</Typography>}
        control={
          <Checkbox
            checked={checked}
            color="primary"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setChecked(e.target.checked);
              onChange(e.target.checked);
            }}
          />
        }
      />
    </Box>
  );
};

export default CheckboxFilter;
