import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { billSubmit, fetchBillConfig } from '../redux/actionCreator';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { Close, ReceiptTextOutline } from 'mdi-material-ui';
import BillUploadForm from './BillUploadForm';
import { useSelector } from 'react-redux';
import { IBill } from '../interface';

interface IBillProps {}

const Bill = ({}: IBillProps) => {
  const dispatch = useAppDispatch();
  const { poId = '', billId = '' } = useParams();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [billUrl, setBillUrl] = useState<string>('');
  const { bill, tds_taxes } = useSelector((state: any) => state.poSlice);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const params = { url: '' };

  useEffect(() => {
    let query = searchParams.get('params');
    setBillUrl(query ? JSON.parse(query) : params);
    setIsFetching(true);
    dispatch(fetchBillConfig(poId)).then((res: any) => {
      setIsFetching(false);
    });
    // if (billId) {
    //   setBillUrl(
    //     po?.bills?.find((bill: any) => bill._id === billId)?.url || ''
    //   );
    // }
  }, [billId, poId]);

  const onBillSubmit = (bill: any) => {
    setIsSubmitting(true);
    dispatch(billSubmit({ poId, billId, bill })).then((res: any) => {
      setIsSubmitting(false);
    });
  };

  return (
    <>
      {isFetching ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={4}
          p={40}
        >
          <Typography>Loading....</Typography>
          <CircularProgress size={32} />
        </Stack>
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={4}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <ReceiptTextOutline />
              <Typography variant="h2">New Bill</Typography>
            </Stack>
            <IconButton>
              <Close />
            </IconButton>
          </Stack>
          <Divider />
          <BillUploadForm
            isSubmitting={isSubmitting}
            poId={poId}
            bill={bill}
            tds_taxes={tds_taxes || []}
            onBillSubmit={onBillSubmit}
          />
          {/* <Grid container>
            <Grid item xs={6} sx={{ borderRight: '2px solid #e4e5e6' }}>
              <BillUploadForm
                poId={poId}
                bill={bill}
                tds_taxes={tds_taxes || []}
                onBillSubmit={onBillSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              {billUrl !== '' ? (
                <iframe src={billUrl} />
              ) : (
                <Typography>Error in loading Bill Preview</Typography>
              )}
              <Typography>Error in loading Bill Preview</Typography>
            </Grid>
          </Grid> */}
        </>
      )}
    </>
  );
};

export default Bill;
