import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { InitialState } from '../../../redux/slice';
import { TTgBank, TVerticalSplit } from '../interface';
import {
  calculateDurationSummaryTotalBudget,
  calculateDurationSummaryTgReachPercentage,
  calculateDurationCampaignTgReach,
  calculateSumOfMediaTgImpression,
  calculateDurationAvgFrequency,
  calculateDurationAvgCpm,
  calculateGeoLevelTotalBudget,
  calculateGeoLevelTotalImpression,
  calculateGeoLevelTgReachPercentage,
  calculateGeoLevelCampaignTgReach,
  calculateGeoLevelAvgCpm,
  calculateGeoLevelAvgFrequency,
  calculateCampaignLevelTotalBudget,
  calculateCampaignLevelAvgCpm,
  calculateCampaignLevelTotalImpression,
  calculateCampaignLevelTgReachPercentage,
  getCampaignLevelSumOfCampaignTgReach,
  calculateCampaignLevelFrequency
} from '../calculationLogic';

const parseNumber = (number: number, toFix: number, parseInteger: boolean) => {
  if (parseInteger) {
    return toFix ? parseInt(number.toFixed(toFix)) : parseInt(number.toFixed());
  }
  return toFix
    ? parseFloat(number.toFixed(toFix))
    : parseFloat(number.toFixed());
};

const handleCalculation = (row: TVerticalSplit, tgBank: TTgBank) => {
  const maxPercentage = 80;
  let newRow = { ...row };

  if (!row.frequency || !row.cpm) {
    return {
      ...newRow,
      universeImpressions: 0,
      mediaTgImpressions: 0,
      mediaUniverseReach: 0,
      mediaTgReach: 0,
      universeTgReachPercent: 0
    };
  }

  newRow.universeImpressions = parseNumber(
    (newRow.budget / newRow.cpm) * 1000,
    0,
    true
  );

  if (newRow.toolName === 'digital') {
    newRow.mediaTgImpressions = newRow.universeImpressions;
  } else {
    newRow.mediaTgImpressions = parseNumber(
      newRow.universeImpressions * (newRow.mediaTg / newRow.mediaUniverse),
      0,
      true
    );
  }
  newRow.mediaUniverseReach = parseNumber(
    Math.min(
      newRow.universeImpressions / newRow.frequency,
      newRow.mediaUniverse
    ),
    0,
    true
  );
  newRow.mediaTgReach = parseNumber(
    Math.min(newRow.mediaTgImpressions / newRow.frequency, newRow.mediaTg),
    0,
    true
  );
  newRow.universeTgReachPercent = parseNumber(
    Math.min(
      (newRow.mediaTgReach / tgBank.ageGenderIncome) * 100,
      (newRow.mediaTg / tgBank.ageGenderIncome) * 100
    ),
    0,
    true
  );

  // if (newRow.toolName === 'digital') {
  //   newRow.mediaTgReachPercentage = parseNumber(
  //     (newRow.mediaTgReach / newRow.mediaTg) * 100,
  //     0,
  //     true
  //   );
  // } else {
  //   newRow.mediaTgReachPercentage = parseNumber(
  //     Math.min((newRow.mediaTgReach / newRow.mediaTg) * 100, maxPercentage),
  //     0,
  //     true
  //   );
  // }

  newRow.mediaTgReachPercentage = parseNumber(
    Math.min((newRow.mediaTgReach / newRow.mediaTg) * 100, maxPercentage),
    0,
    true
  );

  return newRow;
};

type TPayloadAction = {
  locationIdx: number;
  monthIdx: number;
  toolName: string;
  value: number;
};

export const budgetAllocatorReducers = {
  setVerticalSplitBudget(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, monthIdx, toolName, value } = action.payload;

    if (state.plan?.hash) {
      state.plan.hash.locations[locationIdx].duration[monthIdx].verticalSplit =
        state.plan.hash.locations[locationIdx].duration[
          monthIdx
        ].verticalSplit?.map((vertical, idx) => {
          if (vertical.toolName === toolName && state.plan?.hash) {
            return handleCalculation(
              { ...vertical, budget: value },
              state.plan.hash.locations[locationIdx].tg
            );
          }
          return vertical;
        });

      const hash = state.plan.hash;

      hash.locations[locationIdx].duration[monthIdx].sumOfImpressions =
        calculateSumOfMediaTgImpression(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].totalBudget =
        calculateDurationSummaryTotalBudget(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].tgReach =
        calculateDurationSummaryTgReachPercentage(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].campaignTgReach =
        calculateDurationCampaignTgReach(
          hash.locations[locationIdx].tg,
          hash.locations[locationIdx].duration[monthIdx].tgReach
        );
      hash.locations[locationIdx].duration[monthIdx].avgFrequency =
        calculateDurationAvgFrequency(
          hash.locations[locationIdx].duration[monthIdx].sumOfImpressions,
          hash.locations[locationIdx].duration[monthIdx].campaignTgReach
        );
      hash.locations[locationIdx].duration[monthIdx].avgCpm =
        calculateDurationAvgCpm(
          hash.locations[locationIdx].duration[monthIdx].totalBudget,
          hash.locations[locationIdx].duration[monthIdx].sumOfImpressions
        );

      hash.locations[locationIdx].totalBudget = calculateGeoLevelTotalBudget(
        hash.locations[locationIdx]
      );
      hash.locations[locationIdx].sumOfImpressions =
        calculateGeoLevelTotalImpression(hash.locations[locationIdx]);
      hash.locations[locationIdx].tgReach = calculateGeoLevelTgReachPercentage(
        hash.locations[locationIdx]
      );
      hash.locations[locationIdx].campaignTgReach =
        calculateGeoLevelCampaignTgReach(hash.locations[locationIdx]);
      hash.locations[locationIdx].avgCpm = calculateGeoLevelAvgCpm(
        hash.locations[locationIdx].totalBudget,
        hash.locations[locationIdx].sumOfImpressions
      );
      hash.locations[locationIdx].avgFrequency = calculateGeoLevelAvgFrequency(
        hash.locations[locationIdx].sumOfImpressions,
        hash.locations[locationIdx].campaignTgReach
      );

      hash.totalBudget = calculateCampaignLevelTotalBudget(hash.locations);
      hash.sumOfImpressions = calculateCampaignLevelTotalImpression(
        hash.locations
      );
      hash.campaignTgReach = getCampaignLevelSumOfCampaignTgReach(
        hash.locations
      );
      hash.tgReach = calculateCampaignLevelTgReachPercentage(
        hash.locations,
        hash.campaignTgReach
      );
      hash.avgFrequency = calculateCampaignLevelFrequency(
        hash.sumOfImpressions,
        hash.campaignTgReach
      );
      hash.avgCpm = calculateCampaignLevelAvgCpm(
        hash.totalBudget,
        hash.sumOfImpressions
      );

      state.plan.hash = hash;
    }
  },
  setVerticalSplitFrequency(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, monthIdx, toolName, value } = action.payload;

    if (state.plan.hash) {
      state.plan.hash.locations[locationIdx].duration[monthIdx].verticalSplit =
        state.plan.hash.locations[locationIdx].duration[
          monthIdx
        ].verticalSplit?.map((vertical, idx) => {
          if (vertical.toolName === toolName && state.plan?.hash) {
            return handleCalculation(
              { ...vertical, frequency: value },
              state.plan.hash.locations[locationIdx].tg
            );
          }
          return vertical;
        });

      const hash = state.plan.hash;

      hash.locations[locationIdx].duration[monthIdx].sumOfImpressions =
        calculateSumOfMediaTgImpression(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].totalBudget =
        calculateDurationSummaryTotalBudget(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].tgReach =
        calculateDurationSummaryTgReachPercentage(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].campaignTgReach =
        calculateDurationCampaignTgReach(
          hash.locations[locationIdx].tg,
          hash.locations[locationIdx].duration[monthIdx].tgReach
        );
      hash.locations[locationIdx].duration[monthIdx].avgFrequency =
        calculateDurationAvgFrequency(
          hash.locations[locationIdx].duration[monthIdx].sumOfImpressions,
          hash.locations[locationIdx].duration[monthIdx].campaignTgReach
        );
      hash.locations[locationIdx].duration[monthIdx].avgCpm =
        calculateDurationAvgCpm(
          hash.locations[locationIdx].duration[monthIdx].totalBudget,
          hash.locations[locationIdx].duration[monthIdx].sumOfImpressions
        );

      hash.locations[locationIdx].totalBudget = calculateGeoLevelTotalBudget(
        hash.locations[locationIdx]
      );
      hash.locations[locationIdx].sumOfImpressions =
        calculateGeoLevelTotalImpression(hash.locations[locationIdx]);
      hash.locations[locationIdx].tgReach = calculateGeoLevelTgReachPercentage(
        hash.locations[locationIdx]
      );
      hash.locations[locationIdx].campaignTgReach =
        calculateGeoLevelCampaignTgReach(hash.locations[locationIdx]);
      hash.locations[locationIdx].avgCpm = calculateGeoLevelAvgCpm(
        hash.locations[locationIdx].totalBudget,
        hash.locations[locationIdx].sumOfImpressions
      );
      hash.locations[locationIdx].avgFrequency = calculateGeoLevelAvgFrequency(
        hash.locations[locationIdx].sumOfImpressions,
        hash.locations[locationIdx].campaignTgReach
      );

      hash.totalBudget = calculateCampaignLevelTotalBudget(hash.locations);
      hash.sumOfImpressions = calculateCampaignLevelTotalImpression(
        hash.locations
      );
      hash.campaignTgReach = getCampaignLevelSumOfCampaignTgReach(
        hash.locations
      );
      hash.tgReach = calculateCampaignLevelTgReachPercentage(
        hash.locations,
        hash.campaignTgReach
      );
      hash.avgFrequency = calculateCampaignLevelFrequency(
        hash.sumOfImpressions,
        hash.campaignTgReach
      );
      hash.avgCpm = calculateCampaignLevelAvgCpm(
        hash.totalBudget,
        hash.sumOfImpressions
      );

      state.plan.hash = hash;
    }
  },
  setVerticalSplitMediaTg(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, monthIdx, toolName, value } = action.payload;

    if (state.plan?.hash) {
      state.plan.hash.locations[locationIdx].duration[monthIdx].verticalSplit =
        state.plan.hash.locations[locationIdx].duration[
          monthIdx
        ].verticalSplit?.map((vertical, idx) => {
          if (vertical.toolName === toolName && state.plan?.hash) {
            return handleCalculation(
              { ...vertical, mediaTg: value },
              state.plan.hash.locations[locationIdx].tg
            );
          }
          return vertical;
        });

      const hash = state.plan.hash;

      hash.locations[locationIdx].duration[monthIdx].sumOfImpressions =
        calculateSumOfMediaTgImpression(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].totalBudget =
        calculateDurationSummaryTotalBudget(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].tgReach =
        calculateDurationSummaryTgReachPercentage(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].campaignTgReach =
        calculateDurationCampaignTgReach(
          hash.locations[locationIdx].tg,
          hash.locations[locationIdx].duration[monthIdx].tgReach
        );
      hash.locations[locationIdx].duration[monthIdx].avgFrequency =
        calculateDurationAvgFrequency(
          hash.locations[locationIdx].duration[monthIdx].sumOfImpressions,
          hash.locations[locationIdx].duration[monthIdx].campaignTgReach
        );
      hash.locations[locationIdx].duration[monthIdx].avgCpm =
        calculateDurationAvgCpm(
          hash.locations[locationIdx].duration[monthIdx].totalBudget,
          hash.locations[locationIdx].duration[monthIdx].sumOfImpressions
        );

      hash.locations[locationIdx].totalBudget = calculateGeoLevelTotalBudget(
        hash.locations[locationIdx]
      );
      hash.locations[locationIdx].sumOfImpressions =
        calculateGeoLevelTotalImpression(hash.locations[locationIdx]);
      hash.locations[locationIdx].tgReach = calculateGeoLevelTgReachPercentage(
        hash.locations[locationIdx]
      );
      hash.locations[locationIdx].campaignTgReach =
        calculateGeoLevelCampaignTgReach(hash.locations[locationIdx]);
      hash.locations[locationIdx].avgCpm = calculateGeoLevelAvgCpm(
        hash.locations[locationIdx].totalBudget,
        hash.locations[locationIdx].sumOfImpressions
      );
      hash.locations[locationIdx].avgFrequency = calculateGeoLevelAvgFrequency(
        hash.locations[locationIdx].sumOfImpressions,
        hash.locations[locationIdx].campaignTgReach
      );

      hash.totalBudget = calculateCampaignLevelTotalBudget(hash.locations);
      hash.sumOfImpressions = calculateCampaignLevelTotalImpression(
        hash.locations
      );
      hash.campaignTgReach = getCampaignLevelSumOfCampaignTgReach(
        hash.locations
      );
      hash.tgReach = calculateCampaignLevelTgReachPercentage(
        hash.locations,
        hash.campaignTgReach
      );
      hash.avgFrequency = calculateCampaignLevelFrequency(
        hash.sumOfImpressions,
        hash.campaignTgReach
      );
      hash.avgCpm = calculateCampaignLevelAvgCpm(
        hash.totalBudget,
        hash.sumOfImpressions
      );

      state.plan.hash = hash;
    }
  },
  setVerticalSplitCpm(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, monthIdx, toolName, value } = action.payload;

    if (state.plan?.hash) {
      state.plan.hash.locations[locationIdx].duration[monthIdx].verticalSplit =
        state.plan.hash.locations[locationIdx].duration[
          monthIdx
        ].verticalSplit?.map((vertical, idx) => {
          if (vertical.toolName === toolName && state.plan?.hash) {
            return handleCalculation(
              { ...vertical, cpm: value },
              state.plan.hash.locations[locationIdx].tg
            );
          }
          return vertical;
        });

      const hash = state.plan.hash;

      hash.locations[locationIdx].duration[monthIdx].sumOfImpressions =
        calculateSumOfMediaTgImpression(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].totalBudget =
        calculateDurationSummaryTotalBudget(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].tgReach =
        calculateDurationSummaryTgReachPercentage(
          hash.locations[locationIdx].duration[monthIdx]
        );
      hash.locations[locationIdx].duration[monthIdx].campaignTgReach =
        calculateDurationCampaignTgReach(
          hash.locations[locationIdx].tg,
          hash.locations[locationIdx].duration[monthIdx].tgReach
        );
      hash.locations[locationIdx].duration[monthIdx].avgFrequency =
        calculateDurationAvgFrequency(
          hash.locations[locationIdx].duration[monthIdx].sumOfImpressions,
          hash.locations[locationIdx].duration[monthIdx].campaignTgReach
        );
      hash.locations[locationIdx].duration[monthIdx].avgCpm =
        calculateDurationAvgCpm(
          hash.locations[locationIdx].duration[monthIdx].totalBudget,
          hash.locations[locationIdx].duration[monthIdx].sumOfImpressions
        );

      hash.locations[locationIdx].totalBudget = calculateGeoLevelTotalBudget(
        hash.locations[locationIdx]
      );
      hash.locations[locationIdx].sumOfImpressions =
        calculateGeoLevelTotalImpression(hash.locations[locationIdx]);
      hash.locations[locationIdx].tgReach = calculateGeoLevelTgReachPercentage(
        hash.locations[locationIdx]
      );
      hash.locations[locationIdx].campaignTgReach =
        calculateGeoLevelCampaignTgReach(hash.locations[locationIdx]);
      hash.locations[locationIdx].avgCpm = calculateGeoLevelAvgCpm(
        hash.locations[locationIdx].totalBudget,
        hash.locations[locationIdx].sumOfImpressions
      );
      hash.locations[locationIdx].avgFrequency = calculateGeoLevelAvgFrequency(
        hash.locations[locationIdx].sumOfImpressions,
        hash.locations[locationIdx].campaignTgReach
      );

      hash.totalBudget = calculateCampaignLevelTotalBudget(hash.locations);
      hash.sumOfImpressions = calculateCampaignLevelTotalImpression(
        hash.locations
      );
      hash.campaignTgReach = getCampaignLevelSumOfCampaignTgReach(
        hash.locations
      );
      hash.tgReach = calculateCampaignLevelTgReachPercentage(
        hash.locations,
        hash.campaignTgReach
      );
      hash.avgFrequency = calculateCampaignLevelFrequency(
        hash.sumOfImpressions,
        hash.campaignTgReach
      );
      hash.avgCpm = calculateCampaignLevelAvgCpm(
        hash.totalBudget,
        hash.sumOfImpressions
      );

      state.plan.hash = hash;
    }
  }
};
