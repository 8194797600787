import { lazy } from 'react';

const CampaignChat = lazy(() => import('./CampaignChat'));

const CampaignBriefConfig = {
	name: 'Campaign Chat',
	path: 'chat',
	element: <CampaignChat />,
	icon: 'campaignChat',
	display: false
};

export default CampaignBriefConfig;
