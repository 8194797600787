import { lazy } from 'react';

const CampaignBrief = lazy(() => import("./CampaignBrief"));

const CampaignBriefConfig = {
  name: 'Campaign Brief',
  path: '',
  element: <CampaignBrief />,
  icon: 'campaignBrief'
};

export default CampaignBriefConfig;
