import { lazy } from 'react';

const TgBank = lazy(() => import('./TgBank'));

const TgBankConfig = {
  name: 'TG Bank',
  path: 'tg-bank',
  element: <TgBank />,
  icon: 'tgBank',
};

export default TgBankConfig;
