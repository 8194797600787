import {
  TDuration,
  TLocation,
  TTgBank,
  TVerticalSplit
} from '../BudgetAllocator/interface';

// ---------------------- app ---------------------------

export const calculateUniverse = (locations: TLocation[], keyName: string) => {
  const result = locations.reduce(
    (acc, location) => (location.tg[keyName] as number) + acc,
    0
  );
  return result;
};

// --------------------- this is month level -----------------------

export const calculateDurationSummaryTotalBudget = (duration: TDuration) => {
  const totalBudget = duration?.verticalSplit.reduce(
    (acc: number, vertical: TVerticalSplit) => vertical.budget + acc,
    0
  );
  return totalBudget;
};

export const calculateSumOfMediaTgImpression = (duration: TDuration) =>
  duration.verticalSplit.reduce(
    (acc, vertical) => acc + vertical.mediaTgImpressions,
    0
  );

export const calculateDurationSummaryTgReachPercentage = (
  duration: TDuration
) => {
  const tgReachPercentage = duration.verticalSplit.reduce(
    (acc: number, vertical: TVerticalSplit) =>
      (1 - vertical.universeTgReachPercent / 100) * acc,
    1
  );
  return Math.min(parseFloat(((1 - tgReachPercentage) * 100).toFixed(2)), 80);
};

export const calculateDurationCampaignTgReach = (
  tgBank: TTgBank,
  tgReachPercentage: number
) => {
  const universeTgAgeIncomeGender = tgBank.ageGenderIncome;
  return parseInt(
    ((tgReachPercentage / 100) * universeTgAgeIncomeGender).toFixed(2)
  );
};

export const calculateDurationAvgFrequency = (
  sumOfMediaTgImpression: number,
  campaignTgReach: number
) => {
  if (!sumOfMediaTgImpression || !campaignTgReach) return 0;
  return parseFloat((sumOfMediaTgImpression / campaignTgReach).toFixed(2));
};

export const calculateDurationAvgCpm = (
  totalBudget: number,
  sumOfMediaTgImpression: number
) => {
  if (sumOfMediaTgImpression && totalBudget) {
    return (totalBudget / sumOfMediaTgImpression) * 1000;
  }
  return 0;
};

//----------------this is geo level--------------------

export const allGeoLevelVerticals = (location: TLocation) => {
  const allVerticals: TVerticalSplit[] = [];
  location.duration.forEach((dur) => {
    allVerticals.push(...dur.verticalSplit);
  });
  return allVerticals;
};

export const calculateGeoLevelTotalBudget = (location: TLocation) => {
  const geoLevelTotalBudget = location?.duration.reduce(
    (acc, dur) => acc + (dur?.totalBudget ? dur.totalBudget : 0),
    0
  );
  return geoLevelTotalBudget;
};

export const calculateGeoLevelTotalImpression = (location: TLocation) => {
  const geoLevelTotalImpression = location?.duration.reduce(
    (acc, dur) => acc + (dur?.sumOfImpressions ? dur.sumOfImpressions : 0),
    0
  );
  return geoLevelTotalImpression;
};

export const calculateGeoLevelTgReachPercentage = (location: TLocation) => {
  const tgReachPercentage = location?.duration.reduce(
    (acc, dur) => (1 - (dur?.tgReach ? dur.tgReach : 0) / 100) * acc,
    1
  );
  return Math.min(parseFloat(((1 - tgReachPercentage) * 100).toFixed(2)), 80);
};

export const calculateGeoLevelCampaignTgReach = (location: TLocation) => {
  const tgBank = location.tg.ageGenderIncome;
  const tgReachPercentage = location.tgReach;
  return parseFloat((tgBank * (tgReachPercentage / 100)).toFixed(2));
};

export const calculateGeoLevelAvgCpm = (
  TotalBudget: number,
  TotalImpression: number
) => {
  if (!TotalImpression || !TotalBudget) return 0;
  return parseFloat(((TotalBudget / TotalImpression) * 1000).toFixed(2));
};

export const calculateGeoLevelAvgFrequency = (
  geoLevelTotalImpression: number,
  geoLevelCampaignTgReach: number
) => {
  if (geoLevelCampaignTgReach && geoLevelTotalImpression) {
    return parseFloat(
      (geoLevelTotalImpression / geoLevelCampaignTgReach).toFixed(2)
    );
  }
  return 0;
};

// ---------------------------this is campaign level calculation --------------------------

export const calculateCampaignLevelTotalBudget = (locations: TLocation[]) => {
  const campaignLevelTotalBudget = locations.reduce(
    (acc, location) => acc + (location?.totalBudget ? location.totalBudget : 0),
    0
  );
  return campaignLevelTotalBudget;
};

export const calculateCampaignLevelTotalImpression = (
  locations: TLocation[]
) => {
  const campaignLevelTotalImpression = locations.reduce(
    (acc, location) =>
      acc + (location?.sumOfImpressions ? location.sumOfImpressions : 0),
    0
  );
  return campaignLevelTotalImpression;
};

export const calculateCampaignLevelAvgCpm = (
  campaignLevelTotalBudget: number,
  campaignLevelTotalImpression: number
) => {
  if (campaignLevelTotalBudget && campaignLevelTotalImpression) {
    return parseFloat(
      (
        (campaignLevelTotalBudget / campaignLevelTotalImpression) *
        1000
      ).toFixed()
    );
  }
  return 0;
};

export const getCampaignLevelSumOfCampaignTgReach = (
  locations: TLocation[]
) => {
  const sumOfCampaignTgReach = locations.reduce(
    (acc, location) =>
      (location?.campaignTgReach ? location.campaignTgReach : 0) + acc,
    0
  );
  return sumOfCampaignTgReach;
};

export const calculateCampaignLevelTgReachPercentage = (
  locations: TLocation[],
  campaignTgReach: number
) => {
  const sumOfUniverseTg = locations.reduce(
    (acc, locations) => locations.tg.ageGenderIncome + acc,
    0
  );
  if (campaignTgReach && sumOfUniverseTg) {
    return Math.min(parseFloat(((campaignTgReach / sumOfUniverseTg) * 100).toFixed(2)), 80);
  }
  return 0;
};

export const calculateCampaignLevelFrequency = (
  sumOfImpressions: number,
  combinedReach: number
) => {
  if (combinedReach && sumOfImpressions) {
    return parseFloat((sumOfImpressions / combinedReach).toFixed(2));
  }
  return 0;
};
