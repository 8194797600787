import CampaignBrief from './CampaignBrief/index';
import TargetGroup from './TargetGroup/index';
import AudienceContextFile from './AudienceContextFile/index';
import UserPersonaFile from './UserPersonaFile/index';
import MediaSpace from './MediaSpace/index';
import AudienceSelection from './AudienceSelection/index';
import BudgetAllocator from './BudgetAllocator/index';

export const digitalHashChildRoutes = [
  {
    ...CampaignBrief
  },
  {
    ...TargetGroup
  },
  {
    ...AudienceContextFile
  },
  {
    ...UserPersonaFile
  },
  {
    ...MediaSpace
  },
  {
    ...AudienceSelection
  },
  {
    ...BudgetAllocator
  }
];

export const newStage: { [key: string]: string } = {
  '0': '',
  '10': 'target-group',
  '20': 'audience-context-file',
  '30': 'user-persona-file',
  '40': 'media-space',
  '50': 'audience-selection',
  '60': 'budget-allocator'
};
