import { useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Popper,
  Stack,
  Typography
} from '@mui/material';
import { TrayArrowDown } from 'mdi-material-ui';
import { useAppDispatch } from '../../app/hooks';
import {
  addToMediaSpaceHash,
  competitorFileUpload
} from '../../app/PlanRequests/redux/slice';
import WarninigPopUp from './Shared/WarninigPopUp';
import { SingleFileUploadDragDrop } from '@media-ant/ui';

type THashHeaders = {
  title: string;
  pptDownloading?: boolean;
  excelDownloading?: boolean;
  handlePptDownload?: (anchorElHandler: (value: null) => void) => void;
  handleExcelDownload?: (anchorElHandler: (value: null) => void) => void;
  renderDownloadButton?: boolean;
  disablePpt?: boolean;
  disableExcel?: boolean;
  disableAddToMediaSpace?: boolean;
  addToMediaSpace?: boolean;
  mediaSpaceAddCount?: number | undefined;
  renderCompetitorFileUploadButton?: boolean;
};

const acceptedFileFormat =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

const HashHeaders = ({
  title = '',
  pptDownloading,
  excelDownloading,
  handlePptDownload,
  handleExcelDownload,
  renderDownloadButton,
  disablePpt = false,
  disableExcel = false,
  disableAddToMediaSpace = false,
  addToMediaSpace = false,
  mediaSpaceAddCount = undefined,
  renderCompetitorFileUploadButton = false
}: THashHeaders) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [anchorElCompetitorFileUpload, setAnchorElCompetitorFileUpload] =
    useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openWarningPopUp, setOpenWarningPopUp] = useState(false);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const openCompetitorFileUpload = Boolean(anchorElCompetitorFileUpload);

  const sendAddToMediaSpaceRequest = () => {
    setIsLoading(true);
    dispatch(addToMediaSpaceHash()).finally(() => {
      setIsLoading(false);
      handleWarningPopUpClose();
    });
  };

  const handleAddToMediaSpace = () => {
    if (mediaSpaceAddCount === 0 || mediaSpaceAddCount === undefined) {
      sendAddToMediaSpaceRequest();
    } else {
      setOpenWarningPopUp(true);
    }
  };

  const handleWarningPopUpClose = () => {
    setOpenWarningPopUp(false);
  };

  const handleFileUpload = (file: File | null) => {
    if (file !== null) {
      setIsFileUploading(true);
      dispatch(competitorFileUpload(file))
        .then(() => {})
        .finally(() => {
          setIsFileUploading(false);
          setAnchorElCompetitorFileUpload(null);
        });
    }
  };

  const handleOpenCompetitorFileUploadBtnClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElCompetitorFileUpload(
      anchorElCompetitorFileUpload ? null : event.currentTarget
    );
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        boxShadow:
          '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25)',
        padding: '24px',
        gap: '8px',
        position: 'sticky',
        top: 0,
        zIndex: 1300,
        backgroundColor: '#FAFBFC'
      }}
    >
      <Typography variant="h3" sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
      {renderCompetitorFileUploadButton ? (
        <Button
          size="small"
          disabled={isFileUploading}
          startIcon={
            isFileUploading ? (
              <CircularProgress size={24} sx={{ color: '#4E3BC9' }} />
            ) : null
          }
          variant="outlined"
          sx={{ marginLeft: 'auto', fontWeight: 500 }}
          onClick={handleOpenCompetitorFileUploadBtnClick}
        >
          Upload Competitor Excel File
        </Button>
      ) : null}
      {addToMediaSpace ? (
        <Button
          size="small"
          disabled={isLoading || disableAddToMediaSpace}
          startIcon={
            isLoading ? (
              <CircularProgress size={24} sx={{ color: '#4E3BC9' }} />
            ) : null
          }
          variant="contained"
          sx={
            renderCompetitorFileUploadButton
              ? { fontWeight: 500 }
              : { marginLeft: 'auto', fontWeight: 500 }
          }
          onClick={handleAddToMediaSpace}
        >
          Move to Media Space
        </Button>
      ) : null}
      {renderDownloadButton ? (
        <IconButton
          disabled={pptDownloading || excelDownloading}
          onClick={handleClick}
          sx={{ display: 'grid', placeContent: 'center' }}
        >
          <TrayArrowDown />
        </IconButton>
      ) : null}
      {openCompetitorFileUpload ? (
        <Popper
          open={openCompetitorFileUpload}
          anchorEl={anchorElCompetitorFileUpload}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10]
              }
            }
          ]}
          sx={{
            zIndex: 1301,
            // boxShadow: '0px 2px 12px #00000010',
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            padding: '16px'
          }}
        >
          <div data-popper-arrow></div>
          <SingleFileUploadDragDrop
            supportedFormats={['xlsx']}
            maxFileSize="1.5 GB"
            accept={acceptedFileFormat}
            onChange={handleFileUpload}
          />
        </Popper>
      ) : null}
      {open ? (
        <Popper
          open={open}
          anchorEl={anchorEl}
          sx={{
            zIndex: 1301,
            boxShadow: '0px 2px 12px #00000010',
            backgroundColor: '#fff',
            borderRadius: '8px'
          }}
        >
          <Stack>
            <Button
              variant="text"
              onClick={() => {
                if (handlePptDownload) {
                  handlePptDownload(setAnchorEl);
                }
              }}
              disabled={disablePpt || pptDownloading}
              sx={{ justifyContent: 'flex-start', minWidth: '80px' }}
              endIcon={pptDownloading ? <CircularProgress size={16} /> : null}
            >
              PPT
            </Button>
            <Divider />
            <Button
              variant="text"
              onClick={() => {
                if (handleExcelDownload) {
                  handleExcelDownload(setAnchorEl);
                }
              }}
              sx={{ justifyContent: 'flex-start', minWidth: '80px' }}
              disabled={disableExcel || excelDownloading}
              endIcon={excelDownloading ? <CircularProgress size={16} /> : null}
            >
              Excel
            </Button>
          </Stack>
        </Popper>
      ) : null}
      <WarninigPopUp
        handleClose={handleWarningPopUpClose}
        handleContinue={sendAddToMediaSpaceRequest}
        isLoading={isLoading}
        open={openWarningPopUp}
        renderDescription="media_space_waringing"
      />
    </Stack>
  );
};

export default HashHeaders;