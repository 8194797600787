import React, { useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { PlusCircleOutline, TrashCan } from 'mdi-material-ui';
import { useFormContext, useWatch } from 'react-hook-form';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import CustomTextFieldForNumberFormat from '../../../../../components/SharedHashes/Shared/CustomTextFieldForNumberFormat';

import { TFilters } from '../TargetGroup';

const MIN_LIMIT = 1,
  MAX_LIMIT = 99;

interface IAgeGroup {
  targetKey: string;
  calculateAudienceSize?: (updatedFilters: Partial<TFilters>) => void;
}

interface IMinMax {
  min: string;
  max: string;
}

interface IAge {
  renderDeleteBtn: boolean;
  index: number;
  item: IMinMax;
  handleAddClick: ({ index, value }: { index: number; value: IMinMax }) => void;
  handleDeleteClick: (index: number) => void;
  onMinMaxValueChange: ({
    value,
    index,
    key
  }: {
    value: string;
    index: number;
    key: keyof IMinMax;
  }) => void;
}

const Age = ({
  item,
  renderDeleteBtn,
  handleAddClick,
  index,
  onMinMaxValueChange,
  handleDeleteClick
}: IAge) => {
  const [isHovered, setIsHovered] = useState(false),
    { min = '', max = '' } = item;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        flexWrap: 'wrap',
        gap: '0.75rem',
        padding: '0.75rem',
        backgroundColor: isHovered ? '#F5F4FC' : 'transparent',
        borderRadius: '8px'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <NumericFormat
        customInput={CustomTextFieldForNumberFormat}
        value={min}
        thousandSeparator=","
        decimalScale={0}
        size="small"
        error={!Boolean(min)}
        allowNegative={false}
        inputMode="numeric"
        style={{ maxWidth: '100px' }}
        placeholder="Min Age"
        isAllowed={(values) => {
          const { floatValue, value } = values;
          if (!value) return true;
          if (floatValue === undefined) {
            return false;
          }

          return floatValue >= MIN_LIMIT && floatValue <= MAX_LIMIT;
        }}
        onValueChange={(e: NumberFormatValues) => {
          onMinMaxValueChange({ value: e.value, index, key: 'min' });
        }}
      />
      <Typography variant="body1">to</Typography>
      <NumericFormat
        customInput={CustomTextFieldForNumberFormat}
        value={max}
        thousandSeparator=","
        decimalScale={0}
        size="small"
        error={!Boolean(max)}
        allowNegative={false}
        inputMode="numeric"
        style={{ maxWidth: '100px' }}
        placeholder="Max Age"
        isAllowed={(values) => {
          const { floatValue, value } = values;
          if (!value) return true;
          if (floatValue === undefined) {
            return false;
          }

          return floatValue >= MIN_LIMIT && floatValue <= MAX_LIMIT;
        }}
        onValueChange={(e: NumberFormatValues) => {
          onMinMaxValueChange({ value: e.value, index, key: 'max' });
        }}
      />
      {isHovered ? (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ gap: '0.25rem', marginLeft: 'auto' }}
        >
          {renderDeleteBtn ? (
            <IconButton onClick={() => handleDeleteClick(index)}>
              <TrashCan sx={{ color: 'red', fontSize: '1rem' }} />
            </IconButton>
          ) : null}
          <IconButton
            onClick={() =>
              handleAddClick({ index, value: { min: '', max: '' } })
            }
          >
            <PlusCircleOutline sx={{ color: '#4e3bc9', fontSize: '1rem' }} />
          </IconButton>
        </Stack>
      ) : null}
    </Stack>
  );
};

const getDefaultFormattedAges = (ages: string[]) =>
  ages?.map((age) => {
    const [min = '', max = ''] = age.split(' - ');
    return { min, max };
  });

const AgeGroup = ({
  targetKey = 'digitalHash.targetGroup.age',
  calculateAudienceSize
}: IAgeGroup) => {
  const { setValue, control } = useFormContext(),
    age = useWatch({ name: targetKey, control }),
    formattedAges = getDefaultFormattedAges(age);

  const handleAddClick = ({
    index,
    value
  }: {
    index: number;
    value: { min: string; max: string };
  }) => {
    age.splice(index + 1, 0, `${value.min} - ${value.max}`);
    setValue(targetKey, age);
  };

  const onMinMaxValueChange = ({
    value,
    index,
    key
  }: {
    value: string;
    index: number;
    key: keyof IMinMax;
  }) => {
    let newAges = [...age];
    if (key === 'min')
      newAges[index] = `${value} - ${newAges[index].split(' - ')[1]}`;
    else newAges[index] = `${newAges[index].split(' - ')[0]} - ${value}`;
    setValue(targetKey, newAges);

    if (calculateAudienceSize) calculateAudienceSize({ age: newAges });
  };

  const handleDeleteClick = (index: number) => {
    let newAges = [...age];
    newAges.splice(index, 1);
    setValue(targetKey, newAges);

    if (calculateAudienceSize) calculateAudienceSize({ age: newAges });
  };

  const error = !formattedAges.every(
    (age) => age.min.length > 0 && age.max.length > 0
  );

  return (
    <Stack className="age-group-container" sx={{ gap: '0.75rem' }}>
      <Typography
        variant="h4"
        className="age-group-heading"
        sx={{
          fontWeight: 600,
          color: error ? 'red' : '#172B4D',
          textDecoration: error ? 'underline' : 'none'
        }}
      >
        Age Group *
      </Typography>
      {formattedAges.map((item, index) => (
        <Age
          key={index}
          index={index}
          item={item}
          renderDeleteBtn={formattedAges.length > 1}
          handleAddClick={handleAddClick}
          onMinMaxValueChange={onMinMaxValueChange}
          handleDeleteClick={handleDeleteClick}
        />
      ))}
    </Stack>
  );
};

export default AgeGroup;
