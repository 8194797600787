import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import {
  handleAudienceContextAndUserPersonaError,
  handleAudienceSelectionError,
  handleCampaignBriefError,
  handleTargetGroupError
} from './validations';
import { helperSteps, helperStepsNumKey } from './index';

import {
  sendAudienceContextPutRequest,
  sendAudienceSelectionPutRequest,
  sendCampaignBriefPutRequest,
  sendTargetGroupPutRequest,
  sendUpdateBudgetAllocatorPutRequest,
  sendUserPersonaPutRequest
} from './apiRequest';
import { updateState } from '../../redux/slice';
import { useAppDispatch } from '../../../hooks';
import { AppDispatch } from '../../../store';
import { SnackBarActions } from '../../../Shared/SnackBar/slice';

import { ITargetGroup } from '../TargetGroup/interface';
import { TAudienceContext } from '../AudienceContextFile/interface';
import { TUserPersona } from '../UserPersonaFile/interface';
import { IDigitalHash } from '../BudgetAllocator/interface';
import { ICampaignBrief } from '../CampaignBrief/interface';

import classes from './Stepper.module.css';

type TStepperAction = {
  steps: string[];
  activeStep: number;
  completed: { [k: number]: boolean };
  handleBack: () => void;
  handleComplete: () => void;
  isLoading: boolean;
  handleLoading: (isLoading: boolean) => void;
};

type TDigitalHash = {
  campaignBrief: ICampaignBrief;
  targetGroup: ITargetGroup;
  audienceContext: TAudienceContext;
  userPersona: TUserPersona;
} & IDigitalHash;

const helper: { [k: number]: string } = {
  0: 'digitalHash.campaignBrief',
  1: 'digitalHash.targetGroup',
  2: 'digitalHash.audienceContext',
  3: 'digitalHash.userPersona',
  4: 'digitalHash.audienceSelection',
  5: 'digitalHash.audienceSelection',
  6: 'digitalHash'
};

const tabHelper: { [k: number]: string } = {
  0: 'campaign-brief',
  1: 'target-group',
  2: 'audience-context-file',
  3: 'user-persona-file',
  4: 'digital-media-space',
  5: 'audience-selection',
  6: 'budget-allocator'
};

const triggerError = (error: string, dispatch: AppDispatch) => {
  const err = {
    message: error,
    open: true,
    type: 'error'
  };
  dispatch(SnackBarActions.openSnack({ ...err }));
};

const handleNextStep = (
  activeStep: number,
  dispatch: AppDispatch,
  digitalHash: TDigitalHash,
  callbackFun: () => void,
  callbackFunFinally: () => void
) => {
  switch (tabHelper[activeStep]) {
    case tabHelper[0]:
      return sendCampaignBriefPutRequest({
        dispatch,
        callbackFun,
        campaignBrief: digitalHash.campaignBrief,
        callbackFunFinally
      });
    case tabHelper[1]:
      return sendTargetGroupPutRequest({
        dispatch,
        callbackFun,
        targetGroup: digitalHash.targetGroup,
        callbackFunFinally
      });
    case tabHelper[2]:
      return sendAudienceContextPutRequest({
        dispatch,
        callbackFun,
        audienceContext: digitalHash.audienceContext,
        callbackFunFinally
      });
    case tabHelper[3]:
      return sendUserPersonaPutRequest({
        dispatch,
        callbackFun,
        userPersona: digitalHash.userPersona,
        callbackFunFinally
      });
    case tabHelper[4]:
      return;
    case tabHelper[5]:
      return sendAudienceSelectionPutRequest({
        dispatch,
        callbackFun,
        callbackFunFinally
      });
    case tabHelper[6]:
      return sendUpdateBudgetAllocatorPutRequest({
        dispatch,
        digitalHash: digitalHash,
        callbackFunFinally
      });
    default:
      return;
  }
};

const StepperAction = ({
  steps,
  activeStep,
  handleBack,
  completed,
  isLoading,
  handleLoading
}: TStepperAction) => {
  const { getValues, setValue } = useFormContext(),
    dispatch = useAppDispatch(),
    navigate = useNavigate(),
    { planId } = useParams(),
    location = useLocation(),
    path = location.pathname,
    segments = path.split('/'),
    stage = segments[segments.length - 1];

  const callbackFunFinally = () => {
    handleLoading(false);
  };

  const step = helperSteps[stage as keyof typeof helperSteps] ?? 0;

  const handleNextStepGeneric = (
    error: string,
    step: number,
    callbackFun: () => void,
    callbackFunFinally: () => void,
    key: string = 'digitalHash'
  ) => {
    if (error) triggerError(error, dispatch);
    else {
      handleLoading(true);
      handleNextStep(
        step,
        dispatch,
        getValues(key),
        callbackFun,
        callbackFunFinally
      );
    }
  };

  const handleNext = () => {
    const key = 'digitalHash';
    if (step === 0) {
      const value = getValues(`${helper[step]}`),
        error = handleCampaignBriefError(value);
      handleNextStepGeneric(error, step, callbackFun, callbackFunFinally, key);
    } else if (step === 1) {
      const value = getValues(`${helper[step]}`),
        error = handleTargetGroupError(value);
      handleNextStepGeneric(error, step, callbackFun, callbackFunFinally, key);
    } else if (step === 2) {
      const value = getValues(`${helper[step]}`),
        error = handleAudienceContextAndUserPersonaError(
          value,
          'audienceContext'
        );
      handleNextStepGeneric(error, step, callbackFun, callbackFunFinally, key);
    } else if (step === 3) {
      const userPersona = getValues(`${helper[step]}`),
        audienceContext = getValues(`${helper[step - 1]}`),
        value = { ...audienceContext, ...userPersona },
        error = handleAudienceContextAndUserPersonaError(value, 'userPersona');
      handleNextStepGeneric(error, step, callbackFun, callbackFunFinally, key);
    } else if (step === 4) {
      // there is no error validation here
      handleLoading(true);
      callbackFun();
    } else if (step === 5) {
      const value = getValues(`${helper[step]}`),
        error = handleAudienceSelectionError(value);
      handleNextStepGeneric(error, step, callbackFun, callbackFunFinally, key);
    } else if (step === 6) {
      handleLoading(true);
      handleNextStep(
        step,
        dispatch,
        getValues(key),
        callbackFun,
        callbackFunFinally
      );
    }
  };

  const stateCalc =
    ((helperSteps[stage as keyof typeof helperSteps] ?? 0) + 1) * 10;

  const nextStep =
    helperStepsNumKey[
      (helperSteps[stage as keyof typeof helperSteps] ?? 0) + 1
    ];

  const callbackFun = () => {
    dispatch(
      updateState({
        state: stateCalc,
        hashName: 'digitalHash'
      })
    )
      .then((res: any) => {
        if (res?.payload?.updatedPlan?.digitalHash) {
          setValue('digitalHash', res?.payload?.updatedPlan?.digitalHash);
        }
        if (!res?.error) {
          navigate(
            `/plans/${planId}/digital-hash${
              nextStep === undefined ? '' : `/${nextStep}`
            }`
          );
        }
      })
      .finally(() => {
        callbackFunFinally();
      });
  };

  return (
    <Box className={classes.stepper_action_container}>
      <Button
        variant="outlined"
        size="small"
        disabled={(helperSteps[stage as keyof typeof helperSteps] ?? 0) === 0}
        onClick={handleBack}
        sx={{ mr: 'auto', minWidth: '100px' }}
      >
        Back
      </Button>
      {activeStep !== steps.length &&
        (completed[activeStep] ? (
          <Typography variant="caption" sx={{ display: 'inline-block' }}>
            <strong>{steps[activeStep]}</strong> already completed
          </Typography>
        ) : null)}
      <Button
        disabled={isLoading}
        size="small"
        startIcon={isLoading ? <CircularProgress size={20} /> : null}
        variant="contained"
        onClick={handleNext}
        sx={{ mr: 1, minWidth: '100px' }}
      >
        {step === steps.length - 1 ? 'Submit' : 'Next'}
      </Button>
    </Box>
  );
};

export default StepperAction;