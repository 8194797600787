import { ICampaignBrief } from '../MultiVerticalBrief/interface';

export const handleCampaignBriefError = (
  campaignBrief: ICampaignBrief
): string => {
  if (!campaignBrief?.audienceType) return 'Audience type is required.';
  if (!campaignBrief?.creativeType?.length)
    return 'Planner has to select at least one creative type.';
  if (campaignBrief?.budget === '' || campaignBrief?.budget === 0)
    return 'Planner has to enter the budget.';
  if (!campaignBrief?.plannersInput) return 'Planners input is required.';
  if (!campaignBrief?.campaignCategory) return 'Campaign category is required.';
  if (campaignBrief?.duration === '' || campaignBrief?.duration === 0)
    return 'Duration is required.';

  // Validate if at least one campaign objective is selected
  const hasSelectedObjective = campaignBrief?.campaignObjective.some(
    (obj) => obj.isSelected
  );
  if (!hasSelectedObjective)
    return 'Planner should select at least one campaign objective.';

  return '';
};
