import { lazy } from 'react';

const CampaignDuration = lazy(() => import("./CampaignDuration"));

const CampaignDurationConfig = {
  name: 'Campaign Duration',
  path: 'campaign-duration',
  element: <CampaignDuration />,
  icon: 'campaignDuration',
};

export default CampaignDurationConfig;
