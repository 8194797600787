import React from 'react';
import List from '@mui/material/List';
import { Check } from 'mdi-material-ui';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';
import Icon from './Assets/Icon';

import classes from './styles/styles.module.css';

interface IHashNavProps {
  hashV2ChildRoutes: {
    name: string;
    path: string;
    element: React.JSX.Element;
    icon: string;
    state: number;
  }[];
  hashStagesWithPathAsKey: Record<string, number>;
}

const HashNav = ({
  hashV2ChildRoutes,
  hashStagesWithPathAsKey
}: IHashNavProps) => {
  const plan = useAppSelector((state: any) => state.planRequests?.plan),
    location = useLocation(),
    { planId } = useParams(),
    path = location.pathname,
    segments = path.split('/');

  let stage = segments[segments.length - 1];

  if (stage === planId) stage = '';

  if (!(stage in hashStagesWithPathAsKey)) stage = 'multi-vertical-brief';

  const currentStage = plan?.hashV2CurrentState ?? 0,
    stageFromUrl = hashStagesWithPathAsKey[stage];

  return (
    <nav
      aria-label="main mailbox folders"
      className={`${classes.scroll_bar}`}
      style={{
        width: '100%',
        maxWidth: 250,
        overflow: 'auto',
        backgroundColor: '#F5F4FC'
      }}
    >
      {plan && plan._id && (
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          {hashV2ChildRoutes.map(({ path, name, state, icon }) => (
            <ListItem key={path} disablePadding>
              <ListItemButton
                disabled={state > currentStage}
                component={NavLink}
                to={`/plans/${plan._id}/hash-v2${path ? `/${path}` : ''}`}
                end
                sx={{
                  backgroundColor:
                    stageFromUrl === state ? '#EAE8F9' : 'transparent',
                  color:
                    state === currentStage || stageFromUrl === state
                      ? '#4e3bc9'
                      : 'green',
                  borderRight:
                    stageFromUrl === state ? '2px solid currentColor' : 'none',
                  'div > *': { color: 'currentColor', fontWeight: 500 },
                  'div > span': {
                    color: stageFromUrl === state ? '#4e3bc9' : '#172B4D',
                    fontWeight: stageFromUrl === state ? 500 : 400
                  },
                  '&:hover': {
                    color: '#4e3bc9',
                    borderRight: '2px solid currentColor',
                    'div > span': { color: 'currentcolor' }
                  },
                  '&.Mui-disabled': {
                    borderRight: 'none',
                    color: '#172B4D',
                    backgroundColor: 'transparent',
                    pointerEvents: 'auto',
                    cursor: 'not-allowed',
                    opacity: 0.5
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    marginRight: '0.5rem',
                    padding: '6px',
                    borderRadius: '50%',
                    color: state <= currentStage ? '#fff' : '#637085',
                    border:
                      state === currentStage || stageFromUrl === state
                        ? '1px solid #4e3bc9'
                        : state < currentStage
                        ? '1px solid green'
                        : '1px solid #637085',
                    backgroundColor:
                      state === currentStage || stageFromUrl === state
                        ? '#4e3bc9'
                        : state < currentStage
                        ? 'green'
                        : 'transparent'
                  }}
                >
                  {state < currentStage ? (
                    <Check sx={{ fontSize: '16px' }} />
                  ) : (
                    <Icon
                      iconName={icon}
                      color={state <= currentStage ? '#fff' : '#637085'}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </nav>
  );
};

export default HashNav;
