import {
  Select,
  Stack,
  Typography,
  Grid,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { ITDSTax, ITax } from '../interface';

interface IPriceCalculatorFormProps {
  bill: any;
  taxes: ITDSTax[];
  total_tax: number;
  tdsAmount: number;
  tdsTaxId: string;
  onTDSChange: (tsd_id: string) => void;
}

const PriceCalculatorForm = ({
  bill,
  taxes,
  total_tax,
  tdsAmount,
  tdsTaxId,
  onTDSChange
}: IPriceCalculatorFormProps) => {
  return (
    <Grid container spacing={4} p={4}>
      <Grid item xs={4}>
        <Typography>Sub Total</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{bill?.sub_total}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Discount</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{bill?.discount}</Typography>
      </Grid>
      {bill?.taxes &&
        bill?.taxes?.length &&
        bill.taxes.map((item: ITax) => (
          <>
            <Grid item xs={4}>
              <Typography>{item.tax_name}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{item?.tax_amount}</Typography>
            </Grid>
          </>
        ))}
      <Grid item xs={4}>
        <Typography>TDS</Typography>
      </Grid>
      <Grid item xs={8}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Select
            size="small"
            sx={{ minWidth: '300px' }}
            value={tdsTaxId}
            onChange={(e: SelectChangeEvent) => onTDSChange(e.target.value)}
          >
            {taxes.map((item: ITDSTax) => (
              <MenuItem value={item.tax_id} key={item.tax_id}>
                {`${item.tax_name} - (${item.tax_percentage}%)`}
              </MenuItem>
            ))}
          </Select>
          <Typography>{tdsAmount}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Typography>Total Tax Amount</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{total_tax}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Total</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{bill?.sub_total + bill?.tax_total + tdsAmount}</Typography>
      </Grid>
    </Grid>
  );
};

export default PriceCalculatorForm;
