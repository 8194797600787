import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Main = () => {
	let location = useLocation();

	useEffect(() => {
		try{
			ReactGA.send({hitType: "pageview", page: location.pathname + location.search})
		} catch(e) {
			console.log("ga pageview error");
			console.log(e);
		}
	}, [location])

	return (
		<Suspense fallback={<div>Loading</div>} >
			<Outlet />
		</Suspense>
	)
}

export default Main;