import { AppDispatch } from '../../../store';
import {
  calculateHashV2MediaMap,
  updateHashV2BdiCdi,
  updateHashV2BudgetAllocator,
  updateHashV2BudgetSplit,
  updateHashV2CampaignBrief,
  updateHashV2CampaignCalendar,
  updateHashV2CaseStudy,
  updateHashV2GeoClustering,
  updateHashV2MediaMap,
  updateHashV2RankingDataInput,
  updateHashV2TargetGroup
} from '../redux/reducers';

import { ICaseStudy } from '../CaseStudy/interface';
import { ICluster } from '../CampaignCalendar/interface';
import { ICampaignBrief } from '../MultiVerticalBrief/interface';
import { IGeoCluster } from '../GeoClustering/interface';
import { IHashV2 } from '../BudgetAllocator/interface';
import { ILocation as ILocationBdiVsCdi } from '../BdiVsCdi/interface';
import { ILocation as ILocationMediaMap } from '../MediaMap/interface';
import { ILocation as ILocationBudgetSplit } from '../BudgetSpit/interface';
import { ILocation as ILocationRankingDataInput } from '../RankingDataInput/interface';
import { ITargetGroup } from '../TargetGroup/interface';

type TSendRequestCommonParameter = {
  dispatch: AppDispatch;
  callbackFun: () => void;
  callbackFunFinally: () => void;
};

type TSendCampaignBriefPutRequest = TSendRequestCommonParameter & {
  campaignBrief: ICampaignBrief;
};

type TSendTargetGroupPutRequest = TSendRequestCommonParameter & {
  targetGroup: ITargetGroup;
};

type TSendRankingFileUploadPutRequest = TSendRequestCommonParameter & {
  locations: ILocationRankingDataInput[];
};

type TSendBdiVsCdiPutRequest = TSendRequestCommonParameter & {
  locations: ILocationBdiVsCdi[];
};

type TSendMediaMapPutRequest = TSendRequestCommonParameter & {
  locations: ILocationMediaMap[];
};

type TSendBudgetSplitPutRequest = TSendRequestCommonParameter & {
  locations: ILocationBudgetSplit[];
};

type TSendGeoClusteringPutRequest = TSendRequestCommonParameter & {
  geoClustering: IGeoCluster[];
};

type TSendBudgetAllocatorPutRequest = TSendRequestCommonParameter & {
  hashV2: IHashV2;
};

type TSendCampaignCalenderPutRequest = TSendRequestCommonParameter & {
  clusters: ICluster[];
};

type TSendCaseStudyPutRequest = TSendRequestCommonParameter & {
  caseStudy: ICaseStudy;
};

const hashName = 'hashV2';

const handleResponse = (
  dispatchAction: Promise<any>,
  callbackFun: () => void,
  callbackFunFinally: () => void
) => {
  dispatchAction.then((response) => {
    if (response?.payload) callbackFun();
    else callbackFunFinally();
  });
};

export const sendCampaignBriefPutRequest = ({
  dispatch,
  callbackFun,
  campaignBrief,
  callbackFunFinally
}: TSendCampaignBriefPutRequest) => {
  const dispatchAction = dispatch(
    updateHashV2CampaignBrief({
      campaignBrief,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendTargetGroupPutRequest = ({
  dispatch,
  callbackFun,
  targetGroup,
  callbackFunFinally
}: TSendTargetGroupPutRequest) => {
  const dispatchAction = dispatch(
    updateHashV2TargetGroup({
      targetGroup,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendRankingFileUploadPutRequest = ({
  dispatch,
  callbackFun,
  locations,
  callbackFunFinally
}: TSendRankingFileUploadPutRequest) => {
  const dispatchAction = dispatch(
    updateHashV2RankingDataInput({
      locations,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendBdiVsCdiPutRequest = ({
  dispatch,
  callbackFun,
  locations,
  callbackFunFinally
}: TSendBdiVsCdiPutRequest) => {
  dispatch(
    updateHashV2BdiCdi({
      locations,
      hashName
    })
  )
    .then((res) => {
      if (!res?.payload)
        throw new Error('Error occurred in updating Bdi Vs Cdi');
      return dispatch(calculateHashV2MediaMap({ hashName }));
    })
    .then((res) => {
      if (!res?.payload) throw new Error('Error occurred in getting Media Map');
      return callbackFun();
    })
    .catch(() => callbackFunFinally());
};

export const sendMediaMapPutRequest = ({
  dispatch,
  callbackFun,
  locations,
  callbackFunFinally
}: TSendMediaMapPutRequest) => {
  const dispatchAction = dispatch(
    updateHashV2MediaMap({
      locations,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendBudgetSplitPutRequest = ({
  dispatch,
  callbackFun,
  locations,
  callbackFunFinally
}: TSendBudgetSplitPutRequest) => {
  const dispatchAction = dispatch(
    updateHashV2BudgetSplit({
      locations,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendGeoClusteringPutRequest = ({
  dispatch,
  callbackFun,
  geoClustering,
  callbackFunFinally
}: TSendGeoClusteringPutRequest) => {
  const dispatchAction = dispatch(
    updateHashV2GeoClustering({
      geoClustering,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendBudgetAllocatorPutRequest = ({
  dispatch,
  callbackFun,
  hashV2,
  callbackFunFinally
}: TSendBudgetAllocatorPutRequest) => {
  const dispatchAction = dispatch(
    updateHashV2BudgetAllocator({
      hashV2,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendCampaignCalenderPutRequest = ({
  dispatch,
  callbackFun,
  clusters,
  callbackFunFinally
}: TSendCampaignCalenderPutRequest) => {
  const dispatchAction = dispatch(
    updateHashV2CampaignCalendar({
      clusters,
      hashName
    })
  );
  handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};

export const sendCaseStudyPutRequest = ({
  dispatch,
  callbackFun,
  caseStudy,
  callbackFunFinally
}: TSendCaseStudyPutRequest) => {
  dispatch(
    updateHashV2CaseStudy({
      caseStudy,
      hashName
    })
  ).finally(() => {
    callbackFunFinally();
  });
  // handleResponse(dispatchAction, callbackFun, callbackFunFinally);
};
