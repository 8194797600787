import { Domain } from 'mdi-material-ui';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../store';

const MediaAgencyList = lazy(() => import('./MediaAgencyList'));
const AddMediaAgency = lazy(() => import('./AddMediaAgency'));

const MediaAgencyConfig = {
  name: 'Media Agency',
  path: 'media-agency',
  element: <Outlet />,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('mediaAgency', slice.default);  
      return true;
    });
  },
  Icon: Domain,

  children: [
    {
      path: '',
      element: <MediaAgencyList />
    },
    {
      path: 'new',
      element: <AddMediaAgency />
    },
    {
      path: ':mediaAgencyId/edit',
      element: <AddMediaAgency />
    },
    {
      path: ':mediaAgencyId/clone',
      element: <AddMediaAgency />
    }
  ]
};

export default MediaAgencyConfig;
