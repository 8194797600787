import CampaignBrief from './CampaignBrief/index';
import OutdoorBrief from './OutdoorBrief/index';
import VendorCollection from './VendorCollection/index';
import VendorExcelUpload from './VendorExcelUpload/index';
import ListingAndTagging from './ListingAndTagging/index';
import Mapping from './Mapping/index';
import BudgetAllocator from './BudgetAllocator/index';
import CampaignCalendar from './CampaignCalendar/index';
import Summary from './Summary/index';

export const outdoorHashChildRoutes = [
  {
    ...CampaignBrief
  },
  {
    ...OutdoorBrief
  },
  {
    ...VendorCollection
  },
  {
    ...VendorExcelUpload
  },
  {
    ...ListingAndTagging
  },
  {
    ...Mapping
  },
  {
    ...BudgetAllocator
  },
  {
    ...CampaignCalendar
  },
  {
    ...Summary
  }
];

export const newStage: { [key: string]: string } = {
  '0': '',
  '10': 'outdoor-brief',
  '20': 'vendor-collection',
  '30': 'vendor-excel-upload',
  '40': 'listing-tagging',
  '50': 'mapping',
  '60': 'budget-allocator',
  '70': 'campaign-calendar',
  '80': 'summary',
  '90': 'summary'
};

export const stages: { [key: string]: string } = {
  '': '0',
  'outdoor-brief': '10',
  'vendor-collection': '20',
  'vendor-excel-upload': '30',
  'listing-tagging': '40',
  mapping: '50',
  'budget-allocator': '60',
  'campaign-calendar': '70',
  summary: '80'
};

export const hashName = 'outdoorHash';
