import { Modal, Box, Typography, IconButton, Stack } from '@mui/material';
import { Close } from 'mdi-material-ui';
import { ReactNode } from 'react';

type TCustomizedModal = {
  open: boolean;
  handleClose: () => void;
  title?: string;
  width?: string;
  children: ReactNode;
  disableTitle?: boolean;
  disableCloseIcon?: boolean;
  maxHeight?: string;
};

const CustomizedModal = ({
  open,
  handleClose,
  title,
  width = '500px',
  children,
  disableTitle = false,
  disableCloseIcon = false,
  maxHeight = "80%"
}: TCustomizedModal) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `min(${width}, 70%)`,
    maxHeight,
    bgcolor: '#fff',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    borderRadius: '8px',
    padding: '24px',
    overflow: 'auto'
  };
  return (
    <Modal keepMounted open={open}>
      <Box sx={style}>
        {disableTitle ? null : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderBottom: '1px solid #DCDFE4' }}
          >
            <Typography variant="h1" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
            {disableCloseIcon ? null : (
              <IconButton aria-label="close" disableRipple onClick={() => handleClose()}>
                <Close sx={{ color: '#4E3BC9', fontSize: '32px' }} />
              </IconButton>
            )}
          </Stack>
        )}
        {/* <Box sx={{ maxHeight: `calc(80% - ${disableTitle ? 0 : 49 + 16}px)`, overflow: "auto" }}>
          {children}
        </Box> */}
        {children}
      </Box>
    </Modal>
  );
};

export default CustomizedModal;
