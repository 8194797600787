import { Forum } from 'mdi-material-ui';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../store';

const FAQList = lazy(() => import('./FAQList'));
const AddFAQ = lazy(() => import('./AddFAQ'));

const FAQConfig = {
  name: 'FAQ',
  path: 'faqs',
  element: <Outlet />,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('faq', slice.default);
      return true;
    });
  },
  Icon: Forum,

  children: [
    { path: '', element: <FAQList /> },
    { path: 'new', element: <AddFAQ /> },
    { path: ':faqId/edit', element: <AddFAQ /> }
  ]
};

export default FAQConfig;
