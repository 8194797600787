import { Domain } from 'mdi-material-ui';
import { lazy } from 'react';

const CompanyOverview = lazy(() => import('./CompanyOverview'));

const CompanyOverviewConfig = {
  name: 'Company Overview',
  path: 'companyOverview',
  element: <CompanyOverview />,
  icon: Domain
};

export default CompanyOverviewConfig;
