import { ITargetGroup } from '../TargetGroup/interface';

export const validateAndFormatData = (data: unknown) => {
  let isValidData: boolean = true;
  let formattedValidData: number = 0;
  if (data === '') {
    isValidData = false;
  } else if (typeof data === 'number') {
    isValidData = true;
    formattedValidData = data;
  } else if (typeof data === 'string') {
    let parsedBudget = parseInt(data);
    if (isNaN(parsedBudget)) {
      isValidData = false;
    } else {
      isValidData = true;
      formattedValidData = parsedBudget;
    }
  } else {
    isValidData = false;
  }
  return { isValidData, formattedValidData };
};

const isAgeValid = (age: string = '') => {
  let [min = '', max = ''] = age.split(' - ');
  max = min === '50+' && max === '' ? '60' : max;
  min = min === '50+' ? '50' : min;
  return { isValid: min && max && Number(min) < Number(max), min, max };
};

const isAgeGroupValid = (ageGroup: string[] = []) =>
  ageGroup
    .filter((age) => !isAgeValid(age)?.isValid)
    .map((age) => isAgeValid(age));

export const validateAge = (ages: string[]) => {
  if (!ages) return 'age array is undefined';

  let errors: string = '';

  const ageGroupValidation = isAgeGroupValid(ages);

  if (ageGroupValidation.length > 0)
    errors = `Please Enter Valid Age Group --> Min: ${ageGroupValidation[0].min}, Max: ${ageGroupValidation[0].max}`;

  return errors;
};

export const handleTargetGroupError = (targetGroup: ITargetGroup): string => {
  if (!targetGroup?.age?.length || targetGroup.age.length === 0)
    return `Please fill target group age.`;

  if (!targetGroup?.gender?.length || targetGroup.gender.length === 0)
    return `Please fill target group gender.`;

  if (!targetGroup?.geos || targetGroup.geos.length === 0)
    return `Please fill geography.`;

  if (!targetGroup?.income?.length || targetGroup.income.length === 0)
    return `Please fill target group income.`;

  if (
    targetGroup?.isCustomAudience &&
    targetGroup?.customAudience?.length === 0
  )
    return 'Please select at least one custom audience';

  return validateAge(targetGroup?.age);
};
