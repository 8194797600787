import React from 'react';
import { Typography } from '@mui/material';
import { NavLink } from "react-router-dom";
import styles from './NavItem.module.css';

interface NavItemProps {
	text: string;
	Icon: any;
	path: string;
}

const NavItem = ({text, Icon, path}: NavItemProps) => {
	return (
		<NavLink className={({isActive}) => isActive ? `${styles.NavItem} ${styles.active}` : styles.NavItem} to={path}>
			<Icon />
			<Typography variant='caption' sx={{lineHeight: 1.2, color: 'inherit'}}>{text}</Typography>
		</NavLink>
	)
}

export default NavItem;