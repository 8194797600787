import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface IDoWeHaveContentToShowProps {
  currentState: number;
  userName: string;
  hashStagesWithPathAsKey: Record<string, number>;
  children: React.ReactNode;
}

const DoWeHaveContentToShow = ({
  currentState,
  userName,
  hashStagesWithPathAsKey,
  children
}: IDoWeHaveContentToShowProps) => {
  const currentStateFromDb = currentState ?? 0,
    location = useLocation(),
    path = location.pathname,
    segments = path.split('/'),
    stage = segments[segments.length - 1],
    validate = currentStateFromDb < hashStagesWithPathAsKey[stage];

  return validate ? (
    <Stack
      sx={{
        display: 'grid',
        placeContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: '#4E3BC9',
          width: '75%',
          margin: 'auto',
          textAlign: 'center'
        }}
      >
        &#128544; {userName}, <br /> You need to complete the previous steps to
        access this tab.
      </Typography>
    </Stack>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

export default DoWeHaveContentToShow;
