import { lazy } from 'react';

const TargetGroup = lazy(() => import('./TargetGroup'));

const TargetGroupConfig = {
  name: 'Target Group',
  path: 'target-group',
  element: <TargetGroup />,
  icon: 'targetGroup'
};

export default TargetGroupConfig;
