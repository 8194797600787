import { TextField, TextFieldProps } from '@mui/material';

const CustomTextFieldForNumberFormat = ({
  sx = {},
  ...props
}: TextFieldProps) => (
  <TextField
    size="medium"
    {...props}
    variant="outlined"
    sx={{
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-input': {
        color: '#6B778C',
        height: '34px',
        padding: '9.5px 12px',
        boxSizing: 'border-box'
      },
      ...sx
    }}
  />
);

export default CustomTextFieldForNumberFormat;
