import { lazy } from 'react';

const AudienceSelection = lazy(() => import('./AudienceSelection'));

const AudienceSelectionConfig = {
  name: 'Audience Selection',
  path: 'audience-selection',
  element: <AudienceSelection />,
  icon: 'audienceSelection'
};

export default AudienceSelectionConfig;
