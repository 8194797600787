import { GoogleAnalytics } from 'mdi-material-ui';
import { lazy } from 'react';

const Analytics = lazy(() => import('./Analytics'));

const AnalyticsConfig = {
  name: 'Analytics (SOV)',
  path: 'analytics',
  element: <Analytics />,
  icon: GoogleAnalytics
};

export default AnalyticsConfig;
