import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import apiService from './apiService';
import slsService from './slsService';

import adexSlice from './Adex/redux/adexSlice';
import sessionSlice from './sessionSlice';
import SnackBarSlice from './Shared/SnackBar/slice';

interface AsyncReducers {
	[key: string]: Reducer
}

const staticReducers = {
	session: sessionSlice,
	adex: adexSlice,
	snackBar: SnackBarSlice
}

const store = configureStore({
	reducer: staticReducers,
	middleware: getDefaultMiddleware => getDefaultMiddleware({
		thunk: {
			extraArgument: {
				API: apiService,
				SLS: slsService
			}
		}
	})
});

const asyncReducers: AsyncReducers = {};

export const injectReducer = (key: string, asyncReducer: Reducer) => {
	if(asyncReducers) {
		asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(asyncReducers));
	}
}

function createReducer(asyncReducers: AsyncReducers) {
	return combineReducers({
		...staticReducers,
		...asyncReducers
	})
}

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch