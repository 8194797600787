import { lazy } from 'react';

const CampaignCalendar = lazy(() => import('./CampaignCalendar'));

const CampaignCalendarConfig = {
  name: 'Campaign Calendar',
  path: 'campaign-calendar',
  element: <CampaignCalendar />,
  icon: 'campaignBrief',
  state: 80
};

export default CampaignCalendarConfig;
