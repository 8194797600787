import { lazy } from 'react';

const RankingFileUpload = lazy(() => import('./RankingFileUpload'));

const RankingFileUploadConfig = {
  name: 'Ranking File Upload',
  path: 'ranking-file-upload',
  element: <RankingFileUpload />,
  icon: 'fileUpload'
};

export default RankingFileUploadConfig;
