import { GeographyAutocomplete } from '@media-ant/ui';
import { Stack, Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { Geo } from '../../../shared/SharedMediaSpecificDetails';
import { geoValue } from '../../../../../components/SharedHashes/Shared/geoValue';

import { TFilters } from '../TargetGroup';

interface IGeoLocationProps {
  targetKey: string;
  calculateAudienceSize?: (updatedFilters: Partial<TFilters>) => void;
}

const GeoLocation = ({
  targetKey,
  calculateAudienceSize
}: IGeoLocationProps) => {
  const { control } = useFormContext(),
    geos = useFieldArray({ name: `${targetKey}`, control });

  const handleGeoSubmit = (values: Geo[]) => {
    geos.replace(values);
    if (calculateAudienceSize) {
      calculateAudienceSize({ geos: values.map((geo: any) => geoValue(geo)) });
    }
  };

  return (
    <Stack sx={{ gap: '0.75rem' }}>
      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        Geo Location *
      </Typography>
      <Controller
        name={`${targetKey}`}
        control={control}
        rules={{
          required: true
        }}
        render={({ field }) => (
          <GeographyAutocomplete
            selectedGeos={field.value}
            onChange={handleGeoSubmit}
          />
        )}
      />
    </Stack>
  );
};

export default GeoLocation;
