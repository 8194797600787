import { Suspense, lazy } from 'react';
import { MediaPlans } from '../Campaign/IconLibrary/MenuIcons';
import { injectReducer } from '../store';
import { Outlet } from 'react-router-dom';
// import CampaignBrief from "./ProjectHash/CampaignBrief/CampaignBrief";
import CampaignBriefConfig from './ProjectHash/CampaignBrief';
import FrequencyCalculatorConfig from './ProjectHash/FrequencyCalculator';
import RankingFileUploadConfig from './ProjectHash/RankingFileUpload';
import TgBankConfig from './ProjectHash/TgBank';
import MixMediaModelerConfig from './ProjectHash/MixMediaModeler';
import CampaignDurationConfig from './ProjectHash/CampaignDuration';
import CampaignCalendarConfig from './ProjectHash/CampaignCalendar';
import BudgetAllocatorConfig from './ProjectHash/BudgetAllocator';
import SummaryConfig from './ProjectHash/Summary';
import { radioHashChildRoutes } from './RadioHash/config';
import RadioHash from './RadioHash';
import PrintHash from './PrintHash';
import OutdoorHash from './OutdoorHash';
import DigitalHash from './DigitalHash';
import HashV2 from './HashV2';
import { hashV2ChildRoutes } from './HashV2/config';
import { printHashChildRoutes } from './PrintHash/config';
import { outdoorHashChildRoutes } from './OutdoorHash/config';
import { digitalHashChildRoutes } from './DigitalHash/config';
import NotFound from './shared/NotFound';

const PlanRequest = lazy(() => import('./PlanRequests'));
const ProjectHash = lazy(() => import('./ProjectHash'));

export const projectHashChildRoutes = [
  {
    ...CampaignBriefConfig
  },
  {
    ...FrequencyCalculatorConfig
  },
  {
    ...RankingFileUploadConfig
  },
  {
    ...TgBankConfig
  },
  {
    ...MixMediaModelerConfig
  },
  {
    ...CampaignDurationConfig
  },
  {
    ...CampaignCalendarConfig
  },
  {
    ...BudgetAllocatorConfig
  },
  {
    ...SummaryConfig
  }
];

const Wrapper = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Outlet />
  </Suspense>
);

const PlanRequestPageConfig = {
  name: 'Plans',
  path: '/plans',
  element: <Wrapper />,
  Icon: MediaPlans,
  children: [
    {
      path: '',
      element: <PlanRequest />
    },
    {
      path: ':planId',
      element: <Wrapper />,
      children: [
        {
          path: '',
          element: <ProjectHash />,
          children: projectHashChildRoutes
        },
        {
          path: 'hash-v2',
          element: <HashV2 />,
          children: hashV2ChildRoutes
        },
        {
          path: 'radioHash',
          element: <RadioHash />,
          children: radioHashChildRoutes
        },
        {
          path: 'print-hash',
          element: <PrintHash />,
          children: printHashChildRoutes
        },
        {
          path: 'outdoor-hash',
          element: <OutdoorHash />,
          children: outdoorHashChildRoutes
        },
        {
          path: 'digital-hash',
          element: <DigitalHash />,
          children: digitalHashChildRoutes
        },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    },
    {
      path: '*',
      element: <NotFound />
    }
  ],
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('planRequests', slice.default);
      return true;
    });
  }
};

export default PlanRequestPageConfig;
