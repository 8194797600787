import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { Email } from 'mdi-material-ui';
import { injectReducer } from '../store';

const EmailTemplateList = lazy(() => import('./EmailTemplateList'));
const AddEmailTemplate = lazy(() => import('./AddEmailTemplate'));

const EmailTemplateConfig = {
  name: 'Email Template',
  path: 'email-template',
  element: <Outlet />,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('emailTemplate', slice.default);
      return true;
    });
  },
  Icon: Email,

  children: [
    {
      path: '',
      element: <EmailTemplateList />
    },
    {
      path: 'new',
      element: <AddEmailTemplate />
    },
    {
      path: ':emailTemplateId/edit',
      element: <AddEmailTemplate />
    }
  ]
};

export default EmailTemplateConfig;
