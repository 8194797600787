import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import apiService from '../../app/apiService';

let debounce: unknown;

interface ICampaignAutocomplete {
  defaultValue?: any;
  apiPath: string;
  onSelect: (value: any | null) => void;
  showProperty?: string;
  dataProperty?: string;
  urlProperty: string;
  searchByDomain?: boolean;
}

interface ICampaigns {
  _id: string;
  name?: string;
}

const CustomAutoComplete = ({
  defaultValue,
  apiPath,
  showProperty = 'name',
  dataProperty,
  urlProperty,
  onSelect,
  searchByDomain = false
}: ICampaignAutocomplete) => {
  const [value, setValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<ICampaigns[]>([]);

  const fetchResults = (searchTerm: string) => {
    if (searchTerm.length < 2) return;
    setIsLoading(true);
    let filters: any = {};
    filters[urlProperty] = searchTerm;
    if (searchByDomain) filters['domain'] = 'themediaant.com';
    apiService
      .get(
        `${apiPath}?params=` + encodeURIComponent(JSON.stringify({ filters })),
        {
          headers: { 'x-access-token': localStorage.authToken }
        }
      )
      .then((res) => {
        setIsLoading(false);
        setOptions(dataProperty ? res.data[dataProperty] : res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('Error in fetching', error);
      });
  };

  const onInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    if (event) {
      setIsLoading(true);
      if (debounce) clearTimeout(debounce as string);
      debounce = setTimeout(() => {
        fetchResults(value);
      }, 500);
    }
  };
  return (
    <Autocomplete
      value={value || null}
      size="small"
      loading={isLoading}
      onInputChange={onInputChange}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      freeSolo
      onChange={(event, newValue) => {
        setValue(newValue);
        onSelect(newValue);
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option[showProperty];
      }}
      renderOption={(props, option) => (
        <li {...props} key={option._id}>
          {option[showProperty]}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={`Search by ${showProperty}`}
          variant="outlined"
          sx={{
            width: '200px',
            '&.MuiAutocomplete-inputRoot': {
              padding: '5.5px 4px 7.5px 6px'
            }
          }}
        />
      )}
    />
  );
};

export default CustomAutoComplete;
