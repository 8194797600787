import { lazy } from 'react';

const MediaMapMain = lazy(() => import('./MediaMapMain'));

const MediaMapMainConfig = {
  name: 'Media Map',
  path: 'media-map',
  element: <MediaMapMain />,
  icon: 'media-map',
  state: 40
};

export default MediaMapMainConfig;
