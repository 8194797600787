import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';
import { TLocation } from '../interface';
import { TPrintHash } from '../../BudgetAllocator/interface';

export type TDuration = {
  geoHash: string;
  placeId: string;
  value: number;
};

export interface IData<T> {
  locations: T[];
  hashName: string;
  hashSpecificPath: string;
}

export const printHashCampaignDurationAddMonths = createAppAsyncThunk<
  any,
  IData<TDuration>
>(
  'plan-requests/printHashCampaignDurationAddMonths',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data && data?.locations && data?.hashSpecificPath) {
        const response = await API.put(
          `/plan-requests/${planId}/${data.hashSpecificPath}`,
          {
            duration: [...data.locations]
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const printHashUpdateCampaignDuration = createAppAsyncThunk<
  any,
  { locations: TLocation[]; hashName: string; hashSpecificPath: string }
>(
  'plan-requests/printHashUpdateCampaignDuration',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data && data?.locations && data?.hashSpecificPath) {
        const response = await API.put(
          `/plan-requests/${planId}/${data.hashSpecificPath}`,
          {
            locations: [...data.locations]
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const printHashCalculateBudgetAllocator = createAppAsyncThunk<
  any,
  { hashName: string; hashSpecificPath: string }
>(
  'plan-requests/printHashCalculateBudgetAllocator',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data && data?.hashSpecificPath) {
        const response = await API.put(
          `/plan-requests/${planId}/${data.hashSpecificPath}`,
          {}
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const printHashBudgetAllocator = createAppAsyncThunk<
  any,
  { printHash: TPrintHash; hashName: string; hashSpecificPath: string }
>(
  'plan-requests/printHashBudgetAllocator',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (
        planId &&
        data &&
        data?.printHash &&
        data?.hashSpecificPath &&
        data?.hashName
      ) {
        const response = await API.put(
          `/plan-requests/${planId}/${data.hashSpecificPath}`,
          {
            [data.hashName]: { ...data.printHash }
          }
        );
        return {
          updatedPlan: response.data?.updatedPlan,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const printHashCampaignDurationExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(
    printHashCampaignDurationAddMonths.fulfilled,
    (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName as string];
      }
    }
  );
  builder.addCase(
    printHashUpdateCampaignDuration.fulfilled,
    (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName as string];
      }
    }
  );
  builder.addCase(printHashBudgetAllocator.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan && action?.payload?.hashName) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan[action.payload.hashName as keyof IPlan] =
        action.payload.updatedPlan[action.payload.hashName];
    }
  });
  builder.addCase(
    printHashCalculateBudgetAllocator.fulfilled,
    (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    }
  );
};
