import { lazy } from 'react';

const BudgetAllocatorMain = lazy(() => import('./BudgetAllocatorMain'));

const BudgetAllocatorMainConfig = {
  name: 'Budget Allocator',
  path: 'budget-allocator',
  element: <BudgetAllocatorMain />,
  icon: 'budget-allocator',
  state: 70
};

export default BudgetAllocatorMainConfig;
