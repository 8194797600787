import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { InitialState } from '../../../redux/slice';
import { TRankingData } from '../interface';

type TPayloadAction = {
  locationIdx: number;
  toolName: string;
  remark: string;
};

type TUpdateLocationRankingDataPayloadAction = {
  locationIdx: number;
  rankingData: TRankingData[];
};

const defaultMediaRole = {
  primary: 'TG Reach',
  secondary: 'Frequency Reach',
  tertiary: 'Impact'
};

export const mixMediaModelerReducers = {
  setUpdatedLocationRankingData(
    state: Draft<InitialState>,
    action: PayloadAction<TUpdateLocationRankingDataPayloadAction>
  ) {
    const { locationIdx, rankingData } = action.payload;

    if (state.plan?.hash) {
      state.plan.hash.locations[locationIdx].rankingData = rankingData;
    }
  },
  setRemarkForSoftDeletedVertical(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, toolName, remark } = action.payload;

    if (state.plan?.hash) {
      state.plan.hash.locations[locationIdx].rankingData =
        state.plan.hash.locations[locationIdx].rankingData.map((data) => {
          if (data.toolName === toolName) {
            return {
              ...data,
              deleteReason: remark,
              deletedAt: JSON.stringify(new Date())
            }; // need to update deleted at
          }
          return data;
        });
    }
  },
  removeRemarkForSoftDeletedVertical(
    state: Draft<InitialState>,
    action: PayloadAction<Omit<TPayloadAction, 'remark'>>
  ) {
    const { locationIdx, toolName } = action.payload;

    if (state.plan?.hash) {
      state.plan.hash.locations[locationIdx].rankingData =
        state.plan.hash.locations[locationIdx].rankingData.map((data) => {
          if (data.toolName === toolName) {
            const updateData = { ...data };
            if (updateData?.deleteReason) delete updateData.deleteReason;
            if (updateData?.deletedAt) delete updateData.deletedAt;
            return updateData; // need to update deleted at
          }
          return data;
        });
    }
  },
  resetRankingData(
    state: Draft<InitialState>,
    action: PayloadAction<Omit<TPayloadAction, 'remark' | 'toolName'>>
  ) {
    const { locationIdx } = action.payload;
    if (state.plan?.hash) {
      state.plan.hash.locations[locationIdx].rankingData =
        state.plan.hash.locations[locationIdx].rankingData.map((data) => {
          const updateData = { ...data };
          if (updateData?.deleteReason) delete updateData.deleteReason;
          if (updateData?.deletedAt) delete updateData.deletedAt;
          updateData.mediaRole = { ...defaultMediaRole };
          return updateData;
        });
    }
  }
};
