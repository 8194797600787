import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from '@mui/material';

import CustomTextFieldForNumberFormat from '../../../../components/SharedHashes/Shared/CustomTextFieldForNumberFormat';

interface IAudienceDialogProps {
  open: boolean;
  handleClose: () => void;
  handleUpdateCustomAudience: () => void;
  audienceSizeImg: string;
  targetKey: string;
  handleOpen: (data: boolean) => void;
}

const MIN_LIMIT = 1,
  MAX_LIMIT = 100_000_000;

const DialogHeader = ({ audienceSizeImg }: { audienceSizeImg: string }) => (
  <Stack sx={{ gap: '1rem', alignItems: 'center' }}>
    <img
      src={audienceSizeImg}
      alt="audience"
      style={{ width: '228px', aspectRatio: '1/1' }}
    />
    <DialogTitle variant="h2" sx={{ fontWeight: 500 }}>
      Edit your Audience Size
    </DialogTitle>
  </Stack>
);

const AudienceDialog = ({
  open,
  handleClose,
  handleUpdateCustomAudience,
  audienceSizeImg,
  targetKey,
  handleOpen
}: IAudienceDialogProps) => {
  const { setValue, control } = useFormContext(),
    audienceSize = useWatch({ name: `${targetKey}`, control }) ?? '',
    [customAudienceSize, setCustomAudienceSize] = useState<string | number>(
      () => (audienceSize ? audienceSize : '')
    );

  const handleSubmit = () => {
    if (customAudienceSize !== '') {
      setValue(targetKey, customAudienceSize);
    }
    handleOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          handleUpdateCustomAudience();
          handleSubmit();
        },
        style: { width: 'calc(min(80%, 800px))' }
      }}
    >
      <DialogHeader audienceSizeImg={audienceSizeImg} />
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}
      >
        <DialogContentText>Audience Size</DialogContentText>
        <NumericFormat
          customInput={CustomTextFieldForNumberFormat}
          value={customAudienceSize}
          thousandSeparator=","
          decimalScale={0}
          size="small"
          error={!Boolean(customAudienceSize)}
          allowNegative={false}
          inputMode="numeric"
          fullWidth
          placeholder="Enter Your Audience Size"
          isAllowed={(values) => {
            const { floatValue, value } = values;
            if (!value) return true;
            if (floatValue === undefined) return false;
            return floatValue >= MIN_LIMIT && floatValue <= MAX_LIMIT;
          }}
          onValueChange={(e: NumberFormatValues) => {
            setCustomAudienceSize(e.value);
          }}
        />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: '#EEECFF', width: '100%' }}>
        <Button size="small" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          size="small"
          type="submit"
          variant="contained"
          disabled={customAudienceSize === ''}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudienceDialog;
