import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Stack
} from '@mui/material';
import { Close } from 'mdi-material-ui';
//   import { FolderSvg } from '../SvgImages/FolderSvg';
import { useNavigate } from 'react-router-dom';

type TSaveDraftModal = {
  open: boolean;
  handleClose: (value: boolean) => void;
  brandName: string;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
  borderRadius: '8px',
  padding: '40px'
};

const SaveDraftModal = ({ open, handleClose, brandName }: TSaveDraftModal) => {
  const navigate = useNavigate();
  return (
    <Modal
      keepMounted
      open={open}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        {/* <FolderSvg /> */}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <Close sx={{ color: '#4E3BC9', fontSize: '32px' }} />
        </IconButton>

        <Typography
          variant="h1"
          sx={{ fontWeight: 600, mt: 2 }}
          id="keep-mounted-modal-description"
        >
          {brandName
            ? `Your drafts for ${brandName} have been successfully saved.`
            : 'Your drafts have been successfully saved.'}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100%', gap: '24px' }}
        >
          <Button
            variant="outlined"
            sx={{ width: '160px', height: '40px' }}
            onClick={() => handleClose(false)}
          >
            Ok
          </Button>
          <Button
            variant="contained"
            sx={{ width: '160px', height: '40px' }}
            onClick={() => navigate('/plans')}
          >
            List page
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SaveDraftModal;
