import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '../../../hooks';
import { SnackBarActions } from '../../../Shared/SnackBar/slice';
import { InitialState, IPlan } from '../../redux/slice';

import { ICampaignBrief } from '../MultiVerticalBrief/interface';
import { ITargetGroup } from '../TargetGroup/interface';
import { ILocation as ILocationRankingDataInput } from '../RankingDataInput/interface';
import { ILocation as ILocationBdiVsCdi } from '../BdiVsCdi/interface';
import { ILocation as ILocationMediaMap } from '../MediaMap/interface';
import { ILocation as ILocationBudgetSplit } from '../BudgetSpit/interface';
import { IGeoCluster } from '../GeoClustering/interface';
import { IHashV2 } from '../BudgetAllocator/interface';
import { ICluster } from '../CampaignCalendar/interface';
import { ICaseStudy } from '../CaseStudy/interface';

const getEncodedJsonString = (data: unknown): string => {
  const jsonString = JSON.stringify(data),
    encodedJsonString = encodeURIComponent(jsonString);
  return encodedJsonString;
};

export const fetchCategory = createAppAsyncThunk<any, void>(
  'plan-requests/fetchCategory',
  async (_, { extra: { API }, dispatch }) => {
    try {
      const response = await API.get(
        `media/category/edit-config?configuration="category"`
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2CampaignBrief = createAppAsyncThunk<
  any,
  { campaignBrief: ICampaignBrief; hashName: string }
>(
  'plan-requests/updateHashV2CampaignBrief',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.campaignBrief || !data?.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-mash-brief`,
        {
          campaignBrief: data.campaignBrief
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2TargetGroup = createAppAsyncThunk<
  any,
  { targetGroup: ITargetGroup; hashName: string }
>(
  'plan-requests/updateHashV2TargetGroup',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.targetGroup || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-target-group`,
        {
          targetGroup: data.targetGroup
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const hashV2AudienceSizeCalculator = createAppAsyncThunk<
  any,
  { params: { [key: string]: string[] } }
>(
  'plan-requests/hashV2AudienceSizeCalculator',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.params) return undefined;

    const encodedJsonString = getEncodedJsonString(data.params);

    try {
      const response = await API.get(
        `plan-requests/${planId}/hash/v2/audience-size-calculator?params=${encodedJsonString}`
      );

      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2RankingDataInput = createAppAsyncThunk<
  any,
  { locations: ILocationRankingDataInput[]; hashName: string }
>(
  'plan-requests/updateHashV2RankingDataInput',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.locations || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-ranking-data`,
        {
          locations: data.locations
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2BdiCdi = createAppAsyncThunk<
  any,
  { locations: ILocationBdiVsCdi[]; hashName: string }
>(
  'plan-requests/updateHashV2BdiCdi',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.locations || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-bdi-cdi`,
        {
          locations: data.locations
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const calculateHashV2MediaMap = createAppAsyncThunk<
  any,
  { hashName: string }
>(
  'plan-requests/calculateHashV2MediaMap',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/media-map`,
        {}
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2MediaMap = createAppAsyncThunk<
  any,
  { locations: ILocationMediaMap[]; hashName: string }
>(
  'plan-requests/updateHashV2MediaMap',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.locations || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-media-map`,
        {
          locations: data.locations
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2BudgetSplit = createAppAsyncThunk<
  any,
  { locations: ILocationBudgetSplit[]; hashName: string }
>(
  'plan-requests/updateHashV2BudgetSplit',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.locations || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-vertical-budget`,
        {
          locations: data.locations
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2GeoClustering = createAppAsyncThunk<
  any,
  { geoClustering: IGeoCluster[]; hashName: string }
>(
  'plan-requests/updateHashV2GeoClustering',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.geoClustering || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2//update-geo-clustering`,
        {
          geoClustering: data.geoClustering
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2BudgetAllocator = createAppAsyncThunk<
  any,
  { hashV2: IHashV2; hashName: string }
>(
  'plan-requests/updateHashV2BudgetAllocator',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.hashV2 || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-budget-allocator`,
        {
          hashV2: data.hashV2
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2CampaignCalendar = createAppAsyncThunk<
  any,
  { clusters: ICluster[]; hashName: string }
>(
  'plan-requests/updateHashV2CampaignCalendar',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.clusters || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-campaign-calendar`,
        {
          clusters: data.clusters
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2CaseStudy = createAppAsyncThunk<
  any,
  { caseStudy: ICaseStudy; hashName: string }
>(
  'plan-requests/updateHashV2CaseStudy',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    if (!data?.caseStudy || !data.hashName) return undefined;

    try {
      const response = await API.put(
        `plan-requests/${planId}/hash/v2/update-case-study`,
        {
          caseStudy: data.caseStudy
        }
      );
      return {
        updatedPlan: response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateHashV2State = createAppAsyncThunk<
  any,
  { state: number; hashName: string }
>(
  'plan-requests/updateHashV2State',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    if (!planId || typeof data?.state !== 'number' || !data?.hashName)
      return undefined;

    try {
      const response = await API.put(`/plan-requests/${planId}/hash/V2/state`, {
        state: data.state
      });

      return {
        updatedPlan:
          response.data?.updatedPlanRequest ?? response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const autoGenerateHashV2Plan = createAppAsyncThunk<
  any,
  {
    setProgress: React.Dispatch<React.SetStateAction<number>>;
    hashName: string;
  }
>(
  'plan-requests/autoGenerateHashV2Plan',
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    if (!planId || !data?.hashName || !data?.setProgress) return undefined;

    try {
      const response = await API.put(
        `/plan-requests/${planId}/hash/V2/auto-plan`,
        {},
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percentage = total ? Math.floor((loaded * 100) / total) : 0;
            data.setProgress(percentage);
          }
        }
      );

      return {
        updatedPlan:
          response.data?.updatedPlanRequest ?? response.data?.updatedPlan,
        hashName: data?.hashName
      };
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data?.message || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

const updatePlanState = <T>(
  state: InitialState,
  action: PayloadAction<
    any,
    string,
    {
      arg: T;
      requestId: string;
      requestStatus: 'fulfilled';
    },
    never
  >
) => {
  if (action?.payload?.updatedPlan && action?.payload?.hashName) {
    state.plan.hashV2CurrentState =
      action.payload.updatedPlan.hashV2CurrentState;
    state.plan[action.payload.hashName as keyof IPlan] =
      action.payload.updatedPlan[action.payload.hashName];
  }
};

export const hashV2ExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder
    .addCase(updateHashV2CampaignBrief.fulfilled, (state, action) => {
      updatePlanState<{
        campaignBrief: ICampaignBrief;
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2TargetGroup.fulfilled, (state, action) => {
      updatePlanState<{
        targetGroup: ITargetGroup;
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2RankingDataInput.fulfilled, (state, action) => {
      updatePlanState<{
        locations: ILocationRankingDataInput[];
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2BdiCdi.fulfilled, (state, action) => {
      updatePlanState<{
        locations: ILocationBdiVsCdi[];
        hashName: string;
      }>(state, action);
    })
    .addCase(calculateHashV2MediaMap.fulfilled, (state, action) => {
      updatePlanState<{
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2MediaMap.fulfilled, (state, action) => {
      updatePlanState<{
        locations: ILocationMediaMap[];
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2BudgetSplit.fulfilled, (state, action) => {
      updatePlanState<{
        locations: ILocationBudgetSplit[];
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2GeoClustering.fulfilled, (state, action) => {
      updatePlanState<{
        geoClustering: IGeoCluster[];
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2BudgetAllocator.fulfilled, (state, action) => {
      updatePlanState<{
        hashV2: IHashV2;
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2CampaignCalendar.fulfilled, (state, action) => {
      updatePlanState<{
        clusters: ICluster[];
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2CaseStudy.fulfilled, (state, action) => {
      updatePlanState<{
        caseStudy: ICaseStudy;
        hashName: string;
      }>(state, action);
    })
    .addCase(updateHashV2State.fulfilled, (state, action) => {
      updatePlanState<{
        state: number;
        hashName: string;
      }>(state, action);
    })
    .addCase(autoGenerateHashV2Plan.fulfilled, (state, action) => {
      updatePlanState<{
        hashName: string;
      }>(state, action);
    });
};
