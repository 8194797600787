import { Stack, Typography } from '@mui/material';

import { TargetGroupDetailsAsText } from '../../TargetGroup/TargetGroupRightSideSection';
import TargetGroupPopUp from './TargetGroupPopUp';

interface IContentFilterSectionProps {
  targetKey: string;
  compType: string;
}

interface IFiltersSectionProps extends IContentFilterSectionProps {}

const HeaderFilterSection = () => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{ padding: '1.25rem 1rem', backgroundColor: '#eeebff' }}
  >
    <Typography variant="h4" sx={{ fontWeight: 500, color: '#4e3bc9' }}>
      Filters
    </Typography>
    <TargetGroupPopUp />
  </Stack>
);

const ContentFilterSection = ({
  targetKey,
  compType
}: IContentFilterSectionProps) => (
  <Stack sx={{ padding: '1rem' }}>
    <TargetGroupDetailsAsText targetKey={targetKey} compType={compType} />
  </Stack>
);

const FiltersSection = ({ targetKey, compType }: IFiltersSectionProps) => (
  <Stack
    sx={{
      backgroundColor: '#fff',
      borderRadius: '0.5rem',
      overflow: 'hidden',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
    }}
  >
    <HeaderFilterSection />
    <ContentFilterSection targetKey={targetKey} compType={compType} />
  </Stack>
);

export default FiltersSection;
