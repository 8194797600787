import { lazy } from 'react';

const CampaignCalendar = lazy(() => import("./CampaignCalendar"));

const CampaignCalendarConfig = {
  name: 'Campaign Calendar',
  path: 'campaign-calendar',
  element: <CampaignCalendar />,
  icon: 'campaignCalendar',
};

export default CampaignCalendarConfig;
