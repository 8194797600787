import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';

import ContentCardRenderView from '../../Shared/ContentRenderView';

import {
  getCampaignBriefStaticData,
  ICampaignBriefStaticData
} from '../../Utils/getCampaignBriefStaticData';
import { getGeo } from '../../Utils/getGeo';

interface IRenderCompetitionComp {
  campaignBriefStaticData: ICampaignBriefStaticData;
  data: keyof typeof helper;
}

const helper: Record<keyof ICampaignBriefStaticData, string> = {
  productDescription: 'Product Description',
  campaignDescription: 'Campaign Description',
  budget: 'Budget',
  competition: 'Competition',
  duration: 'Duration'
};

const RenderCompetitionComp = ({
  campaignBriefStaticData,
  data
}: IRenderCompetitionComp) =>
  campaignBriefStaticData.competition.length > 0 ? (
    <List dense={true}>
      {(
        campaignBriefStaticData[
          data as keyof typeof helper
        ] as typeof campaignBriefStaticData['competition']
      ).map((item, index) => (
        <ListItem key={`${item}-${index}`}>
          <ListItemText primary={`${index + 1}. ${item}`} />
        </ListItem>
      ))}
    </List>
  ) : (
    <>-</>
  );

const CampaignBriefRightSideSection: React.FC = ({
  targetKey = '_campaign.brief'
}: {
  targetKey?: string;
}) => {
  const { getValues } = useFormContext(),
    campaignBriefStaticData = getCampaignBriefStaticData(getValues(targetKey)),
    campaignId = getValues('_campaign._id');

  const {
    targetGroup: { geos = [] }
  } = getValues('digitalHash');

  return (
    <React.Fragment>
      <Stack alignItems="flex-end">
        <Button
          variant="contained"
          size="small"
          href={`/campaigns/${campaignId}`}
          sx={{
            marginBottom: '1rem'
          }}
          target="_blank"
        >
          Go to Campaign Brief
        </Button>
      </Stack>
      <ContentCardRenderView title="Geography">
        {geos.length !== 0 ? (
          <Typography variant="body1">{getGeo(geos)}</Typography>
        ) : (
          <Typography variant="body1">-</Typography>
        )}
      </ContentCardRenderView>
      {Object.keys(helper).map((data, idx) => (
        <ContentCardRenderView
          key={`${data}-${idx}`}
          title={helper[data as keyof typeof helper]}
        >
          {data === 'competition' && data in campaignBriefStaticData ? (
            <RenderCompetitionComp
              campaignBriefStaticData={campaignBriefStaticData}
              data={data}
            />
          ) : data === 'budget' ? (
            <Typography>
              {typeof campaignBriefStaticData[data as keyof typeof helper]
                ? new Intl.NumberFormat('en-IN').format(
                    parseFloat(
                      `${campaignBriefStaticData[data as keyof typeof helper]}`
                    )
                  )
                : campaignBriefStaticData[data as keyof typeof helper]}
            </Typography>
          ) : (
            <Typography>
              {campaignBriefStaticData[data as keyof typeof helper]
                ? campaignBriefStaticData[data as keyof typeof helper]
                : '-'}
            </Typography>
          )}
        </ContentCardRenderView>
      ))}
    </React.Fragment>
  );
};

export default CampaignBriefRightSideSection;
