export const campaignObjective = [
  {
    key: 'awareness',
    isSelected: false,
    options: [
      { key: 'launch', isSelected: false },
      { key: 'reminder', isSelected: false }
    ]
  },
  {
    key: 'consideration',
    isSelected: false,
    options: [
      { key: 'engagement', isSelected: false },
      { key: 'traffic', isSelected: false }
    ]
  },
  {
    key: 'trial',
    isSelected: false,
    options: [
      { key: 'online sales', isSelected: false },
      { key: 'store footfall', isSelected: false },
      { key: 'app action', isSelected: false }
    ]
  }
];
