import { lazy } from 'react';

const FrequencyCalculator = lazy(() => import('./FrequencyCalculator'));

const FrequencyCalculatorConfig = {
  name: 'Frequency Calculator',
  path: 'frequency-calculator',
  element: <FrequencyCalculator />,
  icon: 'frequencyCalculator',
};

export default FrequencyCalculatorConfig;
