import { lazy } from 'react';

const VendorExcelUpload = lazy(() => import("./VendorExcelUpload"));

const vendorExcelUploadConfig = {
  name: "Vendor Excel Upload",
  path: "vendor-excel-upload",
  element: <VendorExcelUpload />,
  icon: 'radioBrief'
};

export default vendorExcelUploadConfig;