import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { PlusCircleOutline } from 'mdi-material-ui';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { fetchPO } from '../redux/actionCreator';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import ConvertToCurrencySystem from '../../Shared/ConvertToCurrency';
import { IBillFiles } from '../interface';
import BillsUploadDialog from '../Bills/BillsUploadDialog';
import BillCard from '../Bills/BillCard';

const PurchaseOrder = () => {
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(true);
  const { po } = useSelector((state: any) => state.poSlice);
  const [open, setOpen] = useState<boolean>(false);
    const [isRejecting, setIsRejecting] = useState<string | null>(null);
    const { poId = '' } = useParams();

    useEffect(() => {
      setIsFetching(true);
      dispatch(fetchPO(poId)).then((res: any) => setIsFetching(false));
    }, [poId]);

    const handleRejectBill = (poId: string, billId: string, reason: string) => {
      setIsRejecting(billId);
      console.log(poId, billId, reason);
    };

    let data = [
      {
        displayName: 'Vendor',
        value: po?.order?.vendor_name
      },
      {
        displayName: 'GSTIN',
        value: po?.order?.gst_no
      },
      {
        displayName: 'GST Treatment',
        value: po?.order?.tax_treatment
      },
      {
        displayName: 'Billing Address',
        value: po?.order?.billing_address?.address
      },
      {
        displayName: 'Delivery Address',
        value: po?.order?.delivery_address?.address
      },
      {
        displayName: 'Payment Terms',
        value: po?.order?.payment_terms_label || 'N/A'
      },
      {
        displayName: 'Total',
        value: (
          <ConvertToCurrencySystem value={po?.order?.total} currency={true} />
        )
      }
    ];

    return (
      <Box>
        {isFetching ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            p={40}
          >
            <Typography>Loading....</Typography>
            <CircularProgress size={18} />
          </Stack>
        ) : (
          <>
            <Grid container>
              <Grid item xs={6} p={4} sx={{ borderRight: '2px solid #e4e5e6' }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h1" fontWeight={600}>
                    Purchase Order
                  </Typography>
                  <Typography
                    variant="h4"
                    fontWeight={500}
                    color="primary.main"
                  >
                    {po?.order?.status?.toUpperCase()}
                  </Typography>
                </Stack>
                <Divider sx={{ m: '10px 0px' }} />
                {po?.order?.html_string ? (
                  <div
                    style={{
                      background: '#f0f0f0',
                      padding: '20px',
                      maxHeight: '600px',
                      overflow: 'auto'
                    }}
                    dangerouslySetInnerHTML={{ __html: po?.order?.html_string }}
                  />
                ) : (
                  <Stack spacing={2}>
                    <Typography variant="h3" fontWeight={500}>
                      {po?.order?.purchaseorder_number}
                    </Typography>
                    <Typography variant="subtitle2">{`Branch: ${
                      po?.order?.branch_name || 'N/A'
                    }`}</Typography>

                    <Typography variant="subtitle2">
                      {format(new Date(po?.order?.delivery_date), 'P')}
                    </Typography>
                    <Divider sx={{ m: '10px 0px' }} />
                    <Grid container spacing={4}>
                      {data.map((item, index) => (
                        <>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2">
                              {item.displayName}
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="subtitle2">
                              {item.value}
                            </Typography>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Stack>
                )}
                <Divider sx={{ m: '10px 0px' }} />
              </Grid>
              <Grid item xs={6}>
                <Stack p={4}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h3" fontWeight={500}>
                      {`Bills (${po?.bills?.length})`}
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{
                        padding: '6px 22px',
                        fontSize: '14px',
                        borderRadius: '4px',
                        fontWeight: 500
                      }}
                      onClick={() => setOpen(true)}
                      startIcon={<PlusCircleOutline />}
                    >
                      Upload
                    </Button>
                  </Stack>
                  <Box>
                    {po?.bills && po?.bills?.length > 0 ? (
                      po?.bills?.map((item: IBillFiles, index: number) => (
                        <BillCard
                          bill={item}
                          poId={poId}
                          handleRejectBill={handleRejectBill}
                          isRejecting={isRejecting}
                        />
                      ))
                    ) : (
                      <Typography>No Bills Uploaded Yet</Typography>
                    )}
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <BillsUploadDialog
              open={open}
              onClose={() => setOpen(false)}
              bills={po?.bills}
              poId={poId}
            />
          </>
        )}
      </Box>
    );
};;;

export default PurchaseOrder;
