import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';
import { Geo } from '../../Mapping/interface';

export const outdoorHashUpdateBudgetAllocator = createAppAsyncThunk<
  any,
  {
    hashName: string;
    data: {
      durations: { _id: string; duration: number }[];
      locations: {
        locationId: string;
        outdoorUniverse: number;
        remarks: string;
        subLocalities: Geo[];
      }[];
    };
  }
>(
  'plan-requests/outdoorHashUpdateBudgetAllocator',
  async (item, { getState, extra: { API }, dispatch }) => {
    const state: any = getState(),
      planId = state?.planRequests?.plan?._id;

    try {
      if (
        planId &&
        item?.hashName &&
        item?.data?.durations &&
        item?.data?.locations &&
        Array.isArray(item?.data?.durations) &&
        Array.isArray(item?.data?.locations)
      ) {
        const response = await API.put(
          `/plan-requests/${planId}/hash/outdoor/inventory/duration`,
          {
            durations: item?.data?.durations,
            locations: item?.data?.locations
          }
        );

        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: item?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashBudgetAllocatorExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(
    outdoorHashUpdateBudgetAllocator.fulfilled,
    (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    }
  );
};
