import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';

export type TWeightage = {
  irsListenership: number | string;
  directCompetition: number | string;
  category: number | string;
  mashRadio: number | string;
  clutter: number | string;
  cost: number | string;
};

type TLocation = {
  geoHash: string;
  placeId: string;
  mediaSpace: string[];
}[];

export const radioHashMediaSpaceSelectedStatons = createAppAsyncThunk<
  any,
  { locations: TLocation }
>(
  'plan-requests/radioHashMediaSpaceSelectedStatons',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      const response = await API.put(
        `plan-requests/${planId}/radio-hash/select-media-space`,
        {
          selectedMediaSpace: data.locations
        }
      );
      dispatch(
        SnackBarActions.openSnack({
          message: 'Media Space updated successfully',
          open: true,
          type: 'success'
        })
      );
      return response?.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const radioHashMediaSpaceSelectedStatonsExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(
    radioHashMediaSpaceSelectedStatons.fulfilled,
    (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.radioHash = action.payload.updatedPlan.radioHash;
      }
    }
  );
};
