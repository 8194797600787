import {
  TAudienceContextAndUserPersonaCommonItems,
  TAudienceContextTargetGroup
} from '../AudienceContextFile/interface';
import { IAudienceSelection } from '../AudienceSelection/interface';
import { ICampaignBrief } from '../CampaignBrief/interface';
import { ITargetGroup } from '../TargetGroup/interface';

import { errorValidationMediaSpecificDetail } from '../../shared/SharedCampaignBrief';
import { isValidNumber } from '../BudgetAllocator/CalculationFormulas/utils';

export const getDefaultFormattedAges = (ages: string[]) =>
  ages?.map((age) => {
    const [min = '', max = ''] = age.split(' - ');
    return { min, max };
  });

const isAgeValid = (age: string = '') => {
  const [min = 'Empty', max = 'Empty'] = age.split(' - ');
  return { isValid: min && max && Number(min) < Number(max), min, max };
};

const isAgeGroupValid = (ageGroup: string[] = []) =>
  ageGroup
    .filter((age) => !isAgeValid(age)?.isValid)
    .map((age) => isAgeValid(age));

export const handleCampaignBriefError = (campaignBrief: ICampaignBrief) => {
  let errors: string = '';

  if (
    campaignBrief?.campaignObjective &&
    !campaignBrief.campaignObjective.some((objective) => objective.isSelected)
  ) {
    errors = 'Campaign Objective is required';
  } else if (!campaignBrief?.productName?.length) {
    errors = 'Product Name is required';
  } else if (!campaignBrief?.plannersInput) {
    errors = 'Planners Input is required';
  } else if (!campaignBrief?.audienceType) {
    errors = 'Audience Type is required';
  }

  return errors;
};

const validateAge = (ages: string[]) => {
  if (!ages) return 'age array is undefined';
  let errors: string = '';

  const ageGroupValidation = isAgeGroupValid(ages);

  if (ageGroupValidation.length > 0) {
    errors = `Please Enter Valid Age Group --> Min: ${ageGroupValidation[0].min}, Max: ${ageGroupValidation[0].max}`;
  }
  return errors;
};

export const handleTargetGroupError = (targetGroup: ITargetGroup) => {
  let errors: string = '';
  errors = errorValidationMediaSpecificDetail('digital', targetGroup);

  if (errors) return errors;
  if (
    targetGroup?.isCustomAudience &&
    targetGroup?.customAudience?.length === 0
  )
    return 'Please select at least one custom audience';

  return validateAge(targetGroup?.age);
};

export const handleAudienceContextAndUserPersonaError = (
  data: TAudienceContextAndUserPersonaCommonItems & TAudienceContextTargetGroup,
  key: 'audienceContext' | 'userPersona'
) => {
  let errors: string = '';
  let contextDetailsLength = data?.contextDetails?.filter(
    (item) => item.isSelected
  )?.length;
  if (!data?.interest) {
    errors = `${
      key === 'audienceContext' ? 'Behaviour/Interest' : 'Persona'
    } is required`;
  } else if (!data?.file?.name) {
    errors = 'Please upload GWI File';
  } else if (contextDetailsLength === 0 || contextDetailsLength > 3) {
    errors =
      'Users must select at least one row and can select a maximum of three rows.';
  } else if (!data?.gender?.length || data.gender.length === 0)
    errors = `Please fill gender details`;
  else if (!data?.geos || data.geos.length === 0)
    errors = `Please fill geography details`;
  else if (!data?.income?.length || data.income.length === 0)
    errors = `Please fill income details`;

  if (errors) return errors;

  return validateAge(data?.age);
};

export const handleAudienceSelectionError = (
  data: IAudienceSelection[] = []
) => {
  let errors: string = '';
  for (let i = 0; i < data.length; i++) {
    if (!isValidNumber(data[i].audienceSize)) {
      errors = `Please enter valid audience size for ${data[i].name} - ${data[i].mopName} - ${data[i].priority}`;
      break;
    }
  }
  return errors;
};
