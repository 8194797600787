import React from 'react';
import {
  Snackbar as MUISnackbar,
  Alert,
  SnackbarCloseReason
} from '@mui/material';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { SnackBarActions } from './slice';
import Slide, { SlideProps } from '@mui/material/Slide';

const typeArray = ['error', 'success', 'info', 'warning'];

const SnackBar = () => {
  const {
    open,
    message,
    type = 'info',
    duration
  } = useSelector((state: any) => state.snackBar.openSnack);
  const dispatch = useAppDispatch();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(
      SnackBarActions.openSnack({
        open: false
      })
    );
  };

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <MUISnackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={duration ? duration : 5000}
      TransitionComponent={SlideTransition}
    >
      <Alert
        severity={typeArray.includes(type) ? type : 'info'}
        sx={{
          color: 'white'
        }}
        onClose={handleClose}
        variant="filled"
      >
        {message}
      </Alert>
    </MUISnackbar>
  );
};

export default SnackBar;
