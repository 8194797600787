import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { InitialState } from '../../redux/slice';
import { createAppAsyncThunk } from '../../../hooks';
import { SnackBarActions } from '../../../Shared/SnackBar/slice';
import { TLocation } from '../interface';

export type TWeightage = {
  irsListenership: number | string;
  directCompetition: number | string;
  category: number | string;
  mashRadio: number | string;
  clutter: number | string;
  cost: number | string;
};

export const radioHashUpdateWeightage = createAppAsyncThunk<
  any,
  { weightage: TWeightage }
>(
  'plan-requests/radioHashUpdateWeightage',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    if (planId) {
      return API.put(`/plan-requests/${planId}/radio-hash/weightage`, data)
        .then((res: any) => {
          return res?.data;
        })
        .catch((err: any) => {
          let response = { ...err.response };
          dispatch(
            SnackBarActions.openSnack({
              message: response?.data?.message || 'Error Occurred.',
              open: true,
              type: 'error'
            })
          );
          throw err;
        });
    }
  }
);

export const radioHashRankingFileUpload = createAppAsyncThunk<any, File>(
  'plan-requests/radioHashRankingFileUpload',
  async (file, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await API.put(
        `/plan-requests/${planId}/radio-hash/upload-ranking-file`,
        formData
      );
      dispatch(
        SnackBarActions.openSnack({
          message: 'Ranking File uploaded successfully',
          open: true,
          type: 'success'
        })
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const radioHashUpdateRankingFileUpload = createAppAsyncThunk<
  any,
  { locations: TLocation }
>(
  'plan-requests/radioHashUpdateRankingFileUpload',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const { _id: planId, radioHash } = state?.planRequests?.plan;

    try {
      if (planId && radioHash && data && data?.locations) {
        const response = await API.put(
          `/plan-requests/${planId}/radio-hash/update-ranking-data`,
          {
            radioHash: { ...radioHash, locations: data.locations }
          }
        );
        dispatch(
          SnackBarActions.openSnack({
            message: 'Ranking File uploaded successfully',
            open: true,
            type: 'success'
          })
        );
        return response.data;
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const radioHashMixMediaModeler = createAppAsyncThunk<
  any,
  { locations: TLocation }
>(
  'plan-requests/radioHashMixMediaModeler',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data && data?.locations) {
        const response = await API.put(
          `/plan-requests/${planId}/radio-hash/media-mix-modeler`,
          {
            ...data
          }
        );
        return response.data;
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const radioHashRankingFileUploadExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(radioHashRankingFileUpload.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan.radioHash = action.payload.updatedPlan.radioHash;
    }
  });
  builder.addCase(
    radioHashUpdateRankingFileUpload.fulfilled,
    (state, action) => {
      if (action?.payload?.updatedPlan) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan.radioHash = action.payload.updatedPlan.radioHash;
      }
    }
  );
  builder.addCase(radioHashMixMediaModeler.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan.radioHash = action.payload.updatedPlan.radioHash;
    }
  });
};
