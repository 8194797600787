import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../../store';

const AMTeam = lazy(() => import('./AMTeam'));
const TaskManagment = lazy(() => import('../index'));

const Icon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2353 12.875V8.6C18.2353 7.33988 18.2353 6.70982 17.9853 6.22852C17.7653 5.80516 17.4143 5.46095 16.9827 5.24524C16.4919 5 15.8495 5 14.5647 5H9.67059C8.38576 5 7.74335 5 7.25261 5.24524C6.82094 5.46095 6.46999 5.80516 6.25004 6.22852C6 6.70982 6 7.33988 6 8.6V16.4C6 17.6601 6 18.2902 6.25004 18.7715C6.46999 19.1948 6.82094 19.539 7.25261 19.7548C7.74335 20 8.38576 20 9.67059 20H12.1176M13.6471 11.75H9.05882M10.5882 14.75H9.05882M15.1765 8.75H9.05882M14.0294 17.75L15.5588 19.25L19 15.875"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const AMTeamConfig = {
  name: 'AM Team',
  path: 'account-management',
  element: <Outlet />,
  Icon,
  loader: () => {
    return import('../redux/slice').then((slice) => {
      injectReducer('tasks', slice.default);
      return true;
    });
  },
  children: [
    {
      path: '',
      element: <AMTeam />
    },
    {
      path: ':taskId',
      element: <TaskManagment />
    }
  ]
};

export default AMTeamConfig;
