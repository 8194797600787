import { lazy } from 'react';

const MediaSpace = lazy(() => import("./MediaSpace"));

const MediaSpaceConfig = {
  name: "Media Space",
  path: "media-space",
  element: <MediaSpace />,
  icon: 'mediaSpace'
};

export default MediaSpaceConfig;
