import { useState } from 'react';
import { ChevronDown } from 'mdi-material-ui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from '@mui/material';

interface ICustomAccordion {
  title: string;
  children: React.ReactNode;
}

const CustomAccordion = ({ title, children }: ICustomAccordion) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange =
    () => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? true : false);
    };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange()}
      square={true}
      sx={{
        '&::before': { visibility: 'hidden' },
        marginBottom: '16px',
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        overflow: 'hidden'
      }}
    >
      <AccordionSummary
        sx={{
          padding: '0 16px',
          backgroundColor: '#EEEBFF',
          color: '#4E3BC9',
          fontWeight: 500
        }}
        expandIcon={
          <Box
            sx={{
              backgroundColor: '#31249F',
              borderRadius: '50%',
              width: '24px',
              height: '24px'
            }}
          >
            <ChevronDown sx={{ color: '#FFFFFF' }} />
          </Box>
        }
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid rgba(0, 0, 0, .125)',
          padding: '16px',
          backgroundColor: '#FAFBFC'
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
