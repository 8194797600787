import React from 'react';

import FinalizedAudienceContext from './FinalizedAudienceContext';
import FiltersSection from './FiltersSection';

import { ITargetKeyRequired } from '../../interface';

type IContextFileRightSideSectionProps = {
  compType?: string;
} & Partial<ITargetKeyRequired>;

const ContextFileRightSideSection = ({
  targetKey = 'digitalHash.audienceContext',
  compType = 'audienceContext'
}: IContextFileRightSideSectionProps) => (
  <React.Fragment>
    <FinalizedAudienceContext
      // key={compType}
      targetKey={`${targetKey}.contextDetails`}
      compType={compType}
    />
    <FiltersSection targetKey="digitalHash" compType={compType} />
  </React.Fragment>
);

export default ContextFileRightSideSection;
