import { lazy } from 'react';

const TargetGroupMain = lazy(() => import('./TargetGroupMain'));

const TargetGroupMainConfig = {
  name: 'Target Group',
  path: 'target-group',
  element: <TargetGroupMain />,
  icon: 'target-group',
  state: 10
};

export default TargetGroupMainConfig;
