import { Suspense, useEffect, useRef } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';

import CustomStepper from './Stepper';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchPlan } from '../redux/slice';

const Fallback = () => (
  <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
    Loading...
  </Stack>
);

const DigitalHash = () => {
  const isLoading = useAppSelector(
    (state: any) => state?.planRequests?.planLevelIsLoading
  );
  const renderRef = useRef(false);
  const dispatch = useAppDispatch(),
    { planId } = useParams();

  useEffect(() => {
    if (!renderRef.current && planId) {
      dispatch(fetchPlan(planId));
      renderRef.current = true;
    }
  }, [planId]);

  return (
    <Stack
      direction="row"
      sx={{ backgroundColor: '#fff', height: 'calc(100% - 64px)' }}
    >
      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={6}
          sx={{ marginTop: '120px', height: '300px', width: '100%' }}
        >
          <Typography>Loading...</Typography>
          <CircularProgress size={20} />
        </Stack>
      ) : (
        <CustomStepper>
          <Suspense fallback={<Fallback />}>
            <Outlet />
          </Suspense>
        </CustomStepper>
      )}
    </Stack>
  );
};

export default DigitalHash;
