import { lazy } from 'react';

const AudienceContextFile = lazy(() => import('./AudienceContextFile'));

const AudienceContextFileConfig = {
  name: 'Audience Context File',
  path: 'audience-context-file',
  element: <AudienceContextFile />,
  icon: 'audienceContextFile'
};

export default AudienceContextFileConfig;
