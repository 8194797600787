import { lazy } from 'react';

const PlanSummary = lazy(() => import('./PlanSummary'));

const PlanSummaryConfig = {
  name: 'Plan Summary',
  path: 'plan-summary',
  element: <PlanSummary />,
  icon: 'planSummary',
};

export default PlanSummaryConfig;
