import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import DataGrid, { Column } from 'react-data-grid';
import './styles/styles.css';
import { ILineItems } from '../interface';
import { DeleteOutline } from 'mdi-material-ui';

interface IItemsTableProps {
  lineItems: any;
  onDeleteRow: (index: number) => void;
}

const ItemsTable = ({ lineItems, onDeleteRow }: IItemsTableProps) => {
  const columns: Column<ILineItems>[] = [
    {
      key: 'details',
      name: 'Item Details',
      width: 300,
      renderCell: ({ row }) => (
        <Tooltip title={row?.description}>
          <Typography
            noWrap
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {row?.description}
          </Typography>
        </Tooltip>
      )
    },
    {
      key: 'account',
      name: 'Account',
      renderCell: ({ row }) => <Typography>{row?.account_name}</Typography>
    },
    {
      key: 'quantity',
      name: 'Quantity',
      renderCell: ({ row }) => <Typography>{row?.quantity}</Typography>
    },
    {
      key: 'rate',
      name: 'Rate',
      renderCell: ({ row }) => <Typography>{row?.rate}</Typography>
    },
    {
      key: 'tax',
      name: 'Tax',
      renderCell: ({ row }) => <Typography>{row?.tax_name}</Typography>
    },
    {
      key: 'amount',
      name: 'Amount',
      renderCell: ({ row }) => <Typography>{row?.item_total}</Typography>
    },
    {
      key: 'action',
      name: 'Action',
      renderCell: ({ row }) => (
        <IconButton
          color="error"
          disabled={lineItems.length < 2}
          onClick={() =>
            onDeleteRow(lineItems.findIndex((item: any) => item.id === row.id))
          }
        >
          <DeleteOutline />
        </IconButton>
      )
    }
  ];

  return (
    <Stack spacing={4}>
      <Divider />
      <Typography variant="h2">Item Table</Typography>
      <Divider />
      {lineItems && lineItems.length && (
        <DataGrid
          style={{
            overflowX: 'auto',
            gridColumnStart: 1,
            gridColumnEnd: -1,
            height: '100%'
          }}
          rowHeight={60}
          columns={columns}
          rows={lineItems}
        />
      )}
    </Stack>
  );
};

export default ItemsTable;
