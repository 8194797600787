import { createAppAsyncThunk } from '../../hooks';
import { SnackBarActions } from '../../Shared/SnackBar/slice';
import { IParams } from '../interface';

export const fetchPOS = createAppAsyncThunk<any, IParams>(
  'pos/getAll',
  async (params, { extra: { SLS }, dispatch }) => {
    return SLS.get(`campaigns/po/list?params=${JSON.stringify(params)}`)
      .then((res: any) => {
        return res.data.data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message:
              e?.response?.data?.message || 'Error occurred in fetching list',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);
export const fetchPO = createAppAsyncThunk<any, string>(
  'pos/getOne',
  async (poId, { extra: { SLS }, dispatch }) => {
    return SLS.get(`/campaigns/po/${poId}`)
      .then((res: any) => {
        return res.data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message:
              e?.response?.data?.message || 'Error occurred while fetching PO',
            oepn: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

export const fetchBillConfig = createAppAsyncThunk<any, string>(
  'pos/bill/config',
  async (poId, { extra: { SLS }, dispatch }) => {
    return SLS.get(`campaigns/po/${poId}/bill-config`)
      .then((res: any) => {
        return res.data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message:
              e?.response?.data?.message ||
              'Error occurred in fetching Bill config',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

type TBillUpload = {
  zohoPoId: string;
  formData: FormData;
  user: {
    _id: string;
    name: string;
    email: string;
  };
};

export const billUpload = createAppAsyncThunk<any, TBillUpload>(
  'pos/bill/upload',
  async (data, { extra: { SLS }, dispatch }) => {
    return SLS.post(
      `/campaigns/po/${data.zohoPoId}/bills/upload`,
      data.formData
    )
      .then((res: any) => {
        console.log(res.data);
        dispatch(
          SnackBarActions.openSnack({
            message: 'Bills uploaded successfully',
            open: true,
            type: 'success'
          })
        );
        return { response: res?.data?.data, user: data?.user };
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message: e?.response?.data?.message || 'Error in uploading files',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

type TBillSubmit = {
  poId: string;
  billId: string;
  bill: any;
};

export const billSubmit = createAppAsyncThunk<any, TBillSubmit>(
  'pos/bill/submit',
  async (data, { extra: { SLS }, dispatch }) => {
    return SLS.post(
      `/campaigns/po/${data.poId}/bills/${data.billId}`,
      data.bill
    )
      .then((res: any) => {
        dispatch(
          SnackBarActions.openSnack({
            message: 'Bill had been submitted',
            open: true,
            type: 'success'
          })
        );
        return res.data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message:
              e?.response?.data?.message || 'Error occurred in submitting bill',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

export const sendPOReminders = createAppAsyncThunk<any, string[]>(
  'pos/send/remminders',
  async (poIds, { extra: { SLS }, dispatch }) => {
    return SLS.patch(
      `/campaigns/po/send-remainder?zohoPoIds=${JSON.stringify(poIds)}`
    )
      .then((res: any) => {
        dispatch(
          SnackBarActions.openSnack({
            message: 'Reminder sent to selected Vendors',
            open: true,
            type: 'success'
          })
        );
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message:
              e?.response?.data?.message ||
              'Error occurred in sending reminders',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);

type TRejectBill = {
  poId: string;
  billId: string;
  comment: string;
};

export const rejectBill = createAppAsyncThunk<any, TRejectBill>(
  'pos/bills/reject',
  async (data, { extra: { SLS }, dispatch }) => {
    return SLS.patch(
      `/campaigns/po/${data.poId}/bills/${data?.billId}/reject`,
      {
        comment: data?.comment
      }
    )
      .then((res: any) => {
        dispatch(
          SnackBarActions.openSnack({
            message: 'Bill has been rejected successfully',
            open: true,
            type: 'success'
          })
        );
        return res.data;
      })
      .catch((e: any) => {
        console.log({ e });
        dispatch(
          SnackBarActions.openSnack({
            message:
              e?.response?.data?.message || 'Error occurred in rejecting bill',
            open: true,
            type: 'error'
          })
        );
        throw e;
      });
  }
);
