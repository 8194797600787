import { FileDocumentMultipleOutline } from 'mdi-material-ui';
import { lazy } from 'react';

const Banner = lazy(() => import('./Banner'));

const BannerConfig = {
  name: 'Banner',
  path: 'banner',
  element: <Banner />,
  icon: FileDocumentMultipleOutline
};

export default BannerConfig;
