import {
  ActionReducerMapBuilder,
  PayloadAction,
  Draft
} from '@reduxjs/toolkit';
import { InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';
import { TRadioData } from '../interface';

export type TWeightage = {
  irsListenership: number | string;
  directCompetition: number | string;
  category: number | string;
  mashRadio: number | string;
  clutter: number | string;
  cost: number | string;
};

type TLocation = {
  placeId: string;
  geoHash: string;
  mediaIds: string[];
  languages: string[];
};

type TLocationsData = {
  locations: TLocation[];
};

export const radioHashMediaSpecificDetails = createAppAsyncThunk<
  any,
  TLocationsData
>(
  'plan-requests/radioHashMediaSpecificDetails',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      const response = await API.put(
        `plan-requests/${planId}/radio-hash/mediaSpecific`,
        {
          ...data
        }
      );
      dispatch(
        SnackBarActions.openSnack({
          message: 'Radio Brief updated successfully',
          open: true,
          type: 'success'
        })
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const radioHashMediaSpace = createAppAsyncThunk<any, void>(
  'plan-requests/radioHashMediaSpace',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      const response = await API.put(
        `plan-requests/${planId}/radio-hash/add-media-space`,
        {}
      );
      return response.data;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const updateMediaSpecificDetailsRadioReducers = {
  setUpdateMediaSpecificDetails(
    state: Draft<InitialState>,
    action: PayloadAction<any>
  ) {
    if (
      state?.plan?._campaign?.brief?.mediaSpecificDetails &&
      action.payload &&
      action.payload?.tool
    ) {
      state.plan._campaign.brief.mediaSpecificDetails =
        state.plan._campaign.brief.mediaSpecificDetails.map(
          (mediaSpecificDetail: TRadioData) => {
            if (mediaSpecificDetail?.tool === action.payload?.tool) {
              return { ...action.payload };
            }
            return mediaSpecificDetail;
          }
        );
    }
  }
};

export const updateMediaSpecificDetailsRadioExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(radioHashMediaSpecificDetails.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan.radioHash = action.payload.updatedPlan.radioHash;
    }
  });
  builder.addCase(radioHashMediaSpace.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan.radioHash = action.payload.updatedPlan.radioHash;
    }
  });
};
