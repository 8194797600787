import { lazy } from 'react';

const BudgetSpitMain = lazy(() => import('./BudgetSpitMain'));

const BudgetSpitMainConfig = {
  name: 'Budget Split',
  path: 'budget-split',
  element: <BudgetSpitMain />,
  icon: 'budget-split',
  state: 50
};

export default BudgetSpitMainConfig;
