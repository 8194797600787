import { ILocation } from '../RankingDataInput/interface';

export const handleRankingFileUploadError = (locations: ILocation[]) => {
  for (const location of locations) {
    for (const [rankingIdx, ranking] of location.rankingData.entries()) {
      for (const [adexIdx, adexItem] of ranking.adex.entries()) {
        if (adexItem.value === '') {
          return `${location.geoHash} → Tool Name: ${
            ranking.toolName
          } → Adex Column: ${adexIdx + 1} (${
            adexItem.key
          }) → Value is missing and required`;
        }
      }

      if (ranking.irs === '') {
        return `${location.geoHash} → Tool Name: ${ranking.toolName} → IRS Column → Value is missing and required`;
      }

      if (ranking.gwi === '') {
        return `${location.geoHash} → Tool Name: ${ranking.toolName} → GWI Column → Value is missing and required`;
      }

      for (const [mashIdx, mashItem] of ranking.mash.entries()) {
        if (mashItem.value === '') {
          return `${location.geoHash} → Tool Name: ${
            ranking.toolName
          } → Mash Column: ${mashIdx + 1} (${
            mashItem.key
          }) → Value is missing and required`;
        }
      }
    }
  }
  return '';
};
