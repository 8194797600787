import { Box, Grid } from "@mui/material";
import LoginForm from './LoginForm';
import styles from './index.module.css';

function index() {
	return (
		<Grid container sx={{ borderRadius: "12px !important", height: "35em" }}>
			<Grid item xs={12} className={styles.actionsContainer} sx={{ backgroundColor: 'common.white' }}>
				<Box className={styles.mainContainer}>
					<LoginForm />
				</Box>
			</Grid>
		</Grid>
	)
}

export default index;