import { lazy } from 'react';
import { Upload } from 'mdi-material-ui';
import { injectReducer } from '../store';
import { Outlet } from 'react-router-dom';

const UploadsList = lazy(() => import('./UploadsList'));

const EditUpload = lazy(() => import('./UploadsEdit/index'));

const UplpoadConfig = {
  name: 'Uploads',
  path: 'uploads',
  element: <Outlet />,
  Icon: Upload,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('uploads', slice.default);
      return true;
    });
  },

  children: [
    { path: '', element: <UploadsList /> },
    {
      path: 'new',
      element: <EditUpload />
    },
    {
      path: ':uploadId/edit',
      element: <EditUpload />
    }
  ]
};

export default UplpoadConfig;
