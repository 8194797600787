import React from 'react';
import { useFormContext } from 'react-hook-form';

import GeoLocation from './GeoLocation';
import WrapperContainer from '../Shared/WrapperContainer';
import Gender from './Gender';
import Income from './Income';
import AgeGroup from './AgeGroup';
import DirectionStack from './CustomAudience';

import { geoValue } from '../../../../components/SharedHashes/Shared/geoValue';
import { useAppDispatch } from '../../../hooks';
import { getDigitalHashAudienceSize } from '../Stepper/redux/reducers';

interface ITargetGroupProps {
  targetKey?: string;
}

export type TFilters = {
  geos: string[];
  gender: string[];
  income: string[];
  age: string[];
};

const generateParams = (filters: TFilters) => {
  const params: { [k: string]: string[] } = {};
  for (const key in filters) {
    if (params[key]?.length !== 0) {
      params[key] = filters[key as keyof TFilters];
    }
  }
  return params;
};

const getInitialValues = (targetGroup: TFilters) => {
  const { geos, gender, income, age } = targetGroup,
    initialValues = {
      geos: geos?.length ? geos.map((geo: any) => geoValue(geo)) : [],
      gender: gender?.length ? gender : [],
      income: income?.length ? income : [],
      age: age?.length ? age : []
    };
  return initialValues;
};

const TargetGroup = ({
  targetKey = 'digitalHash.targetGroup'
}: ITargetGroupProps) => {
  const dispatch = useAppDispatch(),
    { getValues, setValue } = useFormContext(),
    targetGroup = getValues(targetKey);

  const calculateAudienceSize = (updatedFilters: Partial<TFilters>) => {
    const filters = getInitialValues(targetGroup),
      params = generateParams({ ...filters, ...updatedFilters });

    dispatch(
      getDigitalHashAudienceSize({ params, hashName: 'digitalHash' })
    ).then(({ payload }) => {
      if (payload?.audienceSize) {
        setValue(`${targetKey}.audienceSize`, payload?.audienceSize);
      }
    });
  };

  return (
    <WrapperContainer>
      <GeoLocation
        targetKey={`${targetKey}.geos`}
        calculateAudienceSize={calculateAudienceSize}
      />
      <Gender
        targetKey={`${targetKey}.gender`}
        calculateAudienceSize={calculateAudienceSize}
      />
      <Income
        targetKey={`${targetKey}.income`}
        calculateAudienceSize={calculateAudienceSize}
      />
      <AgeGroup
        targetKey={`${targetKey}.age`}
        calculateAudienceSize={calculateAudienceSize}
      />
      <DirectionStack targetKey={`${targetKey}`} />
    </WrapperContainer>
  );
};

export default TargetGroup;