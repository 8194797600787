import { lazy } from 'react';

const MultiVerticalBriefMain = lazy(() => import('./MultiVerticalBriefMain'));

const MultiVerticalBriefMainConfig = {
  name: 'Multi Vertical Brief',
  path: '',
  element: <MultiVerticalBriefMain />,
  icon: 'multi-vertical-brief',
  state: 0
};

export default MultiVerticalBriefMainConfig;
