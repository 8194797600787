import { lazy, Suspense } from 'react';
import { Factory } from 'mdi-material-ui';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../store';
import { fieldsRoute } from './config';

const PageList = lazy(() => import('./PageList'));
const Form = lazy(() => import('./Form'));

const Wrapper = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Outlet />
  </Suspense>
);

const LandingPageConfig = {
  name: 'Industry Landing Page',
  path: 'industry-landing-page',
  element: <Wrapper />,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('industryLandingPage', slice.default);
      return true;
    });
  },
  Icon: Factory,
  children: [
    { path: '', element: <PageList /> },
    {
      path: ':industryPageId',
      element: <Form />,
      children: fieldsRoute
    }
  ]
};

export default LandingPageConfig;
