import { createSlice } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "./hooks";
import { AxiosError, AxiosResponse } from "axios";

type User = {
	_id: string;
	email: string;
	firstName: string;
	lastName: string;
	name: string;
	_ppic: string;
	role: string;
	groups: string[];
	permissions: string[];
	reportsTo: string;
};

type InitialState = {
	loading: boolean;
	user?: Partial<User>;
	isUserLoggedIn: boolean;
};

type UserCredentials = {
	email: string;
	password: string;
};

const initialState: InitialState = {
	loading: false,
	isUserLoggedIn: false,
};

export const getSession = createAppAsyncThunk("session/fetch", async (args, { extra: { API } }) => {
	return API.post("/authenticate/me")
		.then((res: AxiosResponse) => res.data.user)
		.catch((err: AxiosError) => {
			window.location.replace("/login");
			console.log(err)
		});
});

export const googleSignIn = createAppAsyncThunk<void, String>("session/googleSignIn", async (credential, { extra: { API } }) => {
	return API.post("/authenticate/google", { token: credential, checkRole: true })
		.then((res: AxiosResponse) => localStorage.setItem("authToken", res.data.token))
		.catch((err: AxiosError) => console.log(err));
});

export const signin = createAppAsyncThunk("signinForm", async (data: UserCredentials, { extra: { API } }) => {
	return API.post("/authenticate/signin", { email: data.email, password: data.password, checkRole: true })
		.then((res: AxiosResponse) => localStorage.setItem("authToken", res.data.token))
		.catch((err: AxiosError) => console.log("signinForm", err));
});

const sessionSlice = createSlice({
	name: "session",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSession.fulfilled, (state, action) => {
			state.user = action.payload;
			state.isUserLoggedIn = true;
			state.loading = false;
		});
		builder.addCase(getSession.pending, (state) => {
			state.loading = true;
			state.isUserLoggedIn = false;
		});
	},
});

export default sessionSlice.reducer;
