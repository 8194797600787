import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { InitialState } from '../../../redux/slice';
import { TRankingData } from '../interface';

type TPayloadAction = {
  locationIdx: number;
  keyName: string;
  toolName: string;
  updatedValue: number;
};

type TUpdateCompetitorData = Omit<TPayloadAction, 'updatedValue'> & {
  updatedValue: number[];
};

type TExpectedKeyNames = Pick<
  TRankingData,
  'brandAdex' | 'irs' | 'mash' | 'gwi'
>;

export const rankingFileUploadTable = {
  setUpdatedCellData(
    state: Draft<InitialState>,
    action: PayloadAction<TPayloadAction>
  ) {
    const { locationIdx, keyName, updatedValue, toolName } = action.payload;

    if (state.plan?.hash){
      state.plan.hash.locations[locationIdx].rankingData =
      state.plan.hash.locations[locationIdx].rankingData.map((data) => {
        if (data.toolName === toolName) {
          let updateData = { ...data };
          updateData[keyName as keyof TExpectedKeyNames] = updatedValue;
          return updateData;
        }
        return data;
      });
    }
  },
  setUpdatedCompetitorData(
    state: Draft<InitialState>,
    action: PayloadAction<TUpdateCompetitorData>
  ) {
    const { locationIdx, keyName, updatedValue, toolName } = action.payload;
    
    if (state.plan?.hash){
      state.plan.hash.locations[locationIdx].rankingData =
      state.plan.hash.locations[locationIdx].rankingData.map((data) => {
        if (data.toolName === toolName) {
          return { ...data, competitorsAdex: updatedValue };
        }
        return data;
      });
    }
  }
};
