import React, { useState } from 'react';
import { Stack, Typography, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';

import MultiTextFreeSolo from '../Shared/MultiTextFreeSolo';
import AudienceDialog from './AudienceDialog';

import audienceSizeImg from '../Shared/Assets/audienceSize.svg';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#34C759'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));

const DirectionStack = ({ targetKey }: { targetKey: string }) => {
  const { control, getValues, setValue } = useFormContext();

  const [isCustomAudienceChecked, setIsCustomAudienceChecked] = useState(() => {
      if (getValues(`${targetKey}.isCustomAudience`)) {
        return true;
      }
      return false;
    }),
    [open, setOpen] = useState(false);

  const resetCustomAudience = () => setValue(`${targetKey}.customAudience`, []);

  const handleClose = () => {
    setOpen(false);
    resetCustomAudience();
    setIsCustomAudienceChecked(false);
    setValue(`${targetKey}.isCustomAudience`, false);
  };

  const handleUpdateCustomAudience = () => setIsCustomAudienceChecked(true);

  const handleSwitchChange = (value: boolean) => {
    setOpen(value);
    setIsCustomAudienceChecked(value);
    if (!value) resetCustomAudience();
    setValue(`${targetKey}.isCustomAudience`, value);
  };

  return (
    <Stack sx={{ gap: '12px' }}>
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={2}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Custom Audience
        </Typography>
        <AntSwitch
          checked={isCustomAudienceChecked}
          inputProps={{ 'aria-label': 'controlled' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSwitchChange(e.target.checked);
          }}
        />
      </Stack>
      {isCustomAudienceChecked ? (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ paddingInline: '0.75rem' }}
        >
          <Controller
            control={control}
            name={`${targetKey}.customAudience`}
            render={({ field: { onChange, value } }) => (
              <MultiTextFreeSolo
                id="custom_audience"
                value={value}
                handleValueChange={(data) => onChange(data)}
                placeHolder="Enter Custom Audience"
              />
            )}
          />
        </Stack>
      ) : null}
      {open ? (
        <AudienceDialog
          open={open}
          handleClose={handleClose}
          handleUpdateCustomAudience={handleUpdateCustomAudience}
          audienceSizeImg={audienceSizeImg}
          targetKey={`${targetKey}.audienceSize`}
          handleOpen={(data) => setOpen(data)}
        />
      ) : null}
    </Stack>
  );
};

export default DirectionStack;
