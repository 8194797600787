import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';

let debounce: unknown;

interface IAgent {
  email?: string;
  name: string;
  role?: string;
  reportTo?: string;
  _id?: string;
}

interface IAgentAssignProps {
  selectedAgent?: IAgent;
  onChange: (value: IAgent) => void;
  type: string;
  width?: string;
}

const apiHost = process.env.REACT_APP_API_HOST;

const AgentAssignAutocomplete = ({
  selectedAgent,
  onChange,
  type,
  width
}: IAgentAssignProps) => {
  const [options, setOptions] = useState<IAgent[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);

  const fetchResults = (email: string) => {
    const domain = type !== 'all' ? 'themediaant.com' : '';
    if (email.length < 2) return;
    setIsLoading(true);
    axios
      .get(
        apiHost +
          '/users?params=' +
          JSON.stringify({
            filters: { email, domain },
            limit: 6
          }),
        {
          headers: {
            'x-access-token': localStorage.authToken
            // 'x-access-token':
            //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDc5N2ZiYThkNDJiODExM2VlYWNiYWQiLCJpYXQiOjE2ODU2ODQxNTQsImV4cCI6MTY4NjM2NDU1NH0.PA-dPuJsNPdbz8CNyBiYsAPwRzWrI2gg1a4i0Mnzcp4',
          }
        }
      )
      .then((response) => {
        setIsLoading(false);
        setOptions(response.data.users);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const onInputChange = (
    e: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    if (reason === 'clear') return;
    if (debounce) clearTimeout(debounce as string);
    debounce = setTimeout(() => fetchResults(value), 500);
  };

  const onSelect = (e: React.SyntheticEvent, value: any) => {
    onChange(value);
  };
  return (
    <Autocomplete
      id="tags-standard"
      options={options}
      freeSolo
      onInputChange={onInputChange}
      getOptionLabel={(option) => {
        if (typeof option === 'object') {
          return option?.email || '';
        }
        return '';
      }}
      onChange={onSelect}
      value={selectedAgent}
      noOptionsText={loading ? 'Loading...' : 'Type to search'}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search by email"
          sx={{
            width: width || '220px'
          }}
        />
      )}
    />
  );
};

export default AgentAssignAutocomplete;
