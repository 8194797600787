import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../store';
import { AutoFix } from 'mdi-material-ui';

const List = lazy(() => import('./List'));
const FormSection = lazy(() => import('./FormSection'));

const Wrapper = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Outlet />
  </Suspense>
);

const MarketingToolConfig = {
  name: 'Marketing Tool',
  path: 'marketing-tool',
  element: <Wrapper />,
  loader: async () => {
    const slice = await import('./redux/slice');
    injectReducer('marketingTool', slice.default);
    return true;
  },
  Icon: AutoFix,
  children: [
    { path: '', element: <List /> },
    { path: 'new', element: <FormSection /> },
    { path: ':marketingToolId/edit', element: <FormSection /> }
  ]
};

export default MarketingToolConfig;
