import { lazy } from 'react';

const CaseStudyMain = lazy(() => import('./CaseStudyMain'));

const CaseStudyMainConfig = {
  name: "KPI's & Case Study",
  path: 'kpi-case-study',
  element: <CaseStudyMain />,
  icon: 'kpi-case-study',
  state: 90
};

export default CaseStudyMainConfig;
