import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';
import { TLocation } from '../../interface';

export const radioHashCampaignCalendar = createAppAsyncThunk<
  any,
  { locations: TLocation }
>(
  'plan-requests/radioHashCampaignCalendar',
  async (data, { getState, extra: { API, SLS }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;

    try {
      if (planId && data && data?.locations) {
        const response = await API.put(
          `/plan-requests/${planId}/radio-hash/campaign-calender`,
          {
            ...data
          }
        );
        return response.data;
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occurred.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const radioHashCampaignCalendarExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder.addCase(radioHashCampaignCalendar.fulfilled, (state, action) => {
    if (action?.payload?.updatedPlan) {
      state.plan.currentState = action.payload.updatedPlan.currentState;
      state.plan.radioHash = action.payload.updatedPlan.radioHash;
    }
  });
};
