import { Box, Typography } from '@mui/material';

interface IContentCardRenderViewProps {
  title: string;
  children: React.ReactNode;
}

export const ContentCardRenderView = ({
  title = '',
  children
}: IContentCardRenderViewProps) => (
  <div
    style={{
      backgroundColor: '#F5F4FC',
      marginBottom: '12px',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
    }}
  >
    <Typography
      variant="h4"
      sx={{
        padding: '12px',
        backgroundColor: '#EEEBFF',
        color: '#4E3BC9',
        fontWeight: 500
      }}
    >
      {title}
    </Typography>
    <Box
      style={{
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        padding: '16px',
        backgroundColor: '#FAFBFC'
      }}
    >
      {children}
    </Box>
  </div>
);

export default ContentCardRenderView;
