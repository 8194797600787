import { lazy } from 'react';

const BudgetAllocator = lazy(() => import('./BudgetAllocator'));

const BudgetAllocatorConfig = {
  name: 'Budget Allocator',
  path: 'budget-allocator',
  element: <BudgetAllocator />,
  icon: 'budgetAllocator'
};

export default BudgetAllocatorConfig;
