import { AccountCreditCardOutline } from 'mdi-material-ui';
import PurchaseOrdersTable from './PurchaseOrderTable';
import PurchaseOrder from './PurchaseOrder';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../store';
import Bill from './Bills';

const PurchaseOrderConfig = {
  name: 'Purchase Orders',
  path: 'purchase-orders',
  element: <Outlet />,
  Icon: AccountCreditCardOutline,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('poSlice', slice.default);
      return true;
    });
  },
  children: [
    {
      path: '',
      element: <PurchaseOrdersTable />
    },
    {
      path: ':poId',
      element: <PurchaseOrder />
    },
    {
      path: ':poId/bill/:billId',
      element: <Bill />
    }
  ]
};

export default PurchaseOrderConfig;
