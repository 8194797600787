import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { injectReducer } from '../store';

const UsersList = lazy(() => import('./UsersList/index'));
const AddUser = lazy(()=> import("./AddUser/index"));
const EditUser = lazy(()=> import("./EditUser/index"));

const Icon = () => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M16.5 15.5C18.22 15.5 20.25 16.3 20.5 16.78V17.5H12.5V16.78C12.75 16.3 14.78 15.5 16.5 15.5M16.5 14C14.67 14 11 14.92 11 16.75V19H22V16.75C22 14.92 18.33 14 16.5 14M9 13C6.67 13 2 14.17 2 16.5V19H9V17.5H3.5V16.5C3.5 15.87 6.29 14.34 9.82 14.5A5.12 5.12 0 0 1 11.37 13.25A12.28 12.28 0 0 0 9 13M9 6.5A1.5 1.5 0 1 1 7.5 8A1.5 1.5 0 0 1 9 6.5M9 5A3 3 0 1 0 12 8A3 3 0 0 0 9 5M16.5 8.5A1 1 0 1 1 15.5 9.5A1 1 0 0 1 16.5 8.5M16.5 7A2.5 2.5 0 1 0 19 9.5A2.5 2.5 0 0 0 16.5 7Z"
      fill="currentColor"
    />
  </svg>
);

const Wrapper = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Outlet />
  </Suspense>
);

const UsersPageConfig = {
  name: 'Users',
  path: 'users',
  element: <Wrapper />,
  Icon,
  children: [
    {
      path: '',
      element: <UsersList />
    },
    {
      path: ':userId/edit',
      element: <EditUser />
    },
    {
      path: 'new',
      element: <AddUser />
    }
  ],
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('users', slice.default);
      return true;
    });
  }
};

export default UsersPageConfig;
