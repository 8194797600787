import { useState } from 'react';

export const loadingState = {
  compLevelLoading: 'component-level-loading',
  cautionLoading: 'caution-loading',
  saveAsDraftLoading: 'save-as-draft-loading',
  saveAndNextLoading: 'save-and-next-loading',
  default: 'no-loading'
};

const useHash = (): [
  string,
  (value: string) => void,
  boolean,
  (value: boolean) => void,
  boolean,
  (value: boolean) => void,
  boolean,
  (value: boolean) => void
] => {
  const [isLoading, setIsLoading] = useState<string>(loadingState.default);
  const [openDraftModal, setOpenDraftModal] = useState<boolean>(false);
  const [openSaveAndNextModal, setOpenSaveAndNextModal] =
    useState<boolean>(false);
  const [openSaveAsDraftModal, setOpenSaveAsDraftModal] =
    useState<boolean>(false);

  return [
    isLoading,
    setIsLoading,
    openDraftModal,
    setOpenDraftModal,
    openSaveAndNextModal,
    setOpenSaveAndNextModal,
    openSaveAsDraftModal,
    setOpenSaveAsDraftModal
  ];
};

export default useHash;
