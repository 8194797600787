import { AppDispatch } from '../../../store';
import { SnackBarActions } from '../../../Shared/SnackBar/slice';

import {
  sendBdiVsCdiPutRequest,
  sendBudgetAllocatorPutRequest,
  sendBudgetSplitPutRequest,
  sendCampaignBriefPutRequest,
  sendCampaignCalenderPutRequest,
  sendCaseStudyPutRequest,
  sendGeoClusteringPutRequest,
  sendMediaMapPutRequest,
  sendRankingFileUploadPutRequest,
  sendTargetGroupPutRequest
} from './apiRequest';

import { THashV2 } from '../interface';
import { ICampaignBrief } from '../MultiVerticalBrief/interface';
import { ITargetGroup } from '../TargetGroup/interface';
import { ILocation as ILocationRankingDataInput } from '../RankingDataInput/interface';
import { ILocation as ILocationBdiVsCdi } from '../BdiVsCdi/interface';
import { ILocation as ILocationMediaMap } from '../MediaMap/interface';
import { ILocation as ILocationBudgetSplit } from '../BudgetSpit/interface';
import { IGeoCluster } from '../GeoClustering/interface';
import { IHashV2 } from '../BudgetAllocator/interface';
import { ICluster } from '../CampaignCalendar/interface';
import { ICaseStudy } from '../CaseStudy/interface';

type RequestHandlerParams = {
  dispatch: AppDispatch;
  callbackFun: () => void;
  data: TDataMap;
  callbackFunFinally: () => void;
};

type TDataMap =
  | ICampaignBrief
  | ITargetGroup
  | ILocationRankingDataInput[]
  | ILocationBdiVsCdi[]
  | ILocationMediaMap[]
  | ILocationBudgetSplit[]
  | IGeoCluster[]
  | IHashV2
  | ICluster[]
  | ICaseStudy;

const tabHelper: { [k: number]: string } = {
  0: 'multi-vertical-brief',
  10: 'target-group',
  20: 'ranking-data-input',
  30: 'bdi-vs-cdi',
  40: 'media-map',
  50: 'budget-split',
  60: 'geo-clustering',
  70: 'budget-allocator',
  80: 'campaign-calendar',
  90: 'kpi-case-study'
};

export const triggerError = (errorMessage: string, dispatch: AppDispatch) => {
  dispatch(
    SnackBarActions.openSnack({
      message: errorMessage,
      open: true,
      type: 'error'
    })
  );
};

const getDataForStep = (step: number, hashV2: THashV2) => {
  const dataMap: Record<number, TDataMap> = {
    0: hashV2.campaignBrief,
    10: hashV2.targetGroup,
    20: hashV2.locations,
    30: hashV2.locations,
    40: hashV2.locations,
    50: hashV2.locations,
    60: hashV2.geoClustering,
    70: hashV2,
    80: hashV2.clusters,
    90: hashV2.caseStudy
  };

  return dataMap[step];
};

export const handleNextStep = (
  activeStep: number,
  dispatch: AppDispatch,
  hashV2: THashV2,
  callbackFun: () => void,
  callbackFunFinally: () => void
) => {
  const handlerMap: Record<string, (params: RequestHandlerParams) => void> = {
    [tabHelper[0]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendCampaignBriefPutRequest({
        dispatch,
        callbackFun,
        campaignBrief: data as ICampaignBrief,
        callbackFunFinally
      }),
    [tabHelper[10]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendTargetGroupPutRequest({
        dispatch,
        callbackFun,
        targetGroup: data as ITargetGroup,
        callbackFunFinally
      }),
    [tabHelper[20]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendRankingFileUploadPutRequest({
        dispatch,
        callbackFun,
        locations: data as ILocationRankingDataInput[],
        callbackFunFinally
      }),
    [tabHelper[30]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendBdiVsCdiPutRequest({
        dispatch,
        callbackFun,
        locations: data as ILocationBdiVsCdi[],
        callbackFunFinally
      }),
    [tabHelper[40]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendMediaMapPutRequest({
        dispatch,
        callbackFun,
        locations: data as ILocationMediaMap[],
        callbackFunFinally
      }),
    [tabHelper[50]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendBudgetSplitPutRequest({
        dispatch,
        callbackFun,
        locations: data as ILocationBudgetSplit[],
        callbackFunFinally
      }),
    [tabHelper[60]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendGeoClusteringPutRequest({
        dispatch,
        callbackFun,
        geoClustering: data as IGeoCluster[],
        callbackFunFinally
      }),
    [tabHelper[70]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendBudgetAllocatorPutRequest({
        dispatch,
        callbackFun,
        hashV2: data as THashV2,
        callbackFunFinally
      }),
    [tabHelper[80]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendCampaignCalenderPutRequest({
        dispatch,
        callbackFun,
        clusters: data as ICluster[],
        callbackFunFinally
      }),
    [tabHelper[90]]: ({ dispatch, callbackFun, data, callbackFunFinally }) =>
      sendCaseStudyPutRequest({
        dispatch,
        callbackFun,
        caseStudy: data as ICaseStudy,
        callbackFunFinally
      })
  };
  const handler = handlerMap[tabHelper[activeStep]];
  if (handler) {
    handler({
      dispatch,
      callbackFun,
      data: getDataForStep(activeStep, hashV2),
      callbackFunFinally
    });
  }
};
