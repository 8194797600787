import HashMainContent from './HashMainContent';

import { useAppSelector } from '../../hooks';

import { ICategoryConfig } from './index';

interface IHashMainProps {
  children: React.ReactNode;
  categoryConfig: ICategoryConfig[];
}

const HashMain = ({ children, categoryConfig }: IHashMainProps) => {
  const plan = useAppSelector((state: any) => state.planRequests?.plan);

  const renderStepperContent =
    plan &&
    plan?._campaign &&
    plan?._campaign?.brief &&
    categoryConfig.length !== 0;

  return renderStepperContent ? (
    <HashMainContent categoryConfig={categoryConfig}>
      {children}
    </HashMainContent>
  ) : null;
};

export default HashMain;
