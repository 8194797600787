import { Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

interface IReasonsPopoverProps {
  onClose: () => void;
  onSubmit: (reason: string) => void;
  title: string;
  placeholder: string;
}

const ReasonsPopover = ({
  onClose,
  onSubmit,
  title,
  placeholder
}: IReasonsPopoverProps) => {
  const [reason, setReason] = useState<string>('');
  return (
    <Stack p={4} spacing={4} width={'350px'}>
      <Typography variant="subtitle1">{title}</Typography>
      <TextField
        size="small"
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setReason(e.target.value)
        }
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={4}
      >
        <Button
          variant="text"
          sx={{
            fontWeight: 500,
            padding: '6px 22px',
            fontSize: '14px',
            borderRadius: '4px'
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            padding: '6px 22px',
            fontSize: '14px',
            borderRadius: '4px',
            fontWeight: 500
          }}
          onClick={() => onSubmit(reason)}
          disabled={reason === ''}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default ReasonsPopover;
