import { lazy } from 'react';

const VendorCollection = lazy(() => import("./VendorCollection"));

const vendorCollectionConfig = {
  name: "Vendor Collection",
  path: "vendor-collection",
  element: <VendorCollection />,
  icon: 'radioBrief'
};

export default vendorCollectionConfig;