import { lazy } from 'react';
import { injectReducer } from '../store';

const Revenue = lazy(() => import('./revenue'));

const Icon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6923 20.5H6.84615C6.35652 20.5 5.88695 20.3055 5.54073 19.9593C5.1945 19.6131 5 19.1435 5 18.6538V10.4692C5.00054 10.0523 5.14169 9.64772 5.40062 9.32093L8.68 5.19785C8.85232 4.97976 9.07191 4.80363 9.3222 4.68275C9.5725 4.56187 9.84697 4.49938 10.1249 4.5H16.6923C17.1819 4.5 17.6515 4.69451 17.9977 5.04073C18.344 5.38695 18.5385 5.85653 18.5385 6.34616V18.6538C18.5385 19.1435 18.344 19.6131 17.9977 19.9593C17.6515 20.3055 17.1819 20.5 16.6923 20.5ZM10.1249 5.73077C10.0323 5.73077 9.94083 5.75169 9.85739 5.79197C9.77396 5.83225 9.7007 5.89085 9.64308 5.96339L6.36431 10.0865C6.27827 10.1955 6.23125 10.3303 6.23077 10.4692V18.6538C6.23077 18.8171 6.2956 18.9736 6.41101 19.089C6.52642 19.2044 6.68294 19.2692 6.84615 19.2692H16.6923C16.8555 19.2692 17.012 19.2044 17.1274 19.089C17.2429 18.9736 17.3077 18.8171 17.3077 18.6538V6.34616C17.3077 6.18295 17.2429 6.02642 17.1274 5.91102C17.012 5.79561 16.8555 5.73077 16.6923 5.73077H10.1249Z"
      fill="currentColor"
    />
    <path
      d="M8.69231 10.6538H5.69538C5.53217 10.6538 5.37565 10.589 5.26024 10.4736C5.14483 10.3582 5.08 10.2017 5.08 10.0385C5.08 9.87525 5.14483 9.71873 5.26024 9.60332C5.37565 9.48792 5.53217 9.42308 5.69538 9.42308H8.69231C8.85552 9.42308 9.01204 9.35824 9.12745 9.24284C9.24286 9.12743 9.30769 8.97091 9.30769 8.8077V5.13385C9.30769 4.97064 9.37253 4.81411 9.48793 4.69871C9.60334 4.5833 9.75987 4.51847 9.92308 4.51847C10.0863 4.51847 10.2428 4.5833 10.3582 4.69871C10.4736 4.81411 10.5385 4.97064 10.5385 5.13385V8.8077C10.5385 9.29733 10.344 9.7669 9.99773 10.1131C9.65151 10.4593 9.18194 10.6538 8.69231 10.6538ZM15.8308 15.5769H7.70769C7.54448 15.5769 7.38796 15.5121 7.27255 15.3967C7.15714 15.2813 7.09231 15.1248 7.09231 14.9615C7.09231 14.7983 7.15714 14.6418 7.27255 14.5264C7.38796 14.411 7.54448 14.3462 7.70769 14.3462H15.8308C15.994 14.3462 16.1505 14.411 16.2659 14.5264C16.3813 14.6418 16.4461 14.7983 16.4461 14.9615C16.4461 15.1248 16.3813 15.2813 16.2659 15.3967C16.1505 15.5121 15.994 15.5769 15.8308 15.5769ZM15.8308 18.0385H7.70769C7.54448 18.0385 7.38796 17.9736 7.27255 17.8582C7.15714 17.7428 7.09231 17.5863 7.09231 17.4231C7.09231 17.2599 7.15714 17.1033 7.27255 16.9879C7.38796 16.8725 7.54448 16.8077 7.70769 16.8077H15.8308C15.994 16.8077 16.1505 16.8725 16.2659 16.9879C16.3813 17.1033 16.4461 17.2599 16.4461 17.4231C16.4461 17.5863 16.3813 17.7428 16.2659 17.8582C16.1505 17.9736 15.994 18.0385 15.8308 18.0385Z"
      fill="currentColor"
    />
    <path
      d="M14.5258 8.79497C14.4094 8.91541 14.2528 8.98366 14.0842 8.98768H13.4017C13.1849 8.98366 13.0042 9.15228 13.0002 9.36908C12.9962 9.48149 13.0444 9.58989 13.1247 9.66617L14.839 11.2801C14.9996 11.4327 15.2565 11.4246 15.4091 11.264C15.5617 11.1035 15.5536 10.8465 15.393 10.694L14.3853 9.74245C14.6543 9.68625 14.9032 9.54975 15.1 9.35704C15.2726 9.18842 15.397 8.97965 15.4613 8.74679H15.7985C16.0193 8.74679 16.2 8.56613 16.2 8.34532C16.2 8.1245 16.0193 7.94384 15.7985 7.94384H15.4613C15.4372 7.86354 15.4091 7.77923 15.373 7.70295H15.7945C16.0153 7.70295 16.196 7.52229 16.196 7.30148C16.196 7.08066 16.0153 6.9 15.7945 6.9H13.4057C13.1849 6.9 13.0042 7.08066 13.0042 7.30148C13.0042 7.52229 13.1849 7.70295 13.4057 7.70295H14.0882C14.2809 7.70295 14.4536 7.8234 14.566 7.94384H13.4017C13.1809 7.94384 13.0002 8.1245 13.0002 8.34532C13.0002 8.56613 13.1809 8.74679 13.4017 8.74679H14.562C14.5539 8.74679 14.5419 8.78293 14.5258 8.79497Z"
      fill="currentColor"
    />
  </svg>
);

const RevenueConfig = {
  name: 'Revenue',
  path: 'revenue',
  element: <Revenue />,
  Icon,
  loader: () => {
    return import('./redux/slice').then((slice) => {
      injectReducer('revenue', slice.default);
      return true;
    });
  }
};

export default RevenueConfig;
