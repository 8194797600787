import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
//import theme from './app/theme';
import { theme } from '@media-ant/ui'
import router from './app/router';
import { RouterProvider } from 'react-router';
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

ReactGA.initialize("G-P1KMECPHQN");

root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store} >
				<RouterProvider router={router} />
			</Provider>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
